import React from 'react'
import { PropTypes } from 'prop-types'
import { Row, Col, Button, Preloader, Icon } from 'react-materialize'
import { Field } from 'redux-form'
import InputText from '../../../Inputs/Text'

const Email = ({
  continueHandler,
  isPrivacyAcceptanceDone,
  emailPolling,
  emailHandler,
  fields: { EMAIL },
  change,
  touch,
  backHandler,
  formValues = {},
  formErrors,
  title,
  text,
  isProspect,
  invalid,
  modalHandler,
  valida,
  nonvalida,
  validazione,
  canSendEmail,
  remainingTime
}) => {
  if (emailPolling && !isPrivacyAcceptanceDone) {
    // In validazione
    return (
      <div className="onboarding-modale">
        <div className="onboarding-modale-content">
          <Row>
            <Col s={12} style={{ marginTop: '-20px' }}>
              {title}
            </Col>
            <Col s={12}>
              <div className="onboarding-modale-content__subtitle">
                Email inviata con successo all'indirizzo{' '}
                <p className="onboarding-modale-email">{formValues[EMAIL.id]}.</p> Per proseguire attendi che il cliente
                prenda visione della documentazione ed accetti la privacy.
              </div>
            </Col>
            <Col s={12} className="center-align" style={{ marginTop: '40px' }}>
              <Preloader size="big" />
            </Col>
            <Col s={6} style={{ marginTop: '30px' }}>
              <Button className="custom-btn primary-reverse transparent" onClick={backHandler}>
                Annulla
              </Button>
            </Col>
            <Col s={6} className="right-align" style={{ marginTop: '30px' }}>
              <Button className="btn custom-btn primary" disabled>
                Prosegui
              </Button>
            </Col>
          </Row>
        </div>
      </div>
    )
  } else if (!emailPolling && isPrivacyAcceptanceDone) {
    // Validazione effettuata con successo
    return (
      <div className="onboarding-modale">
        <div className="onboarding-modale-content">
          <Row>
            <Col s={12} style={{ marginTop: '-20px' }}>
              {title}
            </Col>

            <Col s={12} className="center-align" style={{ marginTop: '40px' }}>
              <Icon className="onboarding-modale-icon__confirm icon-icone-ips_sv-spesa" large>
                &#xe90c;
              </Icon>
            </Col>
            <Col s={12} className="center-align">
              <p className="onboarding-modale-content__subtitle">Il Prospect ha accettato la Privacy Policy</p>
            </Col>
            <Col s={12} className="right-align" style={{ marginTop: '30px' }}>
              <Button
                className="btn custom-btn primary"
                onClick={isProspect ? () => continueHandler('gotoData') : () => modalHandler()}>
                Prosegui
              </Button>
            </Col>
          </Row>
        </div>
      </div>
    )
  } else {
    return (
      <div className="onboarding-modale">
        <div className="onboarding-modale-content">
          <Row>
            <Col s={12} style={{ marginTop: '-20px' }}>
              {title}
            </Col>
            <Col s={12}>{text}</Col>
            <Col s={12} style={{ marginTop: '40px' }}>
              <Field
                name={EMAIL.id}
                s={12}
                type="email"
                maxLength={EMAIL.maxLength}
                label="Indirizzo Email"
                error={formErrors[EMAIL.id]}
                value={formValues[EMAIL.id]}
                change={change}
                touch={touch}
                component={InputText}
              />
              {remainingTime > 0 && (
                // TODO: check styling
                <span className="onboarding-modale-content__subtitle">
                  Si prega di attendere prima di effettuare un nuovo tentativo: {Math.ceil(remainingTime / 1000)}s
                </span>
              )}
            </Col>
            <Col s={6} style={{ marginTop: '30px' }}>
              <Button
                className="custom-btn primary-reverse transparent"
                onClick={() => {
                  if (formErrors[EMAIL.id]) {
                    nonvalida()
                  } else {
                    valida()
                  }
                  if (validazione === true) {
                    change(EMAIL.id, '')
                  }
                  backHandler()
                }}>
                Annulla
              </Button>
            </Col>
            <Col s={6} className="right-align" style={{ marginTop: '30px' }}>
              <Button
                className="btn custom-btn primary"
                disabled={formErrors[EMAIL.id] || !formValues[EMAIL.id] || invalid || validazione || !canSendEmail}
                onClick={() => {
                  touch(EMAIL.id)
                  emailHandler()
                }}>
                Invia
              </Button>
            </Col>
          </Row>
        </div>
      </div>
    )
  }
}

export default Email

Email.propTypes = {
  emailHandler: PropTypes.func.isRequired,
  continueHandler: PropTypes.func,
  fields: PropTypes.shape({
    EMAIL: PropTypes.object
  }),
  change: PropTypes.func.isRequired,
  touch: PropTypes.func.isRequired,
  backHandler: PropTypes.func.isRequired,
  formValues: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  formErrors: PropTypes.object,
  isProspect: PropTypes.bool,
  modalHandler: PropTypes.func,
  canSendEmail: PropTypes.bool
}
