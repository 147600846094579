import React, { Component } from 'react'
import { PropTypes } from 'prop-types'
import { connect } from 'react-redux'
import { reduxForm, formValueSelector, isDirty, Field, getFormSyncErrors } from 'redux-form'
import { Col, Row } from 'react-materialize'
import { CollapsibleItem } from '../../../../../components/Collapsible'
import AccordionHeader from '../../../../../components/AccordionHeader'
import { renderAutocomplete } from '../../../../../components/Forms/renderFields'
import { APERTURA_INFO_GIURIDICHE, DOCUMENTI_GIURIDICHE } from '../../../../../store/modules/forms/constants'
import allFields from '../../../fields'
import censimentoFields from '../../../../ConsulenzaBaseContainerLayout/fields'
import {
  loadDefaultFields,
  // getInFormErrors,
  notBelongToDominio,
  getDominio,
  getAutocompleteDefaultOption,
  mapDominiToAutocomplete
} from '../../../../ConsulenzaBaseContainerLayout/utils'
import DatiGiuridici from '../../../../../components/Forms/Residenza/DatiGiuridici'
import Title from '../../../../../components/Title'
import ButtonSubmit from '../../../../../components/ButtonSubmit'

const fields = allFields[APERTURA_INFO_GIURIDICHE]
const defaultFields = loadDefaultFields(fields)
const selector = formValueSelector(APERTURA_INFO_GIURIDICHE)

const loadInitialFields = (pratica = {}, dataRegistry = {}) => {
  const { COMUNE_FIRMA, REGIME, FLAG_INDIRIZZO } = fields
  const result = {
    [REGIME.id]: '' + (pratica[REGIME.id] || REGIME.defaultValue),
    [COMUNE_FIRMA.id]: (pratica[COMUNE_FIRMA.id] || {}).chiave || COMUNE_FIRMA.defaultValue,
    [FLAG_INDIRIZZO.id]: !!pratica[FLAG_INDIRIZZO.id]
  }
  if (pratica.corrispondenzaPG) {
    return Object.keys(fields).reduce((res, cur) => {
      if (fields[cur].isNotCorrispondenza) return res
      if (!pratica.corrispondenzaPG[fields[cur].id]) return res
      return {
        ...res,
        [fields[cur].id]: fields[cur].dominio
          ? pratica.corrispondenzaPG[fields[cur].id].chiave
          : pratica.corrispondenzaPG[fields[cur].id]
      }
    }, result)
  } else {
    const result = {}
    if (dataRegistry) {
      loadDataRegistry(dataRegistry, (key, value) => (result[key] = value))
    }

    return result
  }
}

const loadDataRegistry = (dataRegistry, callback) => {
  Object.keys(fields).forEach(k => {
    if (fields[k].isNotCorrispondenza) return

    if (k === 'RAGIONE_SOCIALE_PG') {
      let censimentoFieldss = censimentoFields[0][DOCUMENTI_GIURIDICHE][k].id
      callback(fields[k].id, dataRegistry[censimentoFieldss])
    } else {
      if (fields[k].dominio) {
        callback(fields[k].id, (dataRegistry[censimentoFields[0][DOCUMENTI_GIURIDICHE][k].id] || {}).chiave || '')
      } else {
        let censimentoFieldss = censimentoFields[0][DOCUMENTI_GIURIDICHE][k].id
        callback(fields[k].id, dataRegistry[censimentoFieldss])
      }
    }
  })
}

const validate = (values, props = {}) => {
  const { NOME, RAGIONE_SOCIALE_PG, FLAG_INDIRIZZO, NAZIONE_PG, PROVINCIA_PG, CITTA_PG, COMUNE_FIRMA, CAP_PG } = fields
  const { domini = {} } = props
  const errors = {}
  if (!!values[COMUNE_FIRMA.id] && notBelongToDominio(domini, COMUNE_FIRMA.dominio, values[COMUNE_FIRMA.id])) {
    errors[COMUNE_FIRMA.id] = 'Comune non riconosciuto'
  }
  if (values[FLAG_INDIRIZZO.id]) {
    if (!!values[NOME.id] && !new RegExp(NOME.validazione).test(values[NOME.id])) {
      errors[NOME.id] = 'Il nome può contenere solo caratteri alfabetici'
    }

    if (
      !!values[RAGIONE_SOCIALE_PG.id] &&
      !new RegExp(RAGIONE_SOCIALE_PG.validazione).test(values[RAGIONE_SOCIALE_PG.id])
    ) {
      errors[RAGIONE_SOCIALE_PG.id] = 'Il formato della Ragione sociale non è corretto'
    }
    if (!!values[NAZIONE_PG.id] && notBelongToDominio(domini, NAZIONE_PG.dominio, values[NAZIONE_PG.id])) {
      errors[NAZIONE_PG.id] = 'Nazione non riconosciuta'
    }
    if (!!values[PROVINCIA_PG.id] && notBelongToDominio(domini, PROVINCIA_PG.dominio, values[PROVINCIA_PG.id])) {
      errors[PROVINCIA_PG.id] = 'Provincia non riconosciuta'
    }
    if (
      !!values[PROVINCIA_PG.id] &&
      !!values[CITTA_PG.id] &&
      notBelongToDominio(domini, CITTA_PG.dominio, values[CITTA_PG.id])
    ) {
      errors[CITTA_PG.id] = 'Comune non riconosciuto'
    }
    if (!!values[CAP_PG.id] && notBelongToDominio(domini, CAP_PG.dominio, values[CAP_PG.id])) {
      errors[CAP_PG.id] = 'CAP non riconosciuto'
    }
    // if (CIVICO && !!values[CIVICO.id] && !(new RegExp(CIVICO.validazione)).test(values[CIVICO.id])) {
    //   errors[CIVICO.id] = 'Numero civico non valido'
    // }
  }

  return errors
}

class InformazioniGeneraliGiuridiche extends Component {
  constructor(props) {
    super(props)

    this.state = {
      saved: false,
      waitingDomini: false
    }
    this.addResidenzaToggle = this.addResidenzaToggle.bind(this)
    this.submit = this.submit.bind(this)
  }

  componentDidMount() {
    // console.log(this.props.isPG)
    if (this.props.isPG === true) {
      this.props.initialValues.idRegimeAppartenenza = '2'
      // this.props.change(fields.REGIME.id, '2')
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isFetching && !this.props.isFetching && !this.props.apiError && this.props.submitSucceeded) {
      this.props.initialize(this.props.formValues)
      this.props.resetForm(APERTURA_INFO_GIURIDICHE)
      this.setState({ saved: true })
    }

    if (prevProps.dominioRequest.fetching && !this.props.dominioRequest.fetching && this.state.waitingDomini) {
      const { change, formValues } = this.props
      change(fields.FLAG_INDIRIZZO.id, !formValues[fields.FLAG_INDIRIZZO.id])

      this.setState({ waitingDomini: false })
    }
    // if (this.state.firmaOptions.length === 0){
    //   this.setState({ firmaOptions: mapDominiToAutocomplete(getDominio(this.props.domini, fields.COMUNE_FIRMA.dominio)) })
    // }
  }

  addResidenzaToggle() {
    const { change, formValues } = this.props

    if (!formValues[fields.FLAG_INDIRIZZO.id]) {
      // if (!isPG) {
      //   loadDataRegistry(dataRegistry, change)
      //   updateDomini(
      //     ['CITTA_PG', 'CAP_PG'].reduce((res, cur) => {
      //       return [
      //         ...res,
      //         {
      //           idDominio: fields[cur].dominio,
      //           filtro: {
      //             codice: formValues[fields[cur].dominioDep]
      //           }
      //         }
      //       ]
      //     }, [])
      //   )
      //   this.setState({ waitingDomini: true })
      // } else {
      //   change(fields.FLAG_INDIRIZZO.id, true)
      // }
      change(fields.FLAG_INDIRIZZO.id, true)
    } else {
      change(fields.FLAG_INDIRIZZO.id, false)
    }
    // change(fields.FLAG_INDIRIZZO.id, !formValues[fields.FLAG_INDIRIZZO.id])
  }

  submit(values) {
    const {
      onSave,
      pratica: { idPratica },
      initialValues
    } = this.props
    onSave(
      idPratica,
      Object.keys(fields).reduce((res, cur) => {
        const field = fields[cur]
        if (field.isNotCorrispondenza) {
          if (field.dominio) {
            return {
              ...res,
              [field.id]: {
                tipoDominio: field.dominio,
                chiave: values[field.id]
              }
            }
          }
          if (field.skip || (!values[field.id] && values[field.id] !== false)) {
            return res
          }
          return {
            ...res,
            [field.id]: values[field.id]
          }
        }
        if (field.skip || (!values[field.id] && values[field.id] !== false)) {
          return res
        }
        const chiave = values[fields.FLAG_INDIRIZZO.id] ? values[field.id] : initialValues[field.id]
        return {
          ...res,
          corrispondenzaPG: {
            ...res.corrispondenzaPG,
            [field.id]: field.dominio ? { tipoDominio: field.dominio, chiave } : chiave
          }
        }
      }, {})
    )
  }

  render() {
    const {
      handleSubmit,
      submitting,
      isCompleted,
      isDirty,
      invalid,
      domini,
      formValues,
      change,
      formErrors,
      isFetching
    } = this.props
    const { COMUNE_FIRMA, REGIME, FLAG_INDIRIZZO } = fields
    // const errori = getInFormErrors(validate(formValues, { domini }), fields)

    return (
      <CollapsibleItem
        itemKey={APERTURA_INFO_GIURIDICHE}
        header="Informazioni Generali"
        icon="keyboard_arrow_down"
        disabled={this.props.disabled}
        labelInfo={AccordionHeader(isDirty, this.state.saved, !isDirty && isCompleted ? 'COMPLETATA' : 'BOZZA')}>
        <form onSubmit={handleSubmit(this.submit)} noValidate>
          <Row>
            <Col s={12}>
              <Title>Dichiarazione del regime di appartenenza*</Title>
              <div className="radio-horizontal">
                <p>
                  <Field
                    name={REGIME.id}
                    className="with-gap"
                    type="radio"
                    id="risparmio-amministrato"
                    value="1"
                    component="input"
                    disabled
                  />
                  <label htmlFor="risparmio-amministrato">RISPARMIO AMMINISTRATO</label>
                </p>
                <p>
                  <Field
                    name={REGIME.id}
                    className="with-gap"
                    type="radio"
                    id="dichiarazione"
                    value="2"
                    component="input"
                    disabled
                  />
                  <label htmlFor={'dichiarazione'}>DICHIARAZIONE</label>
                </p>
              </div>
            </Col>
          </Row>
          <Row>
            <Col s={6}>
              <Field
                name={COMUNE_FIRMA.id}
                s={12}
                threshold={0}
                label={COMUNE_FIRMA.label}
                defaultValue={getAutocompleteDefaultOption(
                  domini.listaDomini,
                  COMUNE_FIRMA.dominio,
                  formValues[COMUNE_FIRMA.id]
                )}
                options={mapDominiToAutocomplete(getDominio(this.props.domini, fields.COMUNE_FIRMA.dominio))}
                handleChange={(field, value) => change(field, value)}
                component={renderAutocomplete}
              />
              {/* <Field
                name={NOME.id}
                s={6}

                label={NOME.label}
                error={formErrors[NOME.id]}
                value={formValues[NOME.id]}
                change={change}
                touch={touch}
                component={InputText}
            /> */}
            </Col>
          </Row>
          <Row>
            <Col s={12}>
              <hr />
              {!formValues[FLAG_INDIRIZZO.id] && (
                <span className="indirizzo-alternativo">
                  <b>Indirizzo per comunicazione:</b> uguale a indirizzo residenza intestatario
                </span>
              )}
              <a onClick={this.addResidenzaToggle} className="indirizzo-alternativo-button">
                {!formValues[FLAG_INDIRIZZO.id] && 'MODIFICA'}
                {formValues[FLAG_INDIRIZZO.id] && 'ANNULLA MODIFICHE'}
              </a>
            </Col>
            <Col s={12}>
              {formValues[FLAG_INDIRIZZO.id] === true && <DatiGiuridici fields={fields} {...this.props} />}
            </Col>
          </Row>
          <ButtonSubmit
            disabled={
              submitting || !isDirty || invalid || isFetching || Object.keys(formErrors).some(e => formErrors[e])
            }
          />
        </form>
      </CollapsibleItem>
    )
  }
}

InformazioniGeneraliGiuridiche.propTypes = {
  handleSubmit: PropTypes.func,
  submitting: PropTypes.bool,
  isDirty: PropTypes.bool,
  invalid: PropTypes.bool,
  isFetching: PropTypes.bool,
  apiError: PropTypes.object,
  resetForm: PropTypes.func,
  submitSucceeded: PropTypes.bool,
  formValues: PropTypes.object,
  initialValues: PropTypes.object,
  domini: PropTypes.object,
  change: PropTypes.func,
  initialize: PropTypes.func,
  onSave: PropTypes.func,
  updateDominio: PropTypes.func,
  updateDomini: PropTypes.func,
  dominioRequest: PropTypes.object,
  pratica: PropTypes.object,
  dataRegistry: PropTypes.object,
  isCompleted: PropTypes.bool,
  disabled: PropTypes.bool,
  isPG: PropTypes.bool,
  formErrors: PropTypes.object
}

export default connect(state => ({
  initialValues: {
    ...defaultFields,
    ...state.anagraficaPersonaGiuridicaCliente.docIDPG,
    ...loadInitialFields(state.pratica.pratica, state.pratica.pratica.corrispondenzaPG),
    idRegimeAppartenenza: '2'
    // ...loadInitialFields(state.pratica.pratica, {})
  },
  formValues: {
    ...defaultFields,
    ...state.anagraficaPersonaGiuridicaCliente.docIDPG,
    // ...state.pratica.pratica.corrispondenzaPG,
    ...loadInitialFields(state.pratica.pratica, state.pratica.pratica.corrispondenzaPG),
    ...selector(state, ...Object.keys(defaultFields).reduce((res, cur) => [...res, cur], [])),
    idRegimeAppartenenza: '2'
  },
  isDirty: isDirty(APERTURA_INFO_GIURIDICHE)(state),
  isFetching: state.pratica.putPraticaPGRequest.fetching,
  apiError: state.pratica.putPraticaPGRequest.error,
  formErrors: getFormSyncErrors(APERTURA_INFO_GIURIDICHE)(state)
  // formValues: getFormValues(APERTURA_INFO_GIURIDICHE)(state),
}))(
  reduxForm({
    form: APERTURA_INFO_GIURIDICHE,
    validate,
    enableReinitialize: true
  })(InformazioniGeneraliGiuridiche)
)
