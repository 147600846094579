import React, { Component, Fragment } from 'react'
import { PropTypes } from 'prop-types'
import { Row, Col } from 'react-materialize'
import { Field } from 'redux-form'
import * as dataSB from '../../../static-data/data-servizio-base.json'
import {
  mapDominiToAutocomplete,
  getDominio,
  getValueDominioFromKey,
  getAutocompleteDefaultOption
} from '../../../routes/ConsulenzaBaseContainerLayout/utils'
import { renderAutocomplete } from '../renderFields'
import InputText from '../../Inputs/Text'
import OtpVerify from '../../OtpVerify'
import { timedButtonClick } from '../../../utils'

const infoContatti = dataSB.censimento_anagrafica.contatti

class ContattiForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showModal: false,
      modal: {
        label: 'Cellulare',
        flagId: '',
        createOtp: () => {},
        verifyOtp: () => {}
      },
      alreadyValidated: {},
      canSendSms: true,
      canSendEmail: true,
      remainingTimeSms: 0,
      remainingTimeEmail: 0,
      timerIdSms: null,
      timerIdEmail: null
    }

    this.otpSmsToggle = this.otpSmsToggle.bind(this)
    this.otpEmailToggle = this.otpEmailToggle.bind(this)
    this.otpUpdateState = this.otpUpdateState.bind(this)
    this.otpVerified = this.otpVerified.bind(this)
  }

  componentDidUpdate(prevProps) {
    if (prevProps.createOtpRequest.fetching && !this.props.createOtpRequest.fetching) {
      // mettere controllo su email duplicate
      console.log(this.state)
      if (this.state.modal.label === 'sms') {
        timedButtonClick('canSendSms', 'remainingTimeSms', 'timerIdSms', this.setState.bind(this), 90000)
      } else {
        timedButtonClick('canSendEmail', 'remainingTimeEmail', 'timerIdEmail', this.setState.bind(this), 90000)
      }
    }

    if (
      (!prevProps.createOtpRequest.error && this.props.createOtpRequest.error) ||
      (!prevProps.verifyOtpRequest.error && this.props.verifyOtpRequest.error)
    ) {
      this.setState({ showModal: false })
    }
  }
  componentWillUnmount() {
    clearInterval(this.state.timerIdEmail)
    clearInterval(this.state.timerIdSms)
  }
  otpSmsToggle() {
    const {
      formValues,
      domini: { listaDomini },
      fields
    } = this.props
    const { PREFISSO, CELLULARE, FLAG_CELLULARE } = fields
    this.otpUpdateState(
      'sms',
      `${getValueDominioFromKey(listaDomini, PREFISSO.dominio, formValues[PREFISSO.id])} ${formValues[CELLULARE.id]}`,
      FLAG_CELLULARE.id,
      location.pathname.indexOf('-PG') >= 0
    )
  }

  otpEmailToggle() {
    const { formValues, fields } = this.props
    const { EMAIL, FLAG_EMAIL } = fields
    this.otpUpdateState('email', formValues[EMAIL.id], FLAG_EMAIL.id)
  }

  otpUpdateState(label, riferimento, flagId, isPg) {
    const { validationCreateOtp, validationVerifyOtp } = this.props
    const flag = `flagCertificazione${label === 'sms' ? 'Cellulare' : 'Email'}`
    const createOtp = () => validationCreateOtp(label, { riferimento })
    const verifyOtp = codice => validationVerifyOtp(label, { codice, riferimento }, flag, isPg)
    createOtp()

    this.setState({
      modal: { label, createOtp, verifyOtp, flagId },
      showModal: true
    })
  }

  otpVerified(flag) {
    if (flag) {
      const { change, formValues, currentInitial, fields } = this.props
      const { PREFISSO, CELLULARE, FLAG_CELLULARE, EMAIL, FLAG_EMAIL } = fields
      const diff = Object.keys(currentInitial).reduce(
        (res, cur) => {
          if (cur === FLAG_CELLULARE.id || cur === FLAG_EMAIL.id) {
            return res
          }
          if (formValues[cur] !== currentInitial[cur]) {
            switch (cur) {
              case EMAIL.id:
                if (this.state.alreadyValidated.email) return res
                break
              case CELLULARE.id:
                if (this.state.alreadyValidated.cellulare) return res
                break
              case PREFISSO.id:
                if (this.state.alreadyValidated.cellulare) return res
                break
              default:
                break
            }
            return { ...res, array: [...res.array, cur] }
          } else if (cur === EMAIL.id) {
            return { ...res, sameEmail: true }
          } else if (cur === CELLULARE.id) {
            return { ...res, sameCellulare: true }
          } else if (cur === PREFISSO.id) {
            return { ...res, samePrefisso: true }
          }
          return res
        },
        {
          array: [],
          sameEmail: false,
          sameCellulare: false,
          samePrefisso: false
        }
      )
      const { array, sameEmail, sameCellulare, samePrefisso } = diff
      if (flag === 'Email') {
        if (
          (sameEmail && array.length === 0) ||
          (!sameEmail && array.length === 1 && array.find(e => e === EMAIL.id))
        ) {
          change(FLAG_EMAIL.id, true)
          this.setState({ alreadyValidated: {} })
        } else {
          change(FLAG_EMAIL.id, true)
          this.setState({ alreadyValidated: { email: formValues[EMAIL.id] } })
        }
      } else {
        if (
          (sameCellulare && !samePrefisso && array.length === 1 && array.find(e => e === PREFISSO.id)) ||
          (!sameCellulare && samePrefisso && array.length === 1 && array.find(e => e === CELLULARE.id)) ||
          (sameCellulare && samePrefisso && array.length === 0) ||
          (!sameCellulare &&
            !samePrefisso &&
            array.length === 2 &&
            array.find(e => e === CELLULARE.id) &&
            array.find(e => e === PREFISSO.id))
        ) {
          change(FLAG_CELLULARE.id, true)
          this.setState({ alreadyValidated: {} })
        } else {
          change(FLAG_CELLULARE.id, true)
          this.setState({
            alreadyValidated: {
              cellulare: formValues[CELLULARE.id],
              prefisso: formValues[PREFISSO.id]
            }
          })
        }
      }
    }
    this.setState({ showModal: false })
  }

  render() {
    const {
      domini,
      formValues,
      formErrors,
      fields,
      change,
      touch,
      cambioStato,
      certifiedPhone,
      certifiedEmail
    } = this.props
    const { PREFISSO, CELLULARE, EMAIL, FLAG_CELLULARE, FLAG_EMAIL } = fields

    return (
      <Fragment>
        <Row>
          <Col s={2} style={{ 'padding-left': '1.5rem' }}>
            <Field
              name={PREFISSO.id}
              s={12}
              label={PREFISSO.label}
              threshold={0}
              defaultValue={getAutocompleteDefaultOption(
                domini.listaDomini,
                fields.PREFISSO.dominio,
                formValues[fields.PREFISSO.id]
              )}
              value={formValues[PREFISSO.id]}
              options={mapDominiToAutocomplete(getDominio(domini, PREFISSO.dominio))}
              handleChange={(field, value) => {
                change(field, value)
                change(
                  FLAG_CELLULARE.id,
                  certifiedPhone.prefix === value && certifiedPhone.number === formValues[CELLULARE.id]
                )
                if (cambioStato) {
                  cambioStato()
                }
              }}
              component={renderAutocomplete}
            />
          </Col>
          <Col s={10} className="col-position-relative">
            <Field
              name={CELLULARE.id}
              s={12}
              // maxLength={10}
              label={CELLULARE.label}
              error={formErrors[CELLULARE.id] || formErrors[FLAG_CELLULARE.id]}
              value={formValues[CELLULARE.id]}
              change={(field, value) => {
                change(field, value)
                change(
                  FLAG_CELLULARE.id,
                  certifiedPhone.number === value && certifiedPhone.prefix === formValues[PREFISSO.id]
                )
                if (cambioStato) {
                  cambioStato()
                }
              }}
              touch={touch}
              component={InputText}
            />
            {!formErrors[CELLULARE.id] &&
            getValueDominioFromKey(domini.listaDomini, PREFISSO.dominio, formValues[PREFISSO.id]) &&
            formValues[CELLULARE.id] &&
            !formValues[FLAG_CELLULARE.id] &&
            this.state.canSendSms ? (
              <a onClick={this.otpSmsToggle} className="certifica">
                {infoContatti.button_link_certifica.toUpperCase()}
              </a>
            ) : !(!formErrors[CELLULARE.id] && formValues[CELLULARE.id] && formValues[FLAG_CELLULARE.id]) &&
              this.state.remainingTimeSms > 0 ? (
                <a onClick={null} className="certifica">
                  {`${infoContatti.button_link_attendi.toUpperCase()} ${Math.ceil(this.state.remainingTimeSms / 1000)}s`}
                </a>
            ) : (
              ''
            )}
            {!formErrors[CELLULARE.id] && formValues[CELLULARE.id] && formValues[FLAG_CELLULARE.id] ? (
              <a className="certificato">{infoContatti.suggestion_pin_verificato.toUpperCase()}</a>
            ) : (
              ''
            )}
          </Col>
        </Row>
        <Row className="m-top10">
          <Col s={10} offset="s2" className="col-position-relative">
            <Field
              name={EMAIL.id}
              s={12}
              type="email"
              maxLength={50}
              label={EMAIL.label}
              className={!formErrors[EMAIL.id] && formValues[EMAIL.id] ? 'certificante' : ''}
              error={formErrors[EMAIL.id] || formErrors[FLAG_EMAIL.id]}
              value={formValues[EMAIL.id]}
              change={(field, value) => {
                change(field, value)
                change(FLAG_EMAIL.id, certifiedEmail === value)
                if (cambioStato) {
                  cambioStato()
                }
              }}
              touch={touch}
              component={InputText}
            />
            {!formErrors[EMAIL.id] && formValues[EMAIL.id] && !formValues[FLAG_EMAIL.id] && this.state.canSendEmail ? (
              <a onClick={this.otpEmailToggle} className="certifica">
                {infoContatti.button_link_certifica.toUpperCase()}
              </a>
            ) : !(!formErrors[EMAIL.id] && formValues[EMAIL.id] && formValues[FLAG_EMAIL.id]) &&
              this.state.remainingTimeEmail > 0 ? (
                <a onClick={null} className="certifica">
                  {`${infoContatti.button_link_attendi.toUpperCase()} ${Math.ceil(
                  this.state.remainingTimeEmail / 1000
                )}s`}
                </a>
            ) : (
              ''
            )}
            {!formErrors[EMAIL.id] && formValues[EMAIL.id] && formValues[FLAG_EMAIL.id] && (
              <a className="certificato">{infoContatti.suggestion_pin_verificato.toUpperCase()}</a>
            )}
          </Col>
        </Row>
        <OtpVerify
          canSendSms={this.state.canSendSms}
          canSendEmail={this.state.canSendEmail}
          remainingTimeSms={this.state.remainingTimeSms}
          remainingTimeEmail={this.state.remainingTimeEmail}
          label={this.state.modal.label}
          callBack={this.otpVerified}
          cellulare={formValues[CELLULARE.id]}
          otpSmsToggle={this.otpSmsToggle}
          otpEmailToggle={this.otpEmailToggle}
          invia={this.state.modal.createOtp}
          verifica={this.state.modal.verifyOtp}
          api={this.props.verifyOtpRequest}
          otpStatus={this.props.otpStatus}
          show={this.state.showModal}
          verifyAndSaveFlag={this.props.verifyAndSaveFlag}
          isProspectPGAvailable={this.props.isProspectPGAvailable || null}
        />
      </Fragment>
    )
  }
}
export default ContattiForm

ContattiForm.propTypes = {
  domini: PropTypes.object,
  validationCreateOtp: PropTypes.func,
  validationVerifyOtp: PropTypes.func,
  createOtpRequest: PropTypes.object,
  verifyOtpRequest: PropTypes.object,
  otpStatus: PropTypes.object,
  formValues: PropTypes.object,
  formErrors: PropTypes.object,
  fields: PropTypes.object,
  currentInitial: PropTypes.object,
  change: PropTypes.func,
  touch: PropTypes.func,
  cambioStato: PropTypes.any,
  verifyAndSaveFlag: PropTypes.bool,
  certifiedPhone: PropTypes.object,
  isProspectPGAvailable: PropTypes.bool,
  certifiedEmail: PropTypes.string
}

ContattiForm.defaultProps = {
  formValues: {},
  formErrors: {}
}
