import * as Forms from '../../store/modules/forms/constants'

const commonDatiGiuridiche = {
  CODICE_FISCALE_PG: {
    id: 'taxCode',
    label: 'Codice Fiscale*',
    defaultValue: '',
    minLength: 16,
    maxLength: 16,
    // validazione: /^[a-zA-Z0-9]{16}$/g
    validazione: /^(?:(?:[B-DF-HJ-NP-TV-Z]|[AEIOU])[AEIOU][AEIOUX]|[B-DF-HJ-NP-TV-Z]{2}[A-Z]){2}[\dLMNP-V]{2}(?:[A-EHLMPR-T](?:[04LQ][1-9MNP-V]|[1256LMRS][\dLMNP-V])|[DHPS][37PT][0L]|[ACELMRT][37PT][01LM])(?:[A-MZ][1-9MNP-V][\dLMNP-V]{2}|[A-M][0L](?:[1-9MNP-V][\dLMNP-V]|[0L][1-9MNP-V]))[A-Z]$/i
  },
  PARTITA_IVA_PG: {
    id: 'vatCode',
    label: 'Partita IVA*',
    minLength: 11,
    maxLength: 11,
    defaultValue: '',
    validazione: /^[0-9]{11}$/g
  },
  CELLULARE_PG: {
    id: 'mobilePhone',
    label: 'Numero di telefono*',
    defaultValue: '',
    validazione: /^[0-9]*$/g
  },
  EMAIL_PG: {
    id: 'email',
    label: 'Email*',
    defaultValue: '',
    validazione: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/
  },
  RAGIONE_SOCIALE_PG: {
    id: 'businessName',
    label: 'Ragione sociale*',
    defaultValue: ''
  },
  FORMA_ASSOCIATIVA_PG: {
    id: 'formCorporationCode',
    label: 'Forma associativa',
    defaultValue: '',
    dominio: 'DOM_FORMA_ASSOCIATIVA',
    // dominio: 'DOM_PROVINCE_RILASCIO_PG',
    dominioDep: null,
    validazione: /^[a-zA-Z0-9]*$/g
  },
  SEDE_LEGALE_PG: {
    id: 'registeredOfficeAddress',
    // label: 'Indirizzo e numero civico di Sede Legale*',
    label: 'Sede Legale (Via e numero civico)',
    maxLength: 100,
    defaultValue: '',
    validazione: /^[a-zA-Z0-9\s]*$/g
  },
  NAZIONE_PG: {
    id: 'nation',
    label: 'Nazione*',
    defaultValue: '',
    dominio: 'DOM_NAZIONE_SEDE_LEGALE_PG',
    dominioDep: null,
    italiaId: 'ITA',
    validazione: /^[a-zA-Z0-9]*$/g
  },
  PROVINCIA_PG: {
    id: 'province',
    label: 'Provincia*',
    defaultValue: '',
    dominio: 'DOM_PROVINCE_SEDE_LEGALE_PG',
    dominioDep: null,
    validazione: /^[a-zA-Z0-9]*$/g
  },
  CITTA_PG: {
    id: 'city',
    label: 'Città*',
    defaultValue: '',
    dominio: 'DOM_COMUNE_SEDE_LEGALE_PG',
    dominioDep: 'province',
    validazione: /^[a-zA-Z0-9]*$/g
  },
  CITTA_ESTERO_PG: {
    id: 'place',
    label: 'Città estera*',
    defaultValue: ''
  },
  NOME: {
    id: 'name',
    label: 'Nome*',
    defaultValue: '',
    validazione: /^[a-zA-ZÀÁÈÉÌÍÒÓÙÚà-ž\s'’-]*$/g,
    daResidenza: true
  },
  CAP_PG: {
    id: 'zipCode',
    label: 'CAP*',
    defaultValue: '',
    dominio: 'DOM_CAP_SEDE_LEGALE_PG',
    dominioDep: 'city',
    validazione: /^[a-zA-Z0-9]*$/g
  },
  DATA_COSTITUZIONE_PG: {
    id: 'creationDate',
    label: 'Data costituzione*',
    defaultValue: ''
  },
  NAZIONE_PG_COSTITUZIONE: {
    id: 'nationCreation',
    label: 'Nazione del luogo di costituzione*',
    defaultValue: '',
    dominio: 'DOM_NAZIONE_COSTITUZIONE_PG',
    dominioDep: null,
    italiaId: 'ITA',
    validazione: /^[a-zA-Z0-9]*$/g
  },
  PROVINCIA_PG_COSTITUZIONE: {
    id: 'provinceCreation',
    label: 'Provincia del luogo di costituzione*',
    defaultValue: '',
    dominio: 'DOM_PROVINCE_COSTITUZIONE_PG',
    dominioDep: null,
    validazione: /^[a-zA-Z0-9]*$/g
  },
  CITTA_PG_COSTITUZIONE: {
    id: 'cityCreation',
    label: 'Luogo di costituzione*',
    defaultValue: '',
    dominio: 'DOM_COMUNE_COSTITUZIONE_PG',
    dominioDep: 'provinceCreation',
    validazione: /^[a-zA-Z0-9]*$/g
  },
  CODICE_ATECO_PG: {
    id: 'atecoCode',
    label: 'Codice Ateco*',
    defaultValue: ''
  },
  ATTIVITA_ESERCITATA_PG: {
    id: 'activitySectorCode',
    label: 'Attività esercitata*',
    defaultValue: '',
    dominio: 'DOM_ATTIVITA_ESERCITATA',
    // dominio: 'DOM_PROVINCE_RILASCIO_PG',
    dominioDep: null,
    validazione: /^[a-zA-Z0-9]*$/g
  }
}

export default [
  {
    [Forms.INFORMAZIONI_PERSONALI_GIURIDICHE]: {
      ...commonDatiGiuridiche
    },
    [Forms.CODICE_FISCALE]: {
      CODICE_FISCALE: {
        id: 'taxCode',
        constant: 'CODICE_FISCALE',
        label: 'Codice Fiscale*',
        defaultValue: '',
        minLength: 16,
        maxLength: 16,
        validazione: /^[a-zA-Z0-9]*$/g
      },
      FLAG_CF_FRONTE: {
        id: 'flagCFFronte',
        defaultValue: false
      },
      CF_FRONTE: {
        id: 'imgCFFronte',
        defaultValue: null,
        isImage: true,
        tipoAllegato: 'CF_FRONTE'
      },
      CF_FRONTE_THUMB: {
        id: 'imgCFFronteThumb',
        defaultValue: null,
        isThumb: true
      },
      FLAG_CF_RETRO: {
        id: 'flagCFRetro',
        defaultValue: false
      },
      CF_RETRO: {
        id: 'imgCFRetro',
        defaultValue: null,
        isImage: true,
        tipoAllegato: 'CF_RETRO'
      },
      CF_RETRO_THUMB: {
        id: 'imgCFRetroThumb',
        defaultValue: null,
        isThumb: true
      },
      FLAG_CUSTOMER_IS_BLACKLIST: {
        id: 'flagCustomerIsBlacklist',
        defaultValue: false
      }
    },
    [Forms.DOCUMENTO_IDENTITA_ALLEGATO]: {
      TIPO_DOCUMENTO: {
        id: 'tipoDocumento',
        label: 'Tipologia documento*',
        defaultValue: 'CI',
        dominio: 'DOM_TIPO_DOCUMENTO',
        dominioDep: null
      },
      DOC_CHECK: {
        id: 'DOC_CHECK',
        label: 'flag di conferma ricezione documenti',
        defaultValue: false
      },
      NUMERO_DOCUMENTO: {
        id: 'numeroDocumenti',
        label: 'Numero documento*',
        defaultValue: '',
        validazione: {
          default: /^[a-zA-Z0-9]*$/g,
          2: /^[a-z]{2}\s{0,1}\d{7}\s{0,1}[a-z]$/i,
          11: /(.*)/i
        }
      },
      NAZIONE_RILASCIO: {
        id: 'nazioneRilascio',
        label: 'Nazione di rilascio*',
        defaultValue: 'ITA',
        dominio: 'DOM_NAZIONE_RILASCIO',
        dominioDep: null,
        italiaId: 'ITA',
        validazione: /^[a-zA-Z0-9]*$/g
      },
      PROVINCIA_RILASCIO: {
        id: 'provinciaRilascio',
        label: 'Provincia di rilascio*',
        defaultValue: '',
        dominio: 'DOM_PROVINCE_RILASCIO',
        dominioDep: null,
        validazione: /^[a-zA-Z0-9]*$/g
      },
      COMUNE_RILASCIO: {
        id: 'comuneRilascio',
        label: 'Comune di rilascio*',
        defaultValue: '',
        dominio: 'DOM_COMUNE_RILASCIO',
        dominioDep: 'provinciaRilascio',
        validazione: /^[a-zA-Z0-9]*$/g
      },
      COMUNE_ESTERO_RILASCIO: {
        id: 'comuneEsteroRilascio',
        label: 'Comune di rilascio*',
        defaultValue: ''
      },
      DATA_RILASCIO: {
        id: 'dataRilascio',
        label: 'Data rilascio*',
        defaultValue: ''
      },
      DATA_SCADENZA: {
        id: 'dataScadenza',
        label: 'Data scadenza*',
        defaultValue: ''
      },
      FLAG_DOC_FRONTE: {
        id: 'flagDOCFronte',
        defaultValue: false
      },
      DOC_FRONTE: {
        id: 'imgDOCFronte',
        defaultValue: null,
        isImage: true,
        tipoAllegato: 'DOC_FRONTE'
      },
      DOC_FRONTE_THUMB: {
        id: 'imgDOCFronteThumb',
        defaultValue: null,
        isThumb: true
      },
      FLAG_DOC_RETRO: {
        id: 'flagDOCRetro',
        defaultValue: false
      },
      DOC_RETRO: {
        id: 'imgDOCRetro',
        defaultValue: null,
        isImage: true,
        tipoAllegato: 'DOC_RETRO'
      },
      DOC_RETRO_THUMB: {
        id: 'imgDOCRetroThumb',
        defaultValue: null,
        isThumb: true
      },
      HA_PROCURATORE: {
        // id: 'hasProcuratore',
        id: 'flagHasProcurator',
        defaultValue: false,
        label: 'Procura'
      }
    },

    [Forms.DOCUMENTO_IDENTITA_ALLEGATO_FISICA_RIFERIMENTO]: {
      DOC_CHECK: {
        id: 'DOC_CHECK',
        label: 'flag di conferma ricezione documenti',
        defaultValue: false
      },
      TIPO_DOCUMENTO: {
        id: 'documentType',
        label: 'Tipologia documento*',
        defaultValue: 'CI',
        dominio: 'DOM_TIPO_DOCUMENTO_PF_RIF',
        dominioDep: null
      },
      NUMERO_DOCUMENTO: {
        id: 'documentNumber',
        label: 'Numero documento*',
        defaultValue: '',
        validazione: {
          default: /^[a-zA-Z0-9]*$/g,
          2: /^[a-z]{2}\s{0,1}\d{7}\s{0,1}[a-z]$/i,
          11: /(.*)/i
        }
      },
      NAZIONE_RILASCIO: {
        id: 'nationRelaseCode',
        label: 'Nazione di rilascio*',
        defaultValue: 'ITA',
        dominio: 'DOM_NAZIONE_RILASCIO_PF_RIF',
        dominioDep: null,
        italiaId: 'ITA',
        validazione: /^[a-zA-Z0-9]*$/g
      },
      PROVINCIA_RILASCIO: {
        id: 'provinceReleaseCode',
        label: 'Provincia di rilascio*',
        defaultValue: '',
        dominio: 'DOM_PROVINCE_RILASCIO_PF_RIF',
        dominioDep: null,
        validazione: /^[a-zA-Z0-9]*$/g
      },
      COMUNE_RILASCIO: {
        id: 'cityReleaseCode',
        label: 'Comune di rilascio*',
        defaultValue: '',
        dominio: 'DOM_COMUNE_RILASCIO_PF_RIF',
        dominioDep: 'provinceReleaseCode',
        validazione: /^[a-zA-Z0-9]*$/g
      },

      DATA_RILASCIO: {
        id: 'releaseDate',
        label: 'Data rilascio*',
        defaultValue: ''
      },
      DATA_SCADENZA: {
        id: 'expireDate',
        label: 'Data scadenza*',
        defaultValue: ''
      },
      COMUNE_ESTERO_RILASCIO: {
        id: 'placeRelease',
        label: 'Comune di rilascio*',
        defaultValue: ''
      },
      FLAG_DOC_FRONTE: {
        id: 'flagDOCFronte',
        defaultValue: false
      },
      DOC_FRONTE: {
        id: 'isIdentityFrontUploaded',
        defaultValue: null,
        isImage: true,
        tipoAllegato: 'DOC_FRONTE'
      },
      DOC_FRONTE_THUMB: {
        id: 'imgDOCFronteThumb',
        defaultValue: null,
        isThumb: true
      },
      FLAG_DOC_RETRO: {
        id: 'flagDOCRetro',
        defaultValue: false
      },
      DOC_RETRO: {
        id: 'isIdentityBackUploaded',
        defaultValue: null,
        isImage: true,
        tipoAllegato: 'DOC_RETRO'
      },
      DOC_RETRO_THUMB: {
        id: 'imgDOCRetro',
        defaultValue: null,
        isThumb: true
      },
      HA_PROCURATORE: {
        id: 'hasProcuratore',
        defaultValue: false,
        label: 'Procura'
      }
    },

    [Forms.CENSIMENTO_PROCURATORE]: {
      NOME: {
        id: 'name',
        label: 'Nome*',
        defaultValue: '',
        maxLength: 50,
        validazione: /^[a-zA-ZÀÁÈÉÌÍÒÓÙÚà-ž\s'’-]*$/g
      },
      COGNOME: {
        id: 'surname',
        label: 'Cognome*',
        defaultValue: '',
        maxLength: 50,
        validazione: /^[a-zA-ZÀÁÈÉÌÍÒÓÙÚà-ž\s'’-]*$/g
      },
      CODICE_FISCALE: {
        id: 'taxCode',
        label: 'Codice Fiscale*',
        defaultValue: '',
        minLength: 16,
        maxLength: 16,
        validazione: /^[a-zA-Z0-9]*$/g
      },
      EMAIL: {
        id: 'email',
        label: 'Email',
        defaultValue: '',
        validazione: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/
      },
      // FLAG_EMAIL: {
      //   id: 'flagCertifiedEmail',
      //   defaultValue: false,
      //   validazione: true
      // },
      COMUNE_RESIDENZA_ESTERO: {
        id: 'foreignResidencePlace',
        label: 'Comune di residenza estero*',
        defaultValue: '',
        dominio: 'DOM_COMUNE_RESIDENZA_ESTERO_PROC',
        dominioDep: 'foreignResidenceProvince',
        validazione: /^[a-zA-Z0-9]*$/g
      },
      RESIDENZA_ANAGRAFICA: {
        id: 'registeredResidenceCode',
        label: 'Nazione di residenza*',
        defaultValue: 'ITA',
        dominio: 'DOM_NAZIONE_RESIDENZA',
        dominioDep: null,
        italiaId: 'ITA',
        validazione: /^[a-zA-Z0-9]*$/g
      },
      PROVINCIA_RESIDENZA: {
        id: 'residenceProvince',
        label: 'Provincia di residenza*',
        defaultValue: '',
        dominio: 'DOM_PROVINCE_RESIDENZA_PROC',
        dominioDep: null,
        validazione: /^[a-zA-Z0-9]*$/g
      },
      COMUNE_RESIDENZA: {
        id: 'residencePlace',
        label: 'Comune di residenza*',
        defaultValue: '',
        dominio: 'DOM_COMUNE_RESIDENZA_PROC',
        dominioDep: 'residenceProvince',
        validazione: /^[a-zA-Z0-9]*$/g
      },
      INDIRIZZO: {
        id: 'residenceAddress',
        label: 'Indirizzo*',
        defaultValue: ''
      },
      CAP: {
        id: 'residenceZipCode',
        label: 'CAP*',
        defaultValue: '',
        dominio: 'DOM_CAP_RESIDENZA_PROC',
        dominioDep: 'residencePlace',
        validazione: /^[a-zA-Z0-9]*$/g
      },
      FLAG_CF_FRONTE: {
        id: 'flagTxFront',
        defaultValue: false
      },
      CF_FRONTE: {
        id: 'imgTxFront',
        label: 'Fronte Codice Fiscale*',
        defaultValue: null,
        isImage: true,
        tipoAllegato: 'CF_FRONTE'
      },
      CF_FRONTE_THUMB: {
        id: 'imgTxFrontThumb',
        defaultValue: null,
        isThumb: true
      },
      FLAG_CF_RETRO: {
        id: 'flagTxBack',
        defaultValue: false,
        validazione: true
      },
      CF_RETRO: {
        id: 'imgTxBack',
        label: 'Retro Codice Fiscale*',
        defaultValue: null,
        isImage: true,
        tipoAllegato: 'CF_RETRO'
      },
      CF_RETRO_THUMB: {
        id: 'imgTxBackThumb',
        defaultValue: null,
        isThumb: true
      },
      PRIVACY_DOC: {
        // WARN: Da verificare
        id: 'privacyDoc',
        defaultValue: null,
        isImage: true,
        tipoAllegato: 'PRIVACY_DOC'
      },
      PRIVACY_DOC_THUMB: {
        // WARN: Da verificare
        id: 'privacyDocThumb',
        defaultValue: null,
        isThumb: true
      }
    },
    [Forms.PROCURATORE_ID]: {
      PREFISSO: {
        label: 'Prefisso*',
        id: 'internationalPrefixCellNumberCode',
        defaultValue: '+39',
        dominio: 'DOM_PREFISSI',
        dominioDep: null
      },
      CELLULARE: {
        id: 'mobileNumber',
        label: 'Numero di telefono*',
        defaultValue: '',
        validazione: /^[0-9]*$/g
      },
      FLAG_CELLULARE: {
        id: 'flagCertifiedMobile',
        defaultValue: false,
        validazione: true
      },
      EMAIL: {
        id: 'email',
        label: 'Email*',
        defaultValue: '',
        validazione: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/
      },
      FLAG_EMAIL: {
        id: 'flagCertifiedEmail',
        defaultValue: false,
        validazione: true
      },
      TIPO_DOCUMENTO: {
        id: 'documentType',
        label: 'Tipologia documento*',
        defaultValue: 'CI',
        dominio: 'DOM_TIPO_DOCUMENTO',
        dominioDep: null,
        validazione: /^[a-zA-Z0-9]*$/g
      },
      NAZIONE_RILASCIO: {
        id: 'releaseNation',
        label: 'Nazione di rilascio*',
        defaultValue: 'ITA',
        dominio: 'DOM_NAZIONE_RILASCIO',
        dominioDep: null,
        italiaId: 'ITA',
        validazione: /^[a-zA-Z0-9]*$/g
      },
      PROVINCIA_RILASCIO_PROC: {
        id: 'releaseProvince',
        label: 'Provincia di rilascio*',
        defaultValue: '',
        dominio: 'DOM_PROVINCE_RILASCIO_PROC',
        dominioDep: null,
        validazione: /^[a-zA-Z0-9]*$/g
      },
      COMUNE_RILASCIO_PROC: {
        id: 'releasePlace',
        label: 'Comune di rilascio*',
        defaultValue: '',
        dominio: 'DOM_COMUNE_RILASCIO_PROC',
        dominioDep: 'releaseProvince', // 'provinciaRilascio',
        validazione: /^[a-zA-Z0-9]*$/g
      },
      COMUNE_ESTERO_RILASCIO_PROC: {
        id: 'releasePlaceAbroad',
        label: 'Comune di rilascio estero*',
        defaultValue: ''
      },
      DATA_RILASCIO: {
        id: 'dateRelease',
        label: 'Data rilascio*',
        defaultValue: ''
      },
      DATA_SCADENZA: {
        id: 'dateExpired',
        label: 'Data scadenza*',
        defaultValue: ''
      },
      DOC_FRONTE: {
        id: 'imgDocFront',
        defaultValue: null,
        isImage: true,
        tipoAllegato: 'DOC_FRONTE'
      },
      DOC_RETRO: {
        id: 'imgDocBack',
        defaultValue: null,
        isImage: true,
        tipoAllegato: 'DOC_RETRO'
      },
      NUMERO_DOCUMENTO: {
        id: 'documentNumber',
        label: 'Numero documento*',
        defaultValue: '',
        validazione: {
          default: /^[a-zA-Z0-9]*$/g,
          2: /^[a-z]{2}\s{0,1}\d{7}\s{0,1}[a-z]$/i,
          11: /(.*)/i
        }
      },
      DOC_RETRO_THUMB: {
        id: 'imgDocRetroThumb',
        defaultValue: null,
        isThumb: true
      },
      DOC_FRONTE_THUMB: {
        id: 'imgDocFronteThumb',
        defaultValue: null,
        isThumb: true
      }
    },
    [Forms.DOCUMENTO_PROCURA]: {
      ATTORNEY_DOC: {
        id: 'imgDocAttorney',
        defaultValue: null,
        isImage: true,
        tipoAllegato: 'DOC_ATTORNEY'
      },
      ATTORNEY_DOC_THUMB: {
        id: 'imgDocAttorneyThumb',
        defaultValue: null,
        isThumb: true
      },
      ATTORNEY_DOC_FLAG: {
        id: 'flagDocAttorney',
        defaultValue: null,
        isThumb: true
      }
    },
    [Forms.DOCUMENTI_GIURIDICHE]: {
      ...commonDatiGiuridiche,
      HA_DOMICILIO: {
        id: 'flagDomicile',
        defaultValue: false,
        label: 'Indirizzo di corrispondenza'
      },
      RAGIONE_SOCIALE_DOMICILIO: {
        id: 'businessNameDomicile',
        defalultValue: '',
        label: 'Ragione sociale / Nome e Cognome*'
      },
      INDIRIZZO_DOMICILIO: {
        id: 'addressDomicile',
        label: 'Indirizzo*',
        defaultValue: ''
      },
      NAZIONE_DOMICILIO: {
        id: 'nationalDomicileCode',
        label: 'Nazione di corrispondenza*',
        defaultValue: 'ITA',
        dominio: 'DOM_NAZIONE_DOMICILIO',
        dominioDep: null,
        italiaId: 'ITA',
        validazione: /^[a-zA-Z0-9]*$/g
      },
      PROVINCIA_DOMICILIO: {
        id: 'provinceDomicileCode',
        label: 'Provincia di corrispondenza*',
        defaultValue: '',
        dominio: 'DOM_PROVINCE_DOMICILIO',
        dominioDep: null,
        validazione: /^[a-zA-Z0-9]*$/g
      },
      COMUNE_DOMICILIO: {
        id: 'cityDomicileCode',
        label: 'Comune di corrispondenza*',
        defaultValue: '',
        dominio: 'DOM_COMUNE_DOMICILIO',
        dominioDep: 'provinceDomicileCode',
        validazione: /^[a-zA-Z0-9]*$/g
      },
      COMUNE_ESTERO_DOMICILIO: {
        id: 'placeDomicileCode',
        label: 'Comune di corrispondenza*',
        defaultValue: ''
      },
      CAP_DOMICILIO: {
        id: 'zipDomicile',
        label: 'CAP*',
        defaultValue: '',
        dominio: 'DOM_CAP_DOMICILIO',
        dominioDep: 'cityDomicileCode',
        validazione: /^[a-zA-Z0-9]*$/g
      },
      STATUTO: {
        id: 'isStatutoEurocapitalUploaded',
        defaultValue: null
      },
      VISURA_CAMERALE: {
        // WARN: Da verificare
        id: 'isVisuraCameraleUploaded',
        defaultValue: null
      },
      ATTO_COSTITUTIVO: {
        // WARN: Da verificare
        id: 'isAttoCostitutivoUploaded',
        defaultValue: null
      }
    },

    // "isPepForeign": true,
    // "isPepNational": true,
    // "isUniqueOwner": true,
    // "isOwner": true
    //   "isDelegate": true,
    // "isLegalRepresentative": true,

    [Forms.SCHEDA_DATI]: {
      TIPO_CLIENTE: {
        id: 'clientType',
        label: 'Il cliente è*',
        defaultValue: ''
      },
      INTRATTENERE_PEP: {
        id: 'intrattenerePep',
        defaultValue: ''
      },
      NOME: {
        id: 'name',
        label: 'Nome*',
        defaultValue: ''
      },
      COGNOME: {
        id: 'surname',
        label: 'Cognome*',
        defaultValue: ''
      },
      RAGIONE: {
        id: 'businessName',
        label: 'Ragione sociale*',
        defaultValue: '',
        validazione: /^[a-zA-Z\s'’]*$/g
      },
      CODICE_FISCALE: {
        id: 'taxCode',
        label: 'Codice fiscale*',
        defaultValue: '',
        minLength: 16,
        maxLength: 16,
        validazione: /^[a-zA-Z0-9]*$/g
      },
      CODICE_CLIENTE: {
        id: 'clientCode',
        label: 'Codice cliente',
        defaultValue: ''
      },

      DATA_NASCITA: {
        id: 'birthDate',
        label: 'Data di nascita*',
        defaultValue: ''
      },

      /** ** */
      NAZIONE_SCHEDA_DATI: {
        id: 'nationalResidenceCode',
        label: 'Nazione di residenza*',
        defaultValue: '',
        dominio: 'DOM_NAZIONE_RESIDENZA_PF_RIF',
        dominioDep: null,
        italiaId: 'ITA',
        statiUnitiId: 'USA',
        validazione: /^[a-zA-Z0-9]*$/g
      },
      NAZIONE_NASCITA_SCHEDA_DATI: {
        id: 'nationBirthCode',
        label: 'Nazione di nascita*',
        defaultValue: '',
        dominio: 'DOM_NAZIONE_NASCITA_PF_RIF',
        dominioDep: null,
        italiaId: 'ITA',
        validazione: /^[a-zA-Z0-9]*$/g
      },
      PROVINCIA_NASCITA_SCHEDA_DATI: {
        id: 'cityBirthCode',
        label: 'Luogo di nascita*',
        defaultValue: '',
        dominio: 'DOM_COMUNE_NASCITA_PF_RIF',
        dominioDep: null,
        validazione: /^[a-zA-Z0-9]*$/g
      },
      PROVINCIA_NASCITA_ESTERA_SCHEDA_DATI: {
        id: 'placeBirth',
        label: 'Luogo di nascita*',
        defaultValue: '',
        validazione: /^[a-zA-Z0-9]*$/g
      },
      PROVINCIA_SCHEDA_DATI: {
        id: 'provinceResidenceCode',
        label: 'Provincia di residenza*',
        defaultValue: '',
        dominio: 'DOM_PROVINCE_RESIDENZA',
        dominioDep: 'nationalityCode',
        validazione: /^[a-zA-Z0-9]*$/g
      },
      // PROVINCIA_ALTRO_SCHEDA_DATI: {
      //   id: 'placeResidenceCode',
      //   label: 'Provincia estero*',
      //   defaultValue: '',
      //   validazione: /^[a-zA-Z0-9]*$/g
      // },
      COMUNE_SCHEDA_DATI: {
        id: 'cityResidenceCode',
        label: 'Comune di residenza*',
        defaultValue: '',
        dominio: 'DOM_COMUNE_RESIDENZA_PF_RIF',
        dominioDep: 'provinceResidenceCode',
        validazione: /^[a-zA-Z0-9]*$/g
      },

      CAP_SCHEDA_DATI: {
        id: 'cap',
        label: 'CAP*',
        defaultValue: '',
        dominio: 'DOM_CAP_RESIDENZA_PF_RIF',
        dominioDep: 'cityResidenceCode',
        validazione: /^[a-zA-Z0-9]*$/g
      },
      INDIRIZZO_RESIDENZA: {
        id: 'addressResidenceCode',
        label: 'Indirizzo di residenza*',
        defaultValue: '',
        // validazione: /^[a-zA-Z0-9 ,'àáèéìíòóùúÀÁÈÉÌÍÒÓÙÚ]*$/g
        validazione: /^[a-zA-Z0-9 /,’'àáèéìíòóùúÀÁÈÉÌÍÒÓÙÚ]*$/g
      },

      COMUNE_ESTERO_SCHEDA_DATI: {
        // id: 'comuneEsteroNascita',
        id: 'placeResidence',
        label: 'Comune di nascita*',
        defaultValue: ''
      },

      RESIDENZA: {
        id: 'residence',
        label: 'Residenza*',
        defaultValue: ''
      },
      CAP: {
        id: 'cap',
        label: 'CAP*',
        defaultValue: ''
      },
      LOCALITA: {
        id: 'place',
        label: 'Località*',
        defaultValue: ''
      },
      EMAIL: {
        id: 'mail',
        label: 'Email*',
        defaultValue: '',
        validazione: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/
      },
      CELLULARE: {
        id: 'phone',
        label: 'Cellulare*',
        defaultValue: '',
        validazione: /^[0-9]*$/g
      },
      TIPO_DOCUMENTO: {
        id: 'documentType',
        label: 'Tipologia di documento*',
        defaultValue: 'CI',
        dominio: 'DOM_TIPO_DOCUMENTO',
        dominioDep: null
      },
      ESTREMI_DOCUMENTO: {
        id: 'documentNumber',
        label: 'Estremi documento*',
        defaultValue: ''
      },
      DATA_RILASCIO: {
        id: 'releaseDate',
        label: 'Data rilascio*',
        defaultValue: ''
      },
      DATA_SCADENZA: {
        id: 'expireDate',
        label: 'Data scadenza*',
        defaultValue: ''
      },
      LUOGO_RILASCIO: {
        id: 'placeRelease',
        label: 'Luogo rilascio*',
        dominio: 'DOM_COMUNE_NASCITA_PF_RIF',
        defaultValue: ''
      },
      TITOLO_STUDIO: {
        id: 'studyTitleCode',
        label: 'Titolo di studio*',
        defaultValue: '',
        propRisposta: 'descRisposta',
        dominio: 'DOM_TITOLO_STUDIO',
        dominioDep: null
      },

      SETTORE_OCCUPAZIONE: {
        id: 'occupationalSectoreCode',
        templateId: 47,
        defaultValue: '',
        label: 'Settore occupazione *',
        propRisposta: 'descRisposta',
        dominio: 'DOM_SETTORE_OCCUPAZIONE_I_AML',
        dominioDep: null,
        altroId: '07'
      },
      SETTORE_OCCUPAZIONE_ALTRO: {
        id: 'occupationalSectoreCodeOther',
        templateId: 47,
        defaultValue: '',
        label: 'Altro Settore occupazione *',
        propRisposta: 'descRisposta',
        dominio: 'DOM_SETTORE_OCCUPAZIONE_II',
        dominioDep: null,
        altroId: '07'
      },

      PROFESSIONE_ATTUALE: {
        id: 'jobCode',
        templateId: 49,
        defaultValue: '',
        label: 'Professione *',
        propRisposta: 'descRisposta',
        dominio: 'DOM_PROFESSIONE_ATTUALE_AML',
        dominioDep: null,
        pensionatoId: '003',
        liberoProfId: '145'
      },
      PROFESSIONE_PRECEDENTE: {
        id: 'prevJobCode',
        templateId: 50,
        label: 'Professione precedente(pensionato) *',
        defaultValue: '',
        propRisposta: 'descRisposta',
        dominio: 'DOM_PROFESSIONE_ATTUALE_AML'
      },

      LAVORATORI: {
        id: 'descriptionActivity',
        label: 'LAVORATORI AUTONOMI/LIBERI PROFESSIONISTI*',
        defaultValue: ''
      },
      NAZIONE_ATTIVITA: {
        id: 'nationalActivityCode',
        label: 'Stato principale insediamento attività*',
        defaultValue: '',
        dominio: 'DOM_NAZIONE_RESIDENZA',
        dominioDep: null,
        italiaId: 'ITA',
        statiUnitiId: 'USA',
        validazione: /^[a-zA-Z0-9]*$/g
      },
      PROVINCIA_ATTIVITA: {
        id: 'provinceActivityCode',
        label: 'Provincia principale insediamento attività*',
        defaultValue: '',
        dominio: 'DOM_PROVINCE_PROFESSIONE',
        dominioDep: '',
        validazione: /^[a-zA-Z0-9]*$/g
      },
      PROVINCIA_ATTIVITA_ALTRO: {
        id: 'placeActivityCode',
        label: 'Stato o Provincia principale insediamento attività*',
        defaultValue: '',
        // dominio: 'DOM_COMUNE_NASCITA_PF_RIF',
        dominioDep: '',
        validazione: /^[a-zA-Z0-9]*$/g
      },
      ANNO_INIZIO: {
        id: 'activityYear',
        label: 'Anno inizio attività*',
        defaultValue: '',
        validazione: /^[0-9]*$/g
      },
      YES_NO_OWNER: {
        id: 'isUniqueOwner',
        label: 'Sei unico titolare effettivo?',
        defaultValue: ''
      },
      YES_NO_MULTI_OWNER: {
        id: 'isOwner',
        label: 'Sei uno dei titolari effettivi?',
        defaultValue: ''
      },
      FASCIA_REDDITO: {
        id: 'tipoFasciaReddito',
        label: 'LA MIA FASCIA DI REDDITO IMPONIBILE MEDIO DICHIARATO NEGLI ULTIMI 3 ANNI È PARI A:*',
        defaultValue: ''
      },

      // PEP
      TITOLARE_EFFETTIVO_PEP_0: {
        id: 'isTitolareEffettivoPEP_0',
        label: 'La Controlling Person è una persona politcamente esposta',
        defaultValue: ''
      },
      TIPO_CLIENTE_PEP_0: {
        id: 'tipologiaCliente_0',
        label: 'Il sottoscritto in qualità di:',
        defaultValue: ''
      },
      TIPO_CLIENTE_PEP1_0: {
        id: 'tipologiaCliente1_0',
        label: 'Il sottoscritto in qualità di:',
        defaultValue: ''
      },
      RADIO_NO_PEP_0: {
        id: 'pepType',
        label: 'NO',
        defaultValue: ''
      },
      RADIO_PEP_NAZIONALE_0: {
        id: 'codicePepNazionale_0',
        label: 'SI, PEP NAZIONALE',
        defaultValue: ''
      },
      RADIO_PEP_ESTERO_0: {
        id: 'codicePepEstero_0',
        label: 'SI, PEP ESTERO',
        defaultValue: ''
      },
      RADIO_PEP_RAPPORTI_0: {
        id: 'codicePepRapporti_0',
        label: 'SI, RAPPORTI CON PEP',
        defaultValue: ''
      },
      NOME_PEP_0: {
        id: 'name',
        label: 'Nome*',
        defaultValue: '',
        validazione: /^[a-zA-Z\s'’]*$/g
      },
      RAGIONE_0: {
        id: 'businessName',
        label: 'Ragione sociale*',
        defaultValue: '',
        validazione: /^[a-zA-Z\s'’]*$/g
      },
      COGNOME_PEP_0: {
        id: 'surname',
        label: 'Cognome*',
        defaultValue: '',
        validazione: /^[a-zA-Z\s'’]*$/g
      },
      CONFERMA_ESSERE_0: {
        id: 'confermaDiEssereDescrizione_0',
        label: 'Conferma di essere…”',
        defaultValue: '',
        validazione: /^[a-zA-Z\s'’]*$/g
      },

      CODICEFISCALE_PIVA_0: {
        id: 'codiceFiscalePivaPep_0',
        label: 'Codice Fiscale / Partita IVA*',
        defaultValue: '',
        validazione: /^[a-zA-Z\s'’]*$/g
      },
      INTESTATARIO_0: {
        id: 'isIntestatario_0',
        label: 'Il sottoscritto in qualità di intestatario',
        defaultValue: '',
        validazione: /^[a-zA-Z\s'’]*$/g
      },
      SOTTOSCRITTO_IN_QUALITA_0: {
        id: 'codiceSottoscritto_0',
        label: 'Il sottoscritto in qualità di:*',
        defaultValue: ''
      },
      TITOLARE_DICHIARA_0: {
        id: 'codiceTitolareDichiara_0',
        label: 'Dichiara che il TITOLARE EFFETTIVO: ',
        defaultValue: ''
      },
      PEP_IN_QUANTO_0: {
        id: 'pepInQuanto_0',
        label: 'PEP IN QUANTO*',
        defaultValue: ''
      },
      PEP_FAMILIARE_0: {
        id: 'pepRelazione_0',
        label: 'PEP IN QUANTO*',
        defaultValue: ''
      },
      PEP_REDDITO_0: {
        id: 'tipoFasciaRedditoPep_0',
        label: 'LA MIA FASCIA DI REDDITO IMPONIBILE MEDIO DICHIARATO NEGLI ULTIMI 3 ANNI È PARI A:*',
        defaultValue: ''
      },
      PEP_PARTECIPAZIONI_0: {
        id: 'pepPartecipazioni_0',
        label: 'POSSIEDO PARTECIPAZIONI SOCIETARIE:*',
        defaultValue: ''
      },
      PEP_PROCEDIMENTI_0: {
        id: 'pepProcedimenti_0',
        label: 'SONO IN CORSO A MIO CARICO PROCEDIMENTI GIUDIZIARI:*',
        defaultValue: ''
      },
      CITTADINANZA_SCHEDA_DATI: {
        id: 'citizenship',
        label: 'Cittadinanza*',
        dominio: 'DOM_CITTADINANZE',
        dominioDep: 'citizenship',
        defalultValue: '',
        validazione: /^[a-zA-Z0-9]*$/g
      }
    },
    [Forms.CLIENTI_PROFESSIONALI]: {
      TYPE_PROFESSIONAL: {
        id: 'professionalSubjectType',
        defalultValue: ''
      },
      IS_PROFESSIONAL_SUBJECT_DONE: {
        id: 'isProfessionalSubjectDone',
        defalultValue: false
      },
      CLIENTI_PROFESSIONALI_SU_RICHIESTA: {
        id: 'CLIENTI_PROFESSIONALI_SU_RICHIESTA',
        label: 'su richiesta'
      },
      CLIENTI_PROFESSIONALI_DI_DIRITTO: {
        id: 'professionalClients',
        label: 'su richiesta',
        defalultValue: ''
      }
    },
    // FINE SCHEDA DATI
    [Forms.SCHEDA_DATI_TITOLARE]: {
      // PEP
      // PEP
      TITOLARE_EFFETTIVO_PEP_0: {
        id: 'isTitolareEffettivoPEP_0',
        label: 'La Controlling Person è una persona politcamente esposta',
        defaultValue: ''
      },
      TIPO_CLIENTE_PEP_0: {
        id: 'tipologiaCliente_0',
        label: 'Il sottoscritto in qualità di:',
        defaultValue: ''
      },
      TIPO_CLIENTE_PEP1_0: {
        id: 'tipologiaCliente1_0',
        label: 'Il sottoscritto in qualità di:',
        defaultValue: ''
      },
      RADIO_NO_PEP_0: {
        id: 'pepType_0',
        label: 'NO',
        defaultValue: ''
      },
      RADIO_PEP_NAZIONALE_0: {
        id: 'codicePepNazionale_0',
        label: 'SI, PEP NAZIONALE',
        defaultValue: ''
      },
      RADIO_PEP_ESTERO_0: {
        id: 'codicePepEstero_0',
        label: 'SI, PEP ESTERO',
        defaultValue: ''
      },
      RADIO_PEP_RAPPORTI_0: {
        id: 'codicePepRapporti_0',
        label: 'SI, RAPPORTI CON PEP',
        defaultValue: ''
      },

      NOME_PEP_0: {
        id: 'nome_0',
        label: 'Nome*',
        defaultValue: '',
        validazione: /^[a-zA-Z\s'’]*$/g
      },
      RAGIONE_0: {
        id: 'ragioneSociale_0',
        label: 'Ragione sociale*',
        defaultValue: '',
        validazione: /^[a-zA-Z\s'’]*$/g
      },
      COGNOME_PEP_0: {
        id: 'cognome_0',
        label: 'Cognome*',
        defaultValue: '',
        validazione: /^[a-zA-Z\s'’]*$/g
      },
      CONFERMA_ESSERE_0: {
        id: 'confermaDiEssereDescrizione_0',
        label: 'Conferma di essere…”',
        defaultValue: '',
        validazione: /^[a-zA-Z\s'’]*$/g
      },

      CODICEFISCALE_PIVA_0: {
        id: 'codiceFiscalePivaPep_0',
        label: 'Codice Fiscale / Partita IVA*',
        defaultValue: '',
        validazione: /^[a-zA-Z\s'’]*$/g
      },
      INTESTATARIO_0: {
        id: 'isIntestatario_0',
        label: 'Il sottoscritto in qualità di intestatario',
        defaultValue: '',
        validazione: /^[a-zA-Z\s'’]*$/g
      },
      SOTTOSCRITTO_IN_QUALITA_0: {
        id: 'codiceSottoscritto_0',
        label: 'Il sottoscritto in qualità di:*',
        defaultValue: ''
      },
      TITOLARE_DICHIARA_0: {
        id: 'codiceTitolareDichiara_0',
        label: 'Dichiara che il TITOLARE EFFETTIVO: ',
        defaultValue: ''
      },
      PEP_IN_QUANTO_0: {
        id: 'pepInQuanto_0',
        label: 'PEP IN QUANTO*',
        defaultValue: ''
      },
      PEP_FAMILIARE_0: {
        id: 'pepRelazione_0',
        label: 'PEP IN QUANTO*',
        defaultValue: ''
      },
      PEP_REDDITO_0: {
        id: 'tipoFasciaRedditoPep_0',
        label: 'LA MIA FASCIA DI REDDITO IMPONIBILE MEDIO DICHIARATO NEGLI ULTIMI 3 ANNI È PARI A:*',
        defaultValue: ''
      },
      PEP_PARTECIPAZIONI_0: {
        id: 'pepPartecipazioni_0',
        label: 'POSSIEDO PARTECIPAZIONI SOCIETARIE:*',
        defaultValue: ''
      },
      PEP_PROCEDIMENTI_0: {
        id: 'pepProcedimenti_0',
        label: 'SONO IN CORSO A MIO CARICO PROCEDIMENTI GIUDIZIARI:*',
        defaultValue: ''
      },
      // FINE PEP

      TIPO_CLIENTE_0: {
        id: 'tipoClienteTitolare_0',
        label: 'Il cliente è*',
        defaultValue: ''
      },
      INTRATTENERE_PEP_0: {
        id: 'intrattenerePepTitolare_0',
        defaultValue: ''
      },
      // titolare 0
      NOME_0: {
        id: 'name_0',
        label: 'Nome*',
        defaultValue: ''
      },
      COGNOME_0: {
        id: 'surname_0',
        label: 'Cognome*',
        defaultValue: ''
      },
      CODICE_FISCALE_0: {
        id: 'taxCode_0',
        label: 'Codice fiscale*',
        defaultValue: '',
        minLength: 16,
        maxLength: 16,
        validazione: /^[a-zA-Z0-9]*$/g
        // validazione: /^(?:(?:[B-DF-HJ-NP-TV-Z]|[AEIOU])[AEIOU][AEIOUX]|[B-DF-HJ-NP-TV-Z]{2}[A-Z]){2}[\dLMNP-V]{2}(?:[A-EHLMPR-T](?:[04LQ][1-9MNP-V]|[1256LMRS][\dLMNP-V])|[DHPS][37PT][0L]|[ACELMRT][37PT][01LM])(?:[A-MZ][1-9MNP-V][\dLMNP-V]{2}|[A-M][0L](?:[1-9MNP-V][\dLMNP-V]|[0L][1-9MNP-V]))[A-Z]$/i
      },

      DATA_NASCITA_0: {
        id: 'birthDate_0',
        label: 'Data di nascita*',
        defaultValue: ''
      },
      NAZIONE_NASCITA_SCHEDA_DATI_0: {
        id: 'nationBirthCode_0',
        label: 'Nazione di Nascita*',
        defaultValue: '',
        dominio: 'DOM_NAZIONE_NASCITA_TE1',
        dominioDep: null,
        italiaId: 'ITA',
        statiUnitiId: 'USA',
        validazione: /^[a-zA-Z0-9]*$/g
      },
      PROVINCIA_NASCITA_SCHEDA_DATI_0: {
        id: 'provinceBirthCode_0',
        label: 'Provincia di nascita*',
        defaultValue: '',
        dominio: 'DOM_PROVINCE_NASCITA_TE1',
        validazione: /^[a-zA-Z0-9]*$/g
      },

      COMUNE_NASCITA_SCHEDA_DATI_0: {
        id: 'cityBirthCode_0',
        label: 'Comune di Nascita*',
        defaultValue: '',
        dominio: 'DOM_COMUNE_NASCITA_TE1',
        // dominioDep: 'provinceBirthCode_0',
        validazione: /^[a-zA-Z0-9]*$/g
      },

      CAP_SCHEDA_DATI_0: {
        id: 'cap_0',
        label: 'CAP di Residenza*',
        defaultValue: '',
        dominio: 'DOM_CAP_RESIDENZA',
        dominioDep: 'cityResidenceCode_0',
        validazione: /^[a-zA-Z0-9]*$/g
      },
      INDIRIZZO_0: {
        id: 'residence_0',
        label: 'Indirizzo, numero civico*',
        defaultValue: '',
        validazione: /^[a-zA-Z0-9 /,'’àáèéìíòóùúÀÁÈÉÌÍÒÓÙÚ]*$/g
      },

      COMUNE_NASCITA_ESTERO_SCHEDA_DATI_0: {
        id: 'cityBirthCode_0',
        label: 'Comune o città di nascita*',
        defaultValue: ''
      },
      CITTADINANZA_0: {
        id: 'citizenship_0',
        label: 'Cittadinanza*',
        defaultValue: '',
        dominio: 'DOM_CITTADINANZE',
        dominioDep: null,
        altroId: '4',
        statiUnitiId: 'USA',
        validazione: /^[a-zA-Z0-9]*$/g
      },
      NAZIONE_SCHEDA_DATI_0: {
        id: 'nationResidenceCode_0',
        label: 'Nazione di Residenza*',
        defaultValue: '',
        dominio: 'DOM_NAZIONE_RESIDENZA_TE1',
        dominioDep: null,
        italiaId: 'ITA',
        statiUnitiId: 'USA',
        validazione: /^[a-zA-Z0-9]*$/g
      },
      PROVINCIA_SCHEDA_DATI_0: {
        id: 'provinceResidenceCode_0',
        label: 'Provincia di residenza*',
        defaultValue: '',
        dominio: 'DOM_PROVINCE_RESIDENZA_TE1',
        validazione: /^[a-zA-Z0-9]*$/g
      },
      COMUNE_SCHEDA_DATI_0: {
        id: 'cityResidenceCode_0',
        label: 'Comune di residenza*',
        defaultValue: '',
        dominio: 'DOM_COMUNE_RESIDENZA_TE1',
        dominioDep: 'provinceCode_0',
        validazione: /^[a-zA-Z0-9]*$/g
      },
      COMUNE_ESTERO_SCHEDA_DATI_0: {
        id: 'cityResidenceCode_0',
        label: 'Comune di residenza*',
        defaultValue: '',
        validazione: /^[a-zA-Z0-9]*$/g
      },
      EMAIL_0: {
        id: 'mail_0',
        label: 'Email*',
        defaultValue: '',
        validazione: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/
      },
      CELLULARE_0: {
        id: 'phone_0',
        label: 'Cellulare*',
        defaultValue: '',
        validazione: /^[0-9]*$/g
      },

      TIPO_DOCUMENTO_0: {
        id: 'documentType_0',
        label: 'Tipologia documento*',
        defaultValue: 'CI',
        dominio: 'DOM_TIPO_DOCUMENTO',
        dominioDep: null
      },
      NUMERO_DOCUMENTO_0: {
        id: 'documentNumber_0',
        label: 'Numero documento*',
        defaultValue: '',
        validazione: /^[a-zA-Z0-9]*$/g
      },

      COMUNE_RILASCIO_0: {
        id: 'placeRelease_0',
        label: 'Comune di rilascio*',
        defaultValue: '',
        dominio: 'DOM_COMUNE_NASCITA_PF_RIF',
        validazione: /^[a-zA-Z0-9]*$/g
      },

      DATA_RILASCIO_0: {
        id: 'releaseDate_0',
        label: 'Data rilascio*',
        defaultValue: ''
      },
      DATA_SCADENZA_0: {
        id: 'expireDate_0',
        label: 'Data scadenza*',
        defaultValue: ''
      },

      TIPO_RELAZIONE_0: {
        id: 'relationshipType_0',
        label: 'Relazione tra Cliente e Titolare Effettivo è:',
        defaultValue: ''
      },

      TIPO_RELAZIONE_ALTRO_0: {
        id: 'relationshipOtherDesc_0',
        label: 'Indicare la relazione*',
        defalultValue: '',
        validazione: /^[a-zA-Z ’'àáèéìíòóùúÀÁÈÉÌÍÒÓÙÚ]*$/g
      },

      NOME_POSTAL_0: {
        id: 'namePostalInfo_0',
        label: 'Nome*',
        defalultValue: ''
      },

      COGNOME_POSTAL_0: {
        id: 'surnamePostalInfo_0',
        label: 'Cognome*',
        defalultValue: ''
      },

      FLAG_POSTAL_0: {
        id: 'flagPostalInfo_0',
        label: 'Indirizzo di corrispondenza diverso da quello di residenza ',
        defalultValue: false
      },

      NAZIONE_POSTAL_0: {
        id: 'nationCodePostalInfo_0',
        label: 'Nazione*',
        defalultValue: '',
        dominio: 'DOM_NAZIONE_COMUNICAZIONI_TE1',
        dominioDep: null,
        italiaId: 'ITA',
        statiUnitiId: 'USA',
        validazione: /^[a-zA-Z0-9]*$/g
      },

      PROVINCIA_POSTAL_0: {
        id: 'provinceCodePostalInfo_0',
        label: 'Provincia*',
        defalultValue: '',
        dominio: 'DOM_PROVINCE_COMUNICAZIONI_TE1',
        dominioDep: null,
        validazione: /^[a-zA-Z0-9]*$/g
      },

      COMUNE_POSTAL_0: {
        id: 'cityCodePostalInfo_0',
        label: 'Comune*',
        defalultValue: '',
        dominio: 'DOM_COMUNE_COMUNICAZIONI_TE1',
        dominioDep: 'provinceCodePostalInfo_0',
        validazione: /^[a-zA-Z0-9]*$/g
      },

      COMUNE_ESTERO_POSTAL_0: {
        id: 'placePostalInfo_0',
        label: 'Comune estero*',
        defalultValue: '',
        validazione: /^[a-zA-Z0-9]*$/g
      },

      CAP_POSTAL_0: {
        id: 'capPostalInfo_0',
        label: 'CAP*',
        defalultValue: '',
        dominio: 'DOM_CAP_COMUNICAZIONI_TE1',
        dominioDep: 'cityCodePostalInfo_0',
        validazione: /^[0-9]*$/g
      },

      INDIRIZZO_POSTAL_0: {
        id: 'residencePostalInfo_0',
        label: 'Indirizzo*',
        defalultValue: '',
        validazione: /^[a-zA-Z0-9]*$/g
      },

      NOME_POSTAL_1: {
        id: 'namePostalInfo_1',
        label: 'Nome*',
        defalultValue: ''
      },

      COGNOME_POSTAL_1: {
        id: 'surnamePostalInfo_1',
        label: 'Cognome*',
        defalultValue: ''
      },

      FLAG_POSTAL_1: {
        id: 'flagPostalInfo_1',
        label: 'Indirizzo di corrispondenza diverso da quello di residenza ',
        defalultValue: false
      },

      NAZIONE_POSTAL_1: {
        id: 'nationCodePostalInfo_1',
        label: 'Nazione*',
        defalultValue: '',
        dominio: 'DOM_NAZIONE_COMUNICAZIONI_TE1',
        dominioDep: null,
        italiaId: 'ITA',
        statiUnitiId: 'USA',
        validazione: /^[a-zA-Z0-9]*$/g
      },

      PROVINCIA_POSTAL_1: {
        id: 'provinceCodePostalInfo_1',
        label: 'Provincia*',
        defalultValue: '',
        dominio: 'DOM_PROVINCE_COMUNICAZIONI_TE1',
        dominioDep: null,
        validazione: /^[a-zA-Z0-9]*$/g
      },

      COMUNE_POSTAL_1: {
        id: 'cityCodePostalInfo_1',
        label: 'Comune*',
        defalultValue: '',
        dominio: 'DOM_COMUNE_COMUNICAZIONI_TE1',
        dominioDep: 'provinceCodePostalInfo_1',
        validazione: /^[a-zA-Z0-9]*$/g
      },

      COMUNE_ESTERO_POSTAL_1: {
        id: 'placePostalInfo_1',
        label: 'Comune estero*',
        defalultValue: '',
        validazione: /^[a-zA-Z0-9]*$/g
      },

      CAP_POSTAL_1: {
        id: 'capPostalInfo_1',
        label: 'CAP*',
        defalultValue: '',
        dominio: 'DOM_CAP_COMUNICAZIONI_TE1',
        dominioDep: 'cityCodePostalInfo_1',
        validazione: /^[0-9]*$/g
      },

      INDIRIZZO_POSTAL_1: {
        id: 'residencePostalInfo_1',
        label: 'Indirizzo*',
        defalultValue: '',
        validazione: /^[a-zA-Z0-9]*$/g
      },

      NOME_POSTAL_2: {
        id: 'namePostalInfo_2',
        label: 'Nome*',
        defalultValue: ''
      },

      COGNOME_POSTAL_2: {
        id: 'surnamePostalInfo_2',
        label: 'Cognome*',
        defalultValue: ''
      },

      FLAG_POSTAL_2: {
        id: 'flagPostalInfo_2',
        label: 'Indirizzo di corrispondenza diverso da quello di residenza ',
        defalultValue: false
      },

      NAZIONE_POSTAL_2: {
        id: 'nationCodePostalInfo_2',
        label: 'Nazione*',
        defalultValue: '',
        dominio: 'DOM_NAZIONE_COMUNICAZIONI_TE1',
        dominioDep: null,
        italiaId: 'ITA',
        statiUnitiId: 'USA',
        validazione: /^[a-zA-Z0-9]*$/g
      },

      PROVINCIA_POSTAL_2: {
        id: 'provinceCodePostalInfo_2',
        label: 'Provincia*',
        defalultValue: '',
        dominio: 'DOM_PROVINCE_COMUNICAZIONI_TE1',
        dominioDep: null,
        validazione: /^[a-zA-Z0-9]*$/g
      },

      COMUNE_POSTAL_2: {
        id: 'cityCodePostalInfo_2',
        label: 'Comune*',
        defalultValue: '',
        dominio: 'DOM_COMUNE_COMUNICAZIONI_TE1',
        dominioDep: 'provinceCodePostalInfo_2',
        validazione: /^[a-zA-Z0-9]*$/g
      },

      COMUNE_ESTERO_POSTAL_2: {
        id: 'placePostalInfo_2',
        label: 'Comune estero*',
        defalultValue: '',
        validazione: /^[a-zA-Z0-9]*$/g
      },

      CAP_POSTAL_2: {
        id: 'capPostalInfo_2',
        label: 'CAP*',
        defalultValue: '',
        dominio: 'DOM_CAP_COMUNICAZIONI_TE1',
        dominioDep: 'cityCodePostalInfo_2',
        validazione: /^[0-9]*$/g
      },

      INDIRIZZO_POSTAL_2: {
        id: 'residencePostalInfo_2',
        label: 'Indirizzo*',
        defalultValue: '',
        validazione: /^[a-zA-Z0-9]*$/g
      },

      NOME_POSTAL_3: {
        id: 'namePostalInfo_3',
        label: 'Nome*',
        defalultValue: ''
      },

      COGNOME_POSTAL_3: {
        id: 'surnamePostalInfo_3',
        label: 'Cognome*',
        defalultValue: ''
      },

      FLAG_POSTAL_3: {
        id: 'flagPostalInfo_3',
        label: 'Indirizzo di corrispondenza diverso da quello di residenza ',
        defalultValue: false
      },

      NAZIONE_POSTAL_3: {
        id: 'nationCodePostalInfo_3',
        label: 'Nazione*',
        defalultValue: '',
        dominio: 'DOM_NAZIONE_COMUNICAZIONI_TE1',
        dominioDep: null,
        italiaId: 'ITA',
        statiUnitiId: 'USA',
        validazione: /^[a-zA-Z0-9]*$/g
      },

      PROVINCIA_POSTAL_3: {
        id: 'provinceCodePostalInfo_3',
        label: 'Provincia*',
        defalultValue: '',
        dominio: 'DOM_PROVINCE_COMUNICAZIONI_TE1',
        dominioDep: null,
        validazione: /^[a-zA-Z0-9]*$/g
      },

      COMUNE_POSTAL_3: {
        id: 'cityCodePostalInfo_3',
        label: 'Comune*',
        defalultValue: '',
        dominio: 'DOM_COMUNE_COMUNICAZIONI_TE1',
        dominioDep: 'provinceCodePostalInfo_3',
        validazione: /^[a-zA-Z0-9]*$/g
      },

      COMUNE_ESTERO_POSTAL_3: {
        id: 'placePostalInfo_3',
        label: 'Comune estero*',
        defalultValue: '',
        validazione: /^[a-zA-Z0-9]*$/g
      },

      CAP_POSTAL_3: {
        id: 'capPostalInfo_3',
        label: 'CAP*',
        defalultValue: '',
        dominio: 'DOM_CAP_COMUNICAZIONI_TE1',
        dominioDep: 'cityCodePostalInfo_3',
        validazione: /^[0-9]*$/g
      },

      INDIRIZZO_POSTAL_3: {
        id: 'residencePostalInfo_3',
        label: 'Indirizzo*',
        defalultValue: '',
        validazione: /^[a-zA-Z0-9]*$/g
      },

      NOME_POSTAL_4: {
        id: 'namePostalInfo_4',
        label: 'Nome*',
        defalultValue: ''
      },

      COGNOME_POSTAL_4: {
        id: 'surnamePostalInfo_4',
        label: 'Cognome*',
        defalultValue: ''
      },

      FLAG_POSTAL_4: {
        id: 'flagPostalInfo_4',
        label: 'Indirizzo di corrispondenza diverso da quello di residenza ',
        defalultValue: false
      },

      NAZIONE_POSTAL_4: {
        id: 'nationCodePostalInfo_4',
        label: 'Nazione*',
        defalultValue: '',
        dominio: 'DOM_NAZIONE_COMUNICAZIONI_TE1',
        dominioDep: null,
        italiaId: 'ITA',
        statiUnitiId: 'USA',
        validazione: /^[a-zA-Z0-9]*$/g
      },

      PROVINCIA_POSTAL_4: {
        id: 'provinceCodePostalInfo_4',
        label: 'Provincia*',
        defalultValue: '',
        dominio: 'DOM_PROVINCE_COMUNICAZIONI_TE1',
        dominioDep: null,
        validazione: /^[a-zA-Z0-9]*$/g
      },

      COMUNE_POSTAL_4: {
        id: 'cityCodePostalInfo_4',
        label: 'Comune*',
        defalultValue: '',
        dominio: 'DOM_COMUNE_COMUNICAZIONI_TE1',
        dominioDep: 'provinceCodePostalInfo_4',
        validazione: /^[a-zA-Z0-9]*$/g
      },

      COMUNE_ESTERO_POSTAL_4: {
        id: 'placePostalInfo_4',
        label: 'Comune estero*',
        defalultValue: '',
        validazione: /^[a-zA-Z0-9]*$/g
      },

      CAP_POSTAL_4: {
        id: 'capPostalInfo_4',
        label: 'CAP*',
        defalultValue: '',
        dominio: 'DOM_CAP_COMUNICAZIONI_TE1',
        dominioDep: 'cityCodePostalInfo_4',
        validazione: /^[0-9]*$/g
      },

      INDIRIZZO_POSTAL_4: {
        id: 'residencePostalInfo_4',
        label: 'Indirizzo*',
        defalultValue: '',
        validazione: /^[a-zA-Z0-9]*$/g
      },

      // finetitolare 0
      // titolare 1
      // PEP
      TITOLARE_EFFETTIVO_PEP_1: {
        id: 'isTitolareEffettivoPEP_1',
        label: 'La Controlling Person è una persona politcamente esposta',
        defaultValue: ''
      },
      TIPO_CLIENTE_PEP_1: {
        id: 'tipologiaCliente_1',
        label: 'Il sottoscritto in qualità di:',
        defaultValue: ''
      },
      TIPO_CLIENTE_PEP1_1: {
        id: 'tipologiaCliente1_1',
        label: 'Il sottoscritto in qualità di:',
        defaultValue: ''
      },
      RADIO_NO_PEP_1: {
        id: 'pepType_1',
        label: 'NO',
        defaultValue: ''
      },
      RADIO_PEP_NAZIONALE_1: {
        id: 'codicePepNazionale_1',
        label: 'SI, PEP NAZIONALE',
        defaultValue: ''
      },
      RADIO_PEP_ESTERO_1: {
        id: 'codicePepEstero_1',
        label: 'SI, PEP ESTERO',
        defaultValue: ''
      },
      RADIO_PEP_RAPPORTI_1: {
        id: 'codicePepRapporti_1',
        label: 'SI, RAPPORTI CON PEP',
        defaultValue: ''
      },

      NOME_PEP_1: {
        id: 'nome_1',
        label: 'Nome*',
        defaultValue: '',
        validazione: /^[a-zA-Z\s'’]*$/g
      },
      RAGIONE_1: {
        id: 'ragioneSociale_1',
        label: 'Ragione sociale*',
        defaultValue: '',
        validazione: /^[a-zA-Z\s'’]*$/g
      },
      COGNOME_PEP_1: {
        id: 'cognome_1',
        label: 'Cognome*',
        defaultValue: '',
        validazione: /^[a-zA-Z\s'’]*$/g
      },
      CONFERMA_ESSERE_1: {
        id: 'confermaDiEssereDescrizione_1',
        label: 'Conferma di essere…”',
        defaultValue: '',
        validazione: /^[a-zA-Z\s'’]*$/g
      },

      CODICEFISCALE_PIVA_1: {
        id: 'codiceFiscalePivaPep_1',
        label: 'Codice Fiscale / Partita IVA*',
        defaultValue: '',
        validazione: /^[a-zA-Z\s'’]*$/g
      },
      INTESTATARIO_1: {
        id: 'isIntestatario_1',
        label: 'Il sottoscritto in qualità di intestatario',
        defaultValue: '',
        validazione: /^[a-zA-Z\s'’]*$/g
      },
      SOTTOSCRITTO_IN_QUALITA_1: {
        id: 'codiceSottoscritto_1',
        label: 'Il sottoscritto in qualità di:*',
        defaultValue: ''
      },
      TITOLARE_DICHIARA_1: {
        id: 'codiceTitolareDichiara_1',
        label: 'Dichiara che il TITOLARE EFFETTIVO: ',
        defaultValue: ''
      },
      PEP_IN_QUANTO_1: {
        id: 'pepInQuanto_1',
        label: 'PEP IN QUANTO*',
        defaultValue: ''
      },
      PEP_FAMILIARE_1: {
        id: 'pepRelazione_1',
        label: 'PEP IN QUANTO*',
        defaultValue: ''
      },
      PEP_REDDITO_1: {
        id: 'tipoFasciaRedditoPep_1',
        label: 'LA MIA FASCIA DI REDDITO IMPONIBILE MEDIO DICHIARATO NEGLI ULTIMI 3 ANNI È PARI A:*',
        defaultValue: ''
      },
      PEP_PARTECIPAZIONI_1: {
        id: 'pepPartecipazioni_1',
        label: 'POSSIEDO PARTECIPAZIONI SOCIETARIE:*',
        defaultValue: ''
      },
      PEP_PROCEDIMENTI_1: {
        id: 'pepProcedimenti_1',
        label: 'SONO IN CORSO A MIO CARICO PROCEDIMENTI GIUDIZIARI:*',
        defaultValue: ''
      },
      // FINE PEP

      TIPO_CLIENTE_1: {
        id: 'tipoClienteTitolare_1',
        label: 'Il cliente è*',
        defaultValue: ''
      },
      INTRATTENERE_PEP_1: {
        id: 'intrattenerePepTitolare_1',
        defaultValue: ''
      },
      // titolare 0
      NOME_1: {
        id: 'name_1',
        label: 'Nome*',
        defaultValue: ''
      },
      COGNOME_1: {
        id: 'surname_1',
        label: 'Cognome*',
        defaultValue: ''
      },
      CODICE_FISCALE_1: {
        id: 'taxCode_1',
        label: 'Codice fiscale*',
        defaultValue: '',
        minLength: 16,
        maxLength: 16,
        validazione: /^[a-zA-Z0-9]*$/g
      },

      DATA_NASCITA_1: {
        id: 'birthDate_1',
        label: 'Data di nascita*',
        defaultValue: ''
      },
      NAZIONE_NASCITA_SCHEDA_DATI_1: {
        id: 'nationBirthCode_1',
        label: 'Nazione di Nascita*',
        defaultValue: '',
        dominio: 'DOM_NAZIONE_NASCITA_TE2',
        dominioDep: null,
        italiaId: 'ITA',
        statiUnitiId: 'USA',
        validazione: /^[a-zA-Z0-9]*$/g
      },
      PROVINCIA_NASCITA_SCHEDA_DATI_1: {
        id: 'provinceBirthCode_1',
        label: 'Provincia di nascita*',
        defaultValue: '',
        dominio: 'DOM_PROVINCE_NASCITA_TE2',
        validazione: /^[a-zA-Z0-9]*$/g
      },
      COMUNE_NASCITA_SCHEDA_DATI_1: {
        id: 'cityBirthCode_1',
        label: 'Comune di Nascita*',
        defaultValue: '',
        dominio: 'DOM_COMUNE_NASCITA_TE2',
        validazione: /^[a-zA-Z0-9]*$/g
      },

      INDIRIZZO_1: {
        id: 'residence_1',
        label: 'Indirizzo, numero civico*',
        defaultValue: '',
        validazione: /^[a-zA-Z0-9 /,'’àáèéìíòóùúÀÁÈÉÌÍÒÓÙÚ]*$/g
      },

      COMUNE_NASCITA_ESTERO_SCHEDA_DATI_1: {
        id: 'cityBirthCode_1',
        label: 'Comune o città di nascita*',
        defaultValue: ''
      },
      CITTADINANZA_1: {
        id: 'citizenship_1',
        label: 'Cittadinanza*',
        defaultValue: '',
        dominio: 'DOM_CITTADINANZE',
        dominioDep: null,
        altroId: '4',
        statiUnitiId: 'USA',
        validazione: /^[a-zA-Z0-9]*$/g
      },
      NAZIONE_SCHEDA_DATI_1: {
        id: 'nationResidenceCode_1',
        label: 'Nazione di Residenza*',
        defaultValue: '',
        dominio: 'DOM_NAZIONE_RESIDENZA_TE2',
        dominioDep: null,
        italiaId: 'ITA',
        statiUnitiId: 'USA',
        validazione: /^[a-zA-Z0-9]*$/g
      },
      PROVINCIA_SCHEDA_DATI_1: {
        id: 'provinceResidenceCode_1',
        label: 'Provincia di residenza*',
        defaultValue: '',
        dominio: 'DOM_PROVINCE_RESIDENZA_TE2',
        validazione: /^[a-zA-Z0-9]*$/g
      },
      COMUNE_SCHEDA_DATI_1: {
        id: 'cityResidenceCode_1',
        label: 'Comune di residenza*',
        defaultValue: '',
        dominio: 'DOM_COMUNE_RESIDENZA_TE2',
        dominioDep: 'provinceCode_1',
        validazione: /^[a-zA-Z0-9]*$/g
      },

      CAP_SCHEDA_DATI_1: {
        id: 'cap_1',
        label: 'CAP di Residenza*',
        defaultValue: '',
        dominio: 'DOM_CAP_RESIDENZA',
        dominioDep: 'cityResidenceCode_1',
        validazione: /^[a-zA-Z0-9]*$/g
      },
      COMUNE_ESTERO_SCHEDA_DATI_1: {
        id: 'cityResidenceCode_1',
        label: 'Comune di residenza*',
        defaultValue: '',
        validazione: /^[a-zA-Z0-9]*$/g
      },
      EMAIL_1: {
        id: 'mail_1',
        label: 'Email*',
        defaultValue: '',
        validazione: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/
      },
      CELLULARE_1: {
        id: 'phone_1',
        label: 'Cellulare*',
        defaultValue: '',
        validazione: /^[0-9]*$/g
      },

      TIPO_DOCUMENTO_1: {
        id: 'documentType_1',
        label: 'Tipologia documento*',
        defaultValue: 'CI',
        dominio: 'DOM_TIPO_DOCUMENTO',
        dominioDep: null
      },
      NUMERO_DOCUMENTO_1: {
        id: 'documentNumber_1',
        label: 'Numero documento*',
        defaultValue: '',
        validazione: /^[a-zA-Z0-9]*$/g
      },

      COMUNE_RILASCIO_1: {
        id: 'placeRelease_1',
        label: 'Comune di rilascio*',
        defaultValue: '',
        dominio: 'DOM_COMUNE_NASCITA_PF_RIF',
        validazione: /^[a-zA-Z0-9]*$/g
      },

      DATA_RILASCIO_1: {
        id: 'releaseDate_1',
        label: 'Data rilascio*',
        defaultValue: ''
      },
      DATA_SCADENZA_1: {
        id: 'expireDate_1',
        label: 'Data scadenza*',
        defaultValue: ''
      },
      TIPO_RELAZIONE_1: {
        id: 'relationshipType_1',
        label: 'Relazione tra Cliente e Titolare Effettivo è:',
        defaultValue: ''
      },
      TIPO_RELAZIONE_ALTRO_1: {
        id: 'relationshipOtherDesc_1',
        label: 'Indicare la relazione*',
        defalultValue: '',
        validazione: /^[a-zA-Z ’'àáèéìíòóùúÀÁÈÉÌÍÒÓÙÚ]*$/g
      },

      // fine titolare1

      // INIZIO TITOLARE 2
      TITOLARE_EFFETTIVO_PEP_2: {
        id: 'isTitolareEffettivoPEP_2',
        label: 'La Controlling Person è una persona politcamente esposta',
        defaultValue: ''
      },
      TIPO_CLIENTE_PEP_2: {
        id: 'tipologiaCliente_2',
        label: 'Il sottoscritto in qualità di:',
        defaultValue: ''
      },
      TIPO_CLIENTE_PEP1_2: {
        id: 'tipologiaCliente1_2',
        label: 'Il sottoscritto in qualità di:',
        defaultValue: ''
      },
      RADIO_NO_PEP_2: {
        id: 'pepType_2',
        label: 'NO',
        defaultValue: ''
      },
      RADIO_PEP_NAZIONALE_2: {
        id: 'codicePepNazionale_2',
        label: 'SI, PEP NAZIONALE',
        defaultValue: ''
      },
      RADIO_PEP_ESTERO_2: {
        id: 'codicePepEstero_2',
        label: 'SI, PEP ESTERO',
        defaultValue: ''
      },
      RADIO_PEP_RAPPORTI_2: {
        id: 'codicePepRapporti_2',
        label: 'SI, RAPPORTI CON PEP',
        defaultValue: ''
      },

      NOME_PEP_2: {
        id: 'nome_2',
        label: 'Nome*',
        defaultValue: '',
        validazione: /^[a-zA-Z\s’']*$/g
      },
      RAGIONE_2: {
        id: 'ragioneSociale_2',
        label: 'Ragione sociale*',
        defaultValue: '',
        validazione: /^[a-zA-Z\s'’]*$/g
      },
      COGNOME_PEP_2: {
        id: 'cognome_2',
        label: 'Cognome*',
        defaultValue: '',
        validazione: /^[a-zA-Z\s'’]*$/g
      },
      CONFERMA_ESSERE_2: {
        id: 'confermaDiEssereDescrizione_2',
        label: 'Conferma di essere…”',
        defaultValue: '',
        validazione: /^[a-zA-Z\s'’]*$/g
      },

      CODICEFISCALE_PIVA_2: {
        id: 'codiceFiscalePivaPep_2',
        label: 'Codice Fiscale / Partita IVA*',
        defaultValue: '',
        validazione: /^[a-zA-Z\s'’]*$/g
      },
      INTESTATARIO_2: {
        id: 'isIntestatario_2',
        label: 'Il sottoscritto in qualità di intestatario',
        defaultValue: '',
        validazione: /^[a-zA-Z\s'’]*$/g
      },
      SOTTOSCRITTO_IN_QUALITA_2: {
        id: 'codiceSottoscritto_2',
        label: 'Il sottoscritto in qualità di:*',
        defaultValue: ''
      },
      TITOLARE_DICHIARA_2: {
        id: 'codiceTitolareDichiara_2',
        label: 'Dichiara che il TITOLARE EFFETTIVO: ',
        defaultValue: ''
      },
      PEP_IN_QUANTO_2: {
        id: 'pepInQuanto_2',
        label: 'PEP IN QUANTO*',
        defaultValue: ''
      },
      PEP_FAMILIARE_2: {
        id: 'pepRelazione_2',
        label: 'PEP IN QUANTO*',
        defaultValue: ''
      },
      PEP_REDDITO_2: {
        id: 'tipoFasciaRedditoPep_2',
        label: 'LA MIA FASCIA DI REDDITO IMPONIBILE MEDIO DICHIARATO NEGLI ULTIMI 3 ANNI È PARI A:*',
        defaultValue: ''
      },
      PEP_PARTECIPAZIONI_2: {
        id: 'pepPartecipazioni_2',
        label: 'POSSIEDO PARTECIPAZIONI SOCIETARIE:*',
        defaultValue: ''
      },
      PEP_PROCEDIMENTI_2: {
        id: 'pepProcedimenti_2',
        label: 'SONO IN CORSO A MIO CARICO PROCEDIMENTI GIUDIZIARI:*',
        defaultValue: ''
      },
      // FINE PEP

      TIPO_CLIENTE_2: {
        id: 'tipoClienteTitolare_2',
        label: 'Il cliente è*',
        defaultValue: ''
      },
      INTRATTENERE_PEP_2: {
        id: 'intrattenerePepTitolare_2',
        defaultValue: ''
      },
      // titolare 0
      NOME_2: {
        id: 'name_2',
        label: 'Nome*',
        defaultValue: ''
      },
      COGNOME_2: {
        id: 'surname_2',
        label: 'Cognome*',
        defaultValue: ''
      },
      CODICE_FISCALE_2: {
        id: 'taxCode_2',
        label: 'Codice fiscale*',
        defaultValue: '',
        minLength: 16,
        maxLength: 16,
        validazione: /^[a-zA-Z0-9]*$/g
      },

      DATA_NASCITA_2: {
        id: 'birthDate_2',
        label: 'Data di nascita*',
        defaultValue: ''
      },
      NAZIONE_NASCITA_SCHEDA_DATI_2: {
        id: 'nationBirthCode_2',
        label: 'Nazione di Nascita*',
        defaultValue: '',
        dominio: 'DOM_NAZIONE_NASCITA_TE3',
        dominioDep: null,
        italiaId: 'ITA',
        statiUnitiId: 'USA',
        validazione: /^[a-zA-Z0-9]*$/g
      },
      PROVINCIA_NASCITA_SCHEDA_DATI_2: {
        id: 'provinceBirthCode_2',
        label: 'Provincia di nascita*',
        defaultValue: '',
        dominio: 'DOM_PROVINCE_NASCITA_TE3',
        validazione: /^[a-zA-Z0-9]*$/g
      },
      COMUNE_NASCITA_SCHEDA_DATI_2: {
        id: 'cityBirthCode_2',
        label: 'Comune di Nascita*',
        defaultValue: '',
        dominio: 'DOM_COMUNE_NASCITA_TE3',
        validazione: /^[a-zA-Z0-9]*$/g
      },

      INDIRIZZO_2: {
        id: 'residence_2',
        label: 'Indirizzo, numero civico*',
        defaultValue: '',
        validazione: /^[a-zA-Z0-9 /,’'àáèéìíòóùúÀÁÈÉÌÍÒÓÙÚ]*$/g
      },

      COMUNE_NASCITA_ESTERO_SCHEDA_DATI_2: {
        id: 'cityBirthCode_2',
        label: 'Comune o città di nascita*',
        defaultValue: ''
      },
      CITTADINANZA_2: {
        id: 'citizenship_2',
        label: 'Cittadinanza*',
        defaultValue: '',
        dominio: 'DOM_CITTADINANZE',
        dominioDep: null,
        altroId: '4',
        statiUnitiId: 'USA',
        validazione: /^[a-zA-Z0-9]*$/g
      },
      NAZIONE_SCHEDA_DATI_2: {
        id: 'nationResidenceCode_2',
        label: 'Nazione di Residenza*',
        defaultValue: '',
        dominio: 'DOM_NAZIONE_RESIDENZA_TE3',
        dominioDep: null,
        italiaId: 'ITA',
        statiUnitiId: 'USA',
        validazione: /^[a-zA-Z0-9]*$/g
      },
      PROVINCIA_SCHEDA_DATI_2: {
        id: 'provinceResidenceCode_2',
        label: 'Provincia di residenza*',
        defaultValue: '',
        dominio: 'DOM_PROVINCE_RESIDENZA_TE3',
        validazione: /^[a-zA-Z0-9]*$/g
      },
      COMUNE_SCHEDA_DATI_2: {
        id: 'cityResidenceCode_2',
        label: 'Comune di residenza*',
        defaultValue: '',
        dominio: 'DOM_COMUNE_RESIDENZA_TE3',
        dominioDep: 'provinceCode_2',
        validazione: /^[a-zA-Z0-9]*$/g
      },

      CAP_SCHEDA_DATI_2: {
        id: 'cap_2',
        label: 'CAP di Residenza*',
        defaultValue: '',
        dominio: 'DOM_CAP_RESIDENZA',
        dominioDep: 'cityResidenceCode_2',
        validazione: /^[a-zA-Z0-9]*$/g
      },
      COMUNE_ESTERO_SCHEDA_DATI_2: {
        id: 'cityResidenceCode_2',
        label: 'Comune di residenza*',
        defaultValue: '',
        validazione: /^[a-zA-Z0-9]*$/g
      },
      EMAIL_2: {
        id: 'mail_2',
        label: 'Email*',
        defaultValue: '',
        validazione: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/
      },
      CELLULARE_2: {
        id: 'phone_2',
        label: 'Cellulare*',
        defaultValue: '',
        validazione: /^[0-9]*$/g
      },

      TIPO_DOCUMENTO_2: {
        id: 'documentType_2',
        label: 'Tipologia documento*',
        defaultValue: 'CI',
        dominio: 'DOM_TIPO_DOCUMENTO',
        dominioDep: null
      },
      NUMERO_DOCUMENTO_2: {
        id: 'documentNumber_2',
        label: 'Numero documento*',
        defaultValue: '',
        validazione: /^[a-zA-Z0-9]*$/g
      },

      COMUNE_RILASCIO_2: {
        id: 'placeRelease_2',
        label: 'Comune di rilascio*',
        defaultValue: '',
        dominio: 'DOM_COMUNE_NASCITA_PF_RIF',
        validazione: /^[a-zA-Z0-9]*$/g
      },

      DATA_RILASCIO_2: {
        id: 'releaseDate_2',
        label: 'Data rilascio*',
        defaultValue: ''
      },
      DATA_SCADENZA_2: {
        id: 'expireDate_2',
        label: 'Data scadenza*',
        defaultValue: ''
      },
      TIPO_RELAZIONE_2: {
        id: 'relationshipType_2',
        label: 'Relazione tra Cliente e Titolare Effettivo è:',
        defaultValue: ''
      },
      TIPO_RELAZIONE_ALTRO_2: {
        id: 'relationshipOtherDesc_2',
        label: 'Indicare la relazione*',
        defalultValue: '',
        validazione: /^[a-zA-Z ’'àáèéìíòóùúÀÁÈÉÌÍÒÓÙÚ]*$/g
      },

      // fine titolare2

      // INIZIO TITOLARE 3
      TITOLARE_EFFETTIVO_PEP_3: {
        id: 'isTitolareEffettivoPEP_3',
        label: 'La Controlling Person è una persona politcamente esposta',
        defaultValue: ''
      },
      TIPO_CLIENTE_PEP_3: {
        id: 'tipologiaCliente_3',
        label: 'Il sottoscritto in qualità di:',
        defaultValue: ''
      },
      TIPO_CLIENTE_PEP1_3: {
        id: 'tipologiaCliente1_3',
        label: 'Il sottoscritto in qualità di:',
        defaultValue: ''
      },
      RADIO_NO_PEP_3: {
        id: 'pepType_3',
        label: 'NO',
        defaultValue: ''
      },
      RADIO_PEP_NAZIONALE_3: {
        id: 'codicePepNazionale_3',
        label: 'SI, PEP NAZIONALE',
        defaultValue: ''
      },
      RADIO_PEP_ESTERO_3: {
        id: 'codicePepEstero_3',
        label: 'SI, PEP ESTERO',
        defaultValue: ''
      },
      RADIO_PEP_RAPPORTI_3: {
        id: 'codicePepRapporti_3',
        label: 'SI, RAPPORTI CON PEP',
        defaultValue: ''
      },

      NOME_PEP_3: {
        id: 'nome_3',
        label: 'Nome*',
        defaultValue: '',
        validazione: /^[a-zA-Z\s'’]*$/g
      },
      RAGIONE_3: {
        id: 'ragioneSociale_3',
        label: 'Ragione sociale*',
        defaultValue: '',
        validazione: /^[a-zA-Z\s'’]*$/g
      },
      COGNOME_PEP_3: {
        id: 'cognome_3',
        label: 'Cognome*',
        defaultValue: '',
        validazione: /^[a-zA-Z\s'’]*$/g
      },
      CONFERMA_ESSERE_3: {
        id: 'confermaDiEssereDescrizione_3',
        label: 'Conferma di essere…”',
        defaultValue: '',
        validazione: /^[a-zA-Z\s'’]*$/g
      },

      CODICEFISCALE_PIVA_3: {
        id: 'codiceFiscalePivaPep_3',
        label: 'Codice Fiscale / Partita IVA*',
        defaultValue: '',
        validazione: /^[a-zA-Z\s'’]*$/g
      },
      INTESTATARIO_3: {
        id: 'isIntestatario_3',
        label: 'Il sottoscritto in qualità di intestatario',
        defaultValue: '',
        validazione: /^[a-zA-Z\s'’]*$/g
      },
      SOTTOSCRITTO_IN_QUALITA_3: {
        id: 'codiceSottoscritto_3',
        label: 'Il sottoscritto in qualità di:*',
        defaultValue: ''
      },
      TITOLARE_DICHIARA_3: {
        id: 'codiceTitolareDichiara_3',
        label: 'Dichiara che il TITOLARE EFFETTIVO: ',
        defaultValue: ''
      },
      PEP_IN_QUANTO_3: {
        id: 'pepInQuanto_3',
        label: 'PEP IN QUANTO*',
        defaultValue: ''
      },
      PEP_FAMILIARE_3: {
        id: 'pepRelazione_3',
        label: 'PEP IN QUANTO*',
        defaultValue: ''
      },
      PEP_REDDITO_3: {
        id: 'tipoFasciaRedditoPep_3',
        label: 'LA MIA FASCIA DI REDDITO IMPONIBILE MEDIO DICHIARATO NEGLI ULTIMI 3 ANNI È PARI A:*',
        defaultValue: ''
      },
      PEP_PARTECIPAZIONI_3: {
        id: 'pepPartecipazioni_3',
        label: 'POSSIEDO PARTECIPAZIONI SOCIETARIE:*',
        defaultValue: ''
      },
      PEP_PROCEDIMENTI_3: {
        id: 'pepProcedimenti_3',
        label: 'SONO IN CORSO A MIO CARICO PROCEDIMENTI GIUDIZIARI:*',
        defaultValue: ''
      },
      // FINE PEP

      TIPO_CLIENTE_3: {
        id: 'tipoClienteTitolare_3',
        label: 'Il cliente è*',
        defaultValue: ''
      },
      INTRATTENERE_PEP_3: {
        id: 'intrattenerePepTitolare_3',
        defaultValue: ''
      },
      // titolare 0
      NOME_3: {
        id: 'name_3',
        label: 'Nome*',
        defaultValue: ''
      },
      COGNOME_3: {
        id: 'surname_3',
        label: 'Cognome*',
        defaultValue: ''
      },
      CODICE_FISCALE_3: {
        id: 'taxCode_3',
        label: 'Codice fiscale*',
        defaultValue: '',
        minLength: 16,
        maxLength: 16,
        validazione: /^[a-zA-Z0-9]*$/g
      },

      DATA_NASCITA_3: {
        id: 'birthDate_3',
        label: 'Data di nascita*',
        defaultValue: ''
      },
      NAZIONE_NASCITA_SCHEDA_DATI_3: {
        id: 'nationBirthCode_3',
        label: 'Nazione di Nascita*',
        defaultValue: '',
        dominio: 'DOM_NAZIONE_NASCITA_TE4',
        dominioDep: null,
        italiaId: 'ITA',
        statiUnitiId: 'USA',
        validazione: /^[a-zA-Z0-9]*$/g
      },
      PROVINCIA_NASCITA_SCHEDA_DATI_3: {
        id: 'provinceBirthCode_3',
        label: 'Provincia di nascita*',
        defaultValue: '',
        dominio: 'DOM_PROVINCE_NASCITA_TE4',
        validazione: /^[a-zA-Z0-9]*$/g
      },
      COMUNE_NASCITA_SCHEDA_DATI_3: {
        id: 'cityBirthCode_3',
        label: 'Comune di Nascita*',
        defaultValue: '',
        dominio: 'DOM_COMUNE_NASCITA_TE4',
        validazione: /^[a-zA-Z0-9]*$/g
      },

      INDIRIZZO_3: {
        id: 'residence_3',
        label: 'Indirizzo, numero civico*',
        defaultValue: '',
        validazione: /^[a-zA-Z0-9 /,'’àáèéìíòóùúÀÁÈÉÌÍÒÓÙÚ]*$/g
      },

      COMUNE_NASCITA_ESTERO_SCHEDA_DATI_3: {
        id: 'cityBirthCode_3',
        label: 'Comune o città di nascita*',
        defaultValue: ''
      },
      CITTADINANZA_3: {
        id: 'citizenship_3',
        label: 'Cittadinanza*',
        defaultValue: '',
        dominio: 'DOM_CITTADINANZE',
        dominioDep: null,
        altroId: '4',
        statiUnitiId: 'USA',
        validazione: /^[a-zA-Z0-9]*$/g
      },
      NAZIONE_SCHEDA_DATI_3: {
        id: 'nationResidenceCode_3',
        label: 'Nazione di Residenza*',
        defaultValue: '',
        dominio: 'DOM_NAZIONE_RESIDENZA_TE4',
        dominioDep: null,
        italiaId: 'ITA',
        statiUnitiId: 'USA',
        validazione: /^[a-zA-Z0-9]*$/g
      },
      PROVINCIA_SCHEDA_DATI_3: {
        id: 'provinceResidenceCode_3',
        label: 'Provincia di residenza*',
        defaultValue: '',
        dominio: 'DOM_PROVINCE_RESIDENZA_TE4',
        validazione: /^[a-zA-Z0-9]*$/g
      },
      COMUNE_SCHEDA_DATI_3: {
        id: 'cityResidenceCode_3',
        label: 'Comune di residenza*',
        defaultValue: '',
        dominio: 'DOM_COMUNE_RESIDENZA_TE4',
        dominioDep: 'provinceCode_3',
        validazione: /^[a-zA-Z0-9]*$/g
      },

      CAP_SCHEDA_DATI_3: {
        id: 'cap_3',
        label: 'CAP di Residenza*',
        defaultValue: '',
        dominio: 'DOM_CAP_RESIDENZA',
        dominioDep: 'cityResidenceCode_3',
        validazione: /^[a-zA-Z0-9]*$/g
      },
      COMUNE_ESTERO_SCHEDA_DATI_3: {
        id: 'cityResidenceCode_3',
        label: 'Comune di residenza*',
        defaultValue: '',
        validazione: /^[a-zA-Z0-9]*$/g
      },
      EMAIL_3: {
        id: 'mail_3',
        label: 'Email*',
        defaultValue: '',
        validazione: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/
      },
      CELLULARE_3: {
        id: 'phone_3',
        label: 'Cellulare*',
        defaultValue: '',
        validazione: /^[0-9]*$/g
      },

      TIPO_DOCUMENTO_3: {
        id: 'documentType_3',
        label: 'Tipologia documento*',
        defaultValue: 'CI',
        dominio: 'DOM_TIPO_DOCUMENTO',
        dominioDep: null
      },
      NUMERO_DOCUMENTO_3: {
        id: 'documentNumber_3',
        label: 'Numero documento*',
        defaultValue: '',
        validazione: /^[a-zA-Z0-9]*$/g
      },

      COMUNE_RILASCIO_3: {
        id: 'placeRelease_3',
        label: 'Comune di rilascio*',
        defaultValue: '',
        dominio: 'DOM_COMUNE_NASCITA_PF_RIF',
        validazione: /^[a-zA-Z0-9]*$/g
      },

      DATA_RILASCIO_3: {
        id: 'releaseDate_3',
        label: 'Data rilascio*',
        defaultValue: ''
      },
      DATA_SCADENZA_3: {
        id: 'expireDate_3',
        label: 'Data scadenza*',
        defaultValue: ''
      },
      TIPO_RELAZIONE_3: {
        id: 'relationshipType_3',
        label: 'Relazione tra Cliente e Titolare Effettivo è:',
        defaultValue: ''
      },
      TIPO_RELAZIONE_ALTRO_3: {
        id: 'relationshipOtherDesc_3',
        label: 'Indicare la relazione*',
        defalultValue: '',
        validazione: /^[a-zA-Z '’àáèéìíòóùúÀÁÈÉÌÍÒÓÙÚ]*$/g
      },

      // fine titolare3

      // INIZIO TITOLARE 4
      TITOLARE_EFFETTIVO_PEP_4: {
        id: 'isTitolareEffettivoPEP_4',
        label: 'La Controlling Person è una persona politcamente esposta',
        defaultValue: ''
      },
      TIPO_CLIENTE_PEP_4: {
        id: 'tipologiaCliente_4',
        label: 'Il sottoscritto in qualità di:',
        defaultValue: ''
      },
      TIPO_CLIENTE_PEP1_4: {
        id: 'tipologiaCliente1_4',
        label: 'Il sottoscritto in qualità di:',
        defaultValue: ''
      },
      RADIO_NO_PEP_4: {
        id: 'pepType_4',
        label: 'NO',
        defaultValue: ''
      },
      RADIO_PEP_NAZIONALE_4: {
        id: 'codicePepNazionale_4',
        label: 'SI, PEP NAZIONALE',
        defaultValue: ''
      },
      RADIO_PEP_ESTERO_4: {
        id: 'codicePepEstero_4',
        label: 'SI, PEP ESTERO',
        defaultValue: ''
      },
      RADIO_PEP_RAPPORTI_4: {
        id: 'codicePepRapporti_4',
        label: 'SI, RAPPORTI CON PEP',
        defaultValue: ''
      },

      NOME_PEP_4: {
        id: 'nome_4',
        label: 'Nome*',
        defaultValue: '',
        validazione: /^[a-zA-Z\s'’]*$/g
      },
      RAGIONE_4: {
        id: 'ragioneSociale_4',
        label: 'Ragione sociale*',
        defaultValue: '',
        validazione: /^[a-zA-Z\s'’]*$/g
      },
      COGNOME_PEP_4: {
        id: 'cognome_4',
        label: 'Cognome*',
        defaultValue: '',
        validazione: /^[a-zA-Z\s'’]*$/g
      },
      CONFERMA_ESSERE_4: {
        id: 'confermaDiEssereDescrizione_4',
        label: 'Conferma di essere…”',
        defaultValue: '',
        validazione: /^[a-zA-Z\s'’]*$/g
      },

      CODICEFISCALE_PIVA_4: {
        id: 'codiceFiscalePivaPep_4',
        label: 'Codice Fiscale / Partita IVA*',
        defaultValue: '',
        validazione: /^[a-zA-Z\s'’]*$/g
      },
      INTESTATARIO_4: {
        id: 'isIntestatario_4',
        label: 'Il sottoscritto in qualità di intestatario',
        defaultValue: '',
        validazione: /^[a-zA-Z\s'’]*$/g
      },
      SOTTOSCRITTO_IN_QUALITA_4: {
        id: 'codiceSottoscritto_4',
        label: 'Il sottoscritto in qualità di:*',
        defaultValue: ''
      },
      TITOLARE_DICHIARA_4: {
        id: 'codiceTitolareDichiara_4',
        label: 'Dichiara che il TITOLARE EFFETTIVO: ',
        defaultValue: ''
      },
      PEP_IN_QUANTO_4: {
        id: 'pepInQuanto_4',
        label: 'PEP IN QUANTO*',
        defaultValue: ''
      },
      PEP_FAMILIARE_4: {
        id: 'pepRelazione_4',
        label: 'PEP IN QUANTO*',
        defaultValue: ''
      },
      PEP_REDDITO_4: {
        id: 'tipoFasciaRedditoPep_4',
        label: 'LA MIA FASCIA DI REDDITO IMPONIBILE MEDIO DICHIARATO NEGLI ULTIMI 3 ANNI È PARI A:*',
        defaultValue: ''
      },
      PEP_PARTECIPAZIONI_4: {
        id: 'pepPartecipazioni_4',
        label: 'POSSIEDO PARTECIPAZIONI SOCIETARIE:*',
        defaultValue: ''
      },
      PEP_PROCEDIMENTI_4: {
        id: 'pepProcedimenti_4',
        label: 'SONO IN CORSO A MIO CARICO PROCEDIMENTI GIUDIZIARI:*',
        defaultValue: ''
      },
      // FINE PEP

      TIPO_CLIENTE_4: {
        id: 'tipoClienteTitolare_4',
        label: 'Il cliente è*',
        defaultValue: ''
      },
      INTRATTENERE_PEP_4: {
        id: 'intrattenerePepTitolare_4',
        defaultValue: ''
      },
      // titolare 0
      NOME_4: {
        id: 'name_4',
        label: 'Nome*',
        defaultValue: ''
      },
      COGNOME_4: {
        id: 'surname_4',
        label: 'Cognome*',
        defaultValue: ''
      },
      CODICE_FISCALE_4: {
        id: 'taxCode_4',
        label: 'Codice fiscale*',
        defaultValue: '',
        minLength: 16,
        maxLength: 16,
        validazione: /^[a-zA-Z0-9]*$/g
      },

      DATA_NASCITA_4: {
        id: 'birthDate_4',
        label: 'Data di nascita*',
        defaultValue: ''
      },
      NAZIONE_NASCITA_SCHEDA_DATI_4: {
        id: 'nationBirthCode_4',
        label: 'Nazione di Nascita*',
        defaultValue: '',
        dominio: 'DOM_NAZIONE_NASCITA_TE5',
        dominioDep: null,
        italiaId: 'ITA',
        statiUnitiId: 'USA',
        validazione: /^[a-zA-Z0-9]*$/g
      },
      PROVINCIA_NASCITA_SCHEDA_DATI_4: {
        id: 'provinceBirthCode_4',
        label: 'Provincia di nascita*',
        defaultValue: '',
        dominio: 'DOM_PROVINCE_NASCITA_TE5',
        validazione: /^[a-zA-Z0-9]*$/g
      },
      COMUNE_NASCITA_SCHEDA_DATI_4: {
        id: 'cityBirthCode_4',
        label: 'Comune di Nascita*',
        defaultValue: '',
        dominio: 'DOM_COMUNE_NASCITA_TE5',
        validazione: /^[a-zA-Z0-9]*$/g
      },

      INDIRIZZO_4: {
        id: 'residence_4',
        label: 'Indirizzo, numero civico*',
        defaultValue: '',
        validazione: /^[a-zA-Z0-9 /,’'àáèéìíòóùúÀÁÈÉÌÍÒÓÙÚ]*$/g
      },

      COMUNE_NASCITA_ESTERO_SCHEDA_DATI_4: {
        id: 'cityBirthCode_4',
        label: 'Comune o città di nascita*',
        defaultValue: ''
      },
      CITTADINANZA_4: {
        id: 'citizenship_4',
        label: 'Cittadinanza*',
        defaultValue: '',
        dominio: 'DOM_CITTADINANZE',
        dominioDep: null,
        altroId: '4',
        statiUnitiId: 'USA',
        validazione: /^[a-zA-Z0-9]*$/g
      },
      NAZIONE_SCHEDA_DATI_4: {
        id: 'nationResidenceCode_4',
        label: 'Nazione di Residenza*',
        defaultValue: '',
        dominio: 'DOM_NAZIONE_RESIDENZA_TE5',
        dominioDep: null,
        italiaId: 'ITA',
        statiUnitiId: 'USA',
        validazione: /^[a-zA-Z0-9]*$/g
      },
      PROVINCIA_SCHEDA_DATI_4: {
        id: 'provinceResidenceCode_4',
        label: 'Provincia di residenza*',
        defaultValue: '',
        dominio: 'DOM_PROVINCE_RESIDENZA_TE5',
        validazione: /^[a-zA-Z0-9]*$/g
      },
      COMUNE_SCHEDA_DATI_4: {
        id: 'cityResidenceCode_4',
        label: 'Comune di residenza*',
        defaultValue: '',
        dominio: 'DOM_COMUNE_RESIDENZA_TE5',
        dominioDep: 'provinceCode_4',
        validazione: /^[a-zA-Z0-9]*$/g
      },

      CAP_SCHEDA_DATI_4: {
        id: 'cap_4',
        label: 'CAP di Residenza*',
        defaultValue: '',
        dominio: 'DOM_CAP_RESIDENZA',
        dominioDep: 'cityResidenceCode_4',
        validazione: /^[a-zA-Z0-9]*$/g
      },
      COMUNE_ESTERO_SCHEDA_DATI_4: {
        id: 'cityResidenceCode_4',
        label: 'Comune di residenza*',
        defaultValue: '',
        validazione: /^[a-zA-Z0-9]*$/g
      },
      EMAIL_4: {
        id: 'mail_4',
        label: 'Email*',
        defaultValue: '',
        validazione: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/
      },
      CELLULARE_4: {
        id: 'phone_4',
        label: 'Cellulare*',
        defaultValue: '',
        validazione: /^[0-9]*$/g
      },

      TIPO_DOCUMENTO_4: {
        id: 'documentType_4',
        label: 'Tipologia documento*',
        defaultValue: 'CI',
        dominio: 'DOM_TIPO_DOCUMENTO',
        dominioDep: null
      },
      NUMERO_DOCUMENTO_4: {
        id: 'documentNumber_4',
        label: 'Numero documento*',
        defaultValue: '',
        validazione: /^[a-zA-Z0-9]*$/g
      },

      COMUNE_RILASCIO_4: {
        id: 'placeRelease_4',
        label: 'Comune di rilascio*',
        defaultValue: '',
        dominio: 'DOM_COMUNE_NASCITA_PF_RIF',
        validazione: /^[a-zA-Z0-9]*$/g
      },

      DATA_RILASCIO_4: {
        id: 'releaseDate_4',
        label: 'Data rilascio*',
        defaultValue: ''
      },
      DATA_SCADENZA_4: {
        id: 'expireDate_4',
        label: 'Data scadenza*',
        defaultValue: ''
      },
      TIPO_RELAZIONE_4: {
        id: 'relationshipType_4',
        label: 'Relazione tra Cliente e Titolare Effettivo è:',
        defaultValue: ''
      },
      TIPO_RELAZIONE_ALTRO_4: {
        id: 'relationshipOtherDesc_4',
        label: 'Indicare la relazione*',
        defalultValue: '',
        validazione: /^[a-zA-Z ’'àáèéìíòóùúÀÁÈÉÌÍÒÓÙÚ]*$/g
      },

      // fine titolare4

      // INIZIO TITOLARE 5
      TITOLARE_EFFETTIVO_PEP_5: {
        id: 'isTitolareEffettivoPEP_5',
        label: 'La Controlling Person è una persona politcamente esposta',
        defaultValue: ''
      },
      TIPO_CLIENTE_PEP_5: {
        id: 'tipologiaCliente_5',
        label: 'Il sottoscritto in qualità di:',
        defaultValue: ''
      },
      TIPO_CLIENTE_PEP1_5: {
        id: 'tipologiaCliente1_5',
        label: 'Il sottoscritto in qualità di:',
        defaultValue: ''
      },
      RADIO_NO_PEP_5: {
        id: 'pepType_5',
        label: 'NO',
        defaultValue: ''
      },
      RADIO_PEP_NAZIONALE_5: {
        id: 'codicePepNazionale_5',
        label: 'SI, PEP NAZIONALE',
        defaultValue: ''
      },
      RADIO_PEP_ESTERO_5: {
        id: 'codicePepEstero_5',
        label: 'SI, PEP ESTERO',
        defaultValue: ''
      },
      RADIO_PEP_RAPPORTI_5: {
        id: 'codicePepRapporti_5',
        label: 'SI, RAPPORTI CON PEP',
        defaultValue: ''
      },

      NOME_PEP_5: {
        id: 'nome_5',
        label: 'Nome*',
        defaultValue: '',
        validazione: /^[a-zA-Z\s'’]*$/g
      },
      RAGIONE_5: {
        id: 'ragioneSociale_5',
        label: 'Ragione sociale*',
        defaultValue: '',
        validazione: /^[a-zA-Z\s'’]*$/g
      },
      COGNOME_PEP_5: {
        id: 'cognome_5',
        label: 'Cognome*',
        defaultValue: '',
        validazione: /^[a-zA-Z\s'’]*$/g
      },
      CONFERMA_ESSERE_5: {
        id: 'confermaDiEssereDescrizione_5',
        label: 'Conferma di essere…”',
        defaultValue: '',
        validazione: /^[a-zA-Z\s'’]*$/g
      },

      CODICEFISCALE_PIVA_5: {
        id: 'codiceFiscalePivaPep_5',
        label: 'Codice Fiscale / Partita IVA*',
        defaultValue: '',
        validazione: /^[a-zA-Z\s'’]*$/g
      },
      INTESTATARIO_5: {
        id: 'isIntestatario_5',
        label: 'Il sottoscritto in qualità di intestatario',
        defaultValue: '',
        validazione: /^[a-zA-Z\s'’]*$/g
      },
      SOTTOSCRITTO_IN_QUALITA_5: {
        id: 'codiceSottoscritto_5',
        label: 'Il sottoscritto in qualità di:*',
        defaultValue: ''
      },
      TITOLARE_DICHIARA_5: {
        id: 'codiceTitolareDichiara_5',
        label: 'Dichiara che il TITOLARE EFFETTIVO: ',
        defaultValue: ''
      },
      PEP_IN_QUANTO_5: {
        id: 'pepInQuanto_5',
        label: 'PEP IN QUANTO*',
        defaultValue: ''
      },
      PEP_FAMILIARE_5: {
        id: 'pepRelazione_5',
        label: 'PEP IN QUANTO*',
        defaultValue: ''
      },
      PEP_REDDITO_5: {
        id: 'tipoFasciaRedditoPep_5',
        label: 'LA MIA FASCIA DI REDDITO IMPONIBILE MEDIO DICHIARATO NEGLI ULTIMI 3 ANNI È PARI A:*',
        defaultValue: ''
      },
      PEP_PARTECIPAZIONI_5: {
        id: 'pepPartecipazioni_5',
        label: 'POSSIEDO PARTECIPAZIONI SOCIETARIE:*',
        defaultValue: ''
      },
      PEP_PROCEDIMENTI_5: {
        id: 'pepProcedimenti_5',
        label: 'SONO IN CORSO A MIO CARICO PROCEDIMENTI GIUDIZIARI:*',
        defaultValue: ''
      },
      // FINE PEP

      TIPO_CLIENTE_5: {
        id: 'tipoClienteTitolare_5',
        label: 'Il cliente è*',
        defaultValue: ''
      },
      INTRATTENERE_PEP_5: {
        id: 'intrattenerePepTitolare_5',
        defaultValue: ''
      },
      // titolare 0
      NOME_5: {
        id: 'name_5',
        label: 'Nome*',
        defaultValue: ''
      },
      COGNOME_5: {
        id: 'surname_5',
        label: 'Cognome*',
        defaultValue: ''
      },
      CODICE_FISCALE_5: {
        id: 'taxCode_5',
        label: 'Codice fiscale*',
        defaultValue: '',
        minLength: 16,
        maxLength: 16,
        validazione: /^[a-zA-Z0-9]*$/g
      },

      DATA_NASCITA_5: {
        id: 'birthDate_5',
        label: 'Data di nascita*',
        defaultValue: ''
      },
      NAZIONE_NASCITA_SCHEDA_DATI_5: {
        id: 'nationBirthCode_5',
        label: 'Nazione di Nascita*',
        defaultValue: '',
        dominio: 'DOM_NAZIONE_RESIDENZA_TE2',
        dominioDep: null,
        italiaId: 'ITA',
        statiUnitiId: 'USA',
        validazione: /^[a-zA-Z0-9]*$/g
      },
      PROVINCIA_NASCITA_SCHEDA_DATI_5: {
        id: 'provinceBirthCode_5',
        label: 'Provincia di nascita*',
        defaultValue: '',
        dominio: 'DOM_PROVINCE_RESIDENZA_TE2',
        validazione: /^[a-zA-Z0-9]*$/g
      },
      COMUNE_NASCITA_SCHEDA_DATI_5: {
        id: 'cityBirthCode_5',
        label: 'Comune di Nascita*',
        defaultValue: '',
        dominio: 'DOM_COMUNE_NASCITA_TE2',
        validazione: /^[a-zA-Z0-9]*$/g
      },

      INDIRIZZO_5: {
        id: 'residence_5',
        label: 'Indirizzo, numero civico*',
        defaultValue: '',
        validazione: /^[a-zA-Z0-9]*$/g
      },

      COMUNE_NASCITA_ESTERO_SCHEDA_DATI_5: {
        id: 'cityBirthCode_5',
        label: 'Comune o città di nascita*',
        defaultValue: ''
      },
      CITTADINANZA_5: {
        id: 'citizenship_5',
        label: 'Cittadinanza*',
        defaultValue: '',
        dominio: 'DOM_CITTADINANZE',
        dominioDep: null,
        altroId: '4',
        statiUnitiId: 'USA',
        validazione: /^[a-zA-Z0-9]*$/g
      },
      NAZIONE_SCHEDA_DATI_5: {
        id: 'nationResidenceCode_5',
        label: 'Nazione di Residenza*',
        defaultValue: '',
        dominio: 'DOM_NAZIONE_RESIDENZA_TE2',
        dominioDep: null,
        italiaId: 'ITA',
        statiUnitiId: 'USA',
        validazione: /^[a-zA-Z0-9]*$/g
      },
      PROVINCIA_SCHEDA_DATI_5: {
        id: 'provinceResidenceCode_5',
        label: 'Provincia di residenza*',
        defaultValue: '',
        dominio: 'DOM_PROVINCE_RESIDENZA_TE2',
        validazione: /^[a-zA-Z0-9]*$/g
      },
      COMUNE_SCHEDA_DATI_5: {
        id: 'cityResidenceCode_5',
        label: 'Comune di residenza*',
        defaultValue: '',
        dominio: 'DOM_COMUNE_RESIDENZA_TE2',
        dominioDep: 'provinceCode_5',
        validazione: /^[a-zA-Z0-9]*$/g
      },

      CAP_SCHEDA_DATI_5: {
        id: 'cap_5',
        label: 'CAP di Residenza*',
        defaultValue: '',
        dominio: 'DOM_CAP_RESIDENZA',
        dominioDep: 'cityResidenceCode_5',
        validazione: /^[a-zA-Z0-9]*$/g
      },
      COMUNE_ESTERO_SCHEDA_DATI_5: {
        id: 'cityResidenceCode_5',
        label: 'Comune di residenza*',
        defaultValue: '',
        validazione: /^[a-zA-Z0-9]*$/g
      },
      EMAIL_5: {
        id: 'mail_5',
        label: 'Email*',
        defaultValue: '',
        validazione: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/
      },
      CELLULARE_5: {
        id: 'phone_5',
        label: 'Cellulare*',
        defaultValue: '',
        validazione: /^[0-9]*$/g
      },

      TIPO_DOCUMENTO_5: {
        id: 'documentType_5',
        label: 'Tipologia documento*',
        defaultValue: 'CI',
        dominio: 'DOM_TIPO_DOCUMENTO',
        dominioDep: null
      },
      NUMERO_DOCUMENTO_5: {
        id: 'documentNumber_5',
        label: 'Numero documento*',
        defaultValue: '',
        validazione: /^[a-zA-Z0-9]*$/g
      },

      COMUNE_RILASCIO_5: {
        id: 'placeRelease_5',
        label: 'Comune di rilascio*',
        defaultValue: '',
        dominio: 'DOM_COMUNE_NASCITA_PF_RIF',
        validazione: /^[a-zA-Z0-9]*$/g
      },

      DATA_RILASCIO_5: {
        id: 'releaseDate_5',
        label: 'Data rilascio*',
        defaultValue: ''
      },
      DATA_SCADENZA_5: {
        id: 'expireDate_5',
        label: 'Data scadenza*',
        defaultValue: ''
      },
      TIPO_RELAZIONE_5: {
        id: 'relationshipType_5',
        label: 'Relazione tra Cliente e Titolare Effettivo è:',
        defaultValue: ''
      },
      TIPO_RELAZIONE_ALTRO_5: {
        id: 'relationshipOtherDesc_5',
        label: 'Indicare la relazione*',
        defalultValue: ''
      }

      // fine titolare5
    }
  },
  {
    [Forms.INFORMAZIONI_PERSONALI]: {
      NOME: {
        id: 'nome',
        label: 'Nome*',
        maxLength: 25,
        defaultValue: '',
        validazione: /^[a-zA-ZÀÁÈÉÌÍÒÓÙÚà-ž\s'’-]*$/g
      },
      COGNOME: {
        id: 'cognome',
        maxLength: 50,
        label: 'Cognome*',
        defaultValue: '',
        validazione: /^[a-zA-ZÀÁÈÉÌÍÒÓÙÚà-ž\s'’-]*$/g
      },
      CITTADINANZA: {
        id: 'codCittadinanza',
        label: 'Cittadinanza*',
        defaultValue: '',
        dominio: 'DOM_CITTADINANZE',
        dominioDep: null,
        altroId: '4',
        statiUnitiId: 'USA',
        validazione: /^[a-zA-Z0-9]*$/g
      },
      CITTADINANZA_ALTRO: {
        id: 'codAltraCittadinanza',
        label: 'Seconda cittadinanza',
        defaultValue: '',
        dominio: 'DOM_CITTADINANZE',
        statiUnitiId: 'USA',
        validazione: /^[a-zA-Z0-9]*$/g
      },
      NAZIONE_NASCITA: {
        id: 'codNazioneNascita',
        label: 'Nazione di nascita*',
        defaultValue: '',
        dominio: 'DOM_NAZIONE_NASCITA',
        dominioDep: null,
        italiaId: 'ITA',
        statiUnitiId: 'USA',
        validazione: /^[a-zA-Z0-9]*$/g
      },
      PROVINCIA_NASCITA: {
        id: 'codProvinciaNascita',
        label: 'Provincia di nascita*',
        defaultValue: '',
        dominio: 'DOM_PROVINCE_NASCITA',
        dominioDep: null,
        validazione: /^[a-zA-Z0-9]*$/g
      },
      COMUNE_NASCITA: {
        id: 'codComuneNascita',
        label: 'Comune di nascita*',
        defaultValue: '',
        dominio: 'DOM_COMUNE_NASCITA',
        dominioDep: 'codProvinciaNascita',
        validazione: /^[a-zA-Z0-9]*$/g
      },
      COMUNE_ESTERO_NASCITA: {
        id: 'comuneEsteroNascita',
        label: 'Comune di nascita*',
        defaultValue: ''
      },
      DATA_NASCITA: {
        id: 'dataNascita',
        label: 'Data di nascita*',
        defaultValue: ''
      }
    },
    [Forms.RESIDENZA]: {
      INDIRIZZO: {
        id: 'indirizzoResidenza',
        label: 'Indirizzo*',
        defaultValue: ''
      },
      NAZIONE: {
        id: 'codNazioneResidenza',
        label: 'Nazione di residenza*',
        defaultValue: 'ITA',
        dominio: 'DOM_NAZIONE_RESIDENZA',
        dominioDep: null,
        italiaId: 'ITA',
        validazione: /^[a-zA-Z0-9]*$/g
      },
      PROVINCIA: {
        id: 'codProvinciaResidenza',
        label: 'Provincia di residenza*',
        defaultValue: '',
        dominio: 'DOM_PROVINCE_RESIDENZA',
        dominioDep: null,
        validazione: /^[a-zA-Z0-9]*$/g
      },
      COMUNE: {
        id: 'codComuneResidenza',
        label: 'Comune di residenza*',
        defaultValue: '',
        dominio: 'DOM_COMUNE_RESIDENZA',
        dominioDep: 'codProvinciaResidenza',
        validazione: /^[a-zA-Z0-9]*$/g
      },
      COMUNE_ESTERO: {
        id: 'comuneEsteroResidenza',
        label: 'Comune di residenza*',
        defaultValue: ''
      },
      CAP: {
        id: 'capResidenza',
        label: 'CAP*',
        defaultValue: '',
        dominio: 'DOM_CAP_RESIDENZA',
        dominioDep: 'codComuneResidenza',
        validazione: /^[a-zA-Z0-9]*$/g
      },
      HA_DOMICILIO: {
        id: 'flagDomicile',
        defaultValue: false,
        label: 'Domicilio diverso dalla residenza'
      },
      INDIRIZZO_DOMICILIO: {
        id: 'addressDomicile',
        label: 'Indirizzo*',
        defaultValue: ''
      },
      NAZIONE_DOMICILIO: {
        id: 'nationalDomicileCode',
        label: 'Nazione di domicilio*',
        defaultValue: 'ITA',
        dominio: 'DOM_NAZIONE_DOMICILIO',
        dominioDep: null,
        italiaId: 'ITA',
        validazione: /^[a-zA-Z0-9]*$/g
      },
      PROVINCIA_DOMICILIO: {
        id: 'provinceDomicileCode',
        label: 'Provincia di domicilio*',
        defaultValue: '',
        dominio: 'DOM_PROVINCE_DOMICILIO',
        dominioDep: null,
        validazione: /^[a-zA-Z0-9]*$/g
      },
      COMUNE_DOMICILIO: {
        id: 'cityDomicileCode',
        label: 'Comune di domicilio*',
        defaultValue: '',
        dominio: 'DOM_COMUNE_DOMICILIO',
        dominioDep: 'provinceDomicileCode',
        validazione: /^[a-zA-Z0-9]*$/g
      },
      COMUNE_ESTERO_DOMICILIO: {
        id: 'placeDomicileCode',
        label: 'Comune di domicilio*',
        defaultValue: ''
      },
      CAP_DOMICILIO: {
        id: 'capDomicile',
        label: 'CAP*',
        defaultValue: '',
        dominio: 'DOM_CAP_DOMICILIO',
        dominioDep: 'cityDomicileCode',
        validazione: /^[a-zA-Z0-9]*$/g
      }
    },
    [Forms.CONTATTI]: {
      PREFISSO: {
        id: 'internationalPrefixCellNumberCode',
        label: 'Prefisso*',
        defaultValue: '+39',
        dominio: 'DOM_PREFISSI',
        dominioDep: null
      },
      CELLULARE: {
        id: 'numeroCellulare',
        label: 'Cellulare*',
        defaultValue: '',
        validazione: /^[0-9]*$/g
      },
      FLAG_CELLULARE: {
        id: 'flagCertificazioneCellulare',
        defaultValue: false,
        validazione: true
      },
      EMAIL: {
        id: 'email',
        label: 'Email*',
        defaultValue: '',
        validazione: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/
      },
      FLAG_EMAIL: {
        id: 'flagCertificazioneEmail',
        defaultValue: false,
        validazione: true
      },
      FLAG_INDIRIZZO: {
        id: 'flagIndirizzoPerInvioComunicazioniPresente',
        defaultValue: false
      },
      NOME: {
        id: 'nomeReferenteInvioComunicazioni',
        label: 'Nome*',
        defaultValue: '',
        validazione: /^[a-zA-ZÀÁÈÉÌÍÒÓÙÚà-ž\s'’-]*$/g,
        daInformazioni: true,
        dataRegistryId: 'nome'
      },
      COGNOME: {
        id: 'cognomeReferenteInvioComunicazioni',
        label: 'Cognome*',
        defaultValue: '',
        validazione: /^[a-zA-ZÀÁÈÉÌÍÒÓÙÚà-ž\s'’-]*$/g,
        daInformazioni: true,
        dataRegistryId: 'cognome'
      },
      INDIRIZZO: {
        id: 'indirizzoInvioComunicazioni',
        label: 'Indirizzo*',
        defaultValue: ''
      },
      // CIVICO: {
      //   id: 'civicoResidenza',
      //   defaultValue: '',
      //   validazione: /^[1-9]{1}[0-9]{0,2}[a-zA-Z]{0,1}$/
      // },
      NAZIONE: {
        id: 'codNazioneInvioComunicazioni',
        label: 'Nazione di residenza*',
        defaultValue: 'ITA',
        dominio: 'DOM_NAZIONE_CONTATTI',
        dominioDep: null,
        italiaId: 'ITA',
        validazione: /^[a-zA-Z0-9]*$/g
      },
      PROVINCIA: {
        id: 'codProvinciaInvioComunicazioni',
        label: 'Provincia di residenza*',
        defaultValue: '',
        dominio: 'DOM_PROVINCE_CONTATTI',
        dominioDep: null,
        validazione: /^[a-zA-Z0-9]*$/g
      },
      COMUNE: {
        id: 'codComuneInvioComunicazioni',
        label: 'Comune di residenza*',
        defaultValue: '',
        dominio: 'DOM_COMUNE_CONTATTI',
        dominioDep: 'codProvinciaInvioComunicazioni',
        validazione: /^[a-zA-Z0-9]*$/g
      },
      COMUNE_ESTERO: {
        id: 'comuneEsteroInvioComunicazioni',
        label: 'Comune di residenza*',
        defaultValue: ''
      },
      CAP: {
        id: 'capInvioComunicazioni',
        label: 'CAP*',
        defaultValue: '',
        dominio: 'DOM_CAP_CONTATTI',
        dominioDep: 'codComuneInvioComunicazioni',
        validazione: /^[a-zA-Z0-9]*$/g
      }
    },
    [Forms.SOGGETTI_CORRELATI]: {
      TABELLA: {
        id: 'tabella',
        label: 'Tabella soggetti gia correlati'
      },
      RELAZIONE: {
        id: 'role',
        label: 'Tipologia di relazione',
        dominio: 'DOM_TIPO_RELAZIONE',
        dominioDep: null,
        defaultValue: 3,
        validazione: /^[a-zA-Z0-9]*$/g
      },
      RICERCA: {
        id: 'searchText',
        label: 'Cerca persona fisica',
        defaultValue: '',
        validazione: /^[a-zA-Z\s]*$/g
      },
      TABELLA_GIURIDICHE: {
        id: 'tabellaGiuridiche',
        label: 'Tabella soggetti gia correlati'
      },
      RELAZIONE_GIURIDICHE: {
        id: 'roleGiuridiche',
        label: 'Tipologia di relazione',
        dominio: 'DOM_TIPO_RELAZIONE',
        dominioDep: null,
        defaultValue: 3,
        validazione: /^[a-zA-Z0-9]*$/g
      },
      RICERCA_GIURIDICHE: {
        id: 'searchTextGiuridiche',
        label: 'Cerca persona giuridica',
        defaultValue: '',
        validazione: /^[a-zA-Z\s]*$/g
      }
    },
    [Forms.SOGGETTI_CORRELATI_GIURIDICHE]: {
      TABELLA: {
        id: 'tabella',
        label: 'Tabella soggetti gia correlati'
      },
      RELAZIONE: {
        id: 'role',
        label: 'Tipologia di relazione',
        dominio: 'DOM_TIPO_RELAZIONE_PG',
        dominioDep: null,
        defaultValue: 3,
        validazione: /^[a-zA-Z0-9]*$/g
      },
      RICERCA: {
        id: 'searchText',
        label: 'Cerca persona fisica',
        defaultValue: '',
        validazione: /^[a-zA-Z\s]*$/g
      },
      RICERCA_PG: {
        id: 'searchTextPg',
        label: 'Cerca persona giuridica',
        defaultValue: '',
        validazione: /^[a-zA-Z\s]*$/g
      },
      TABELLA_GIURIDICHE: {
        id: 'tabellaGiuridiche',
        label: 'Tabella soggetti gia correlati'
      },
      RELAZIONE_GIURIDICHE: {
        id: 'roleGiuridiche',
        label: 'Tipologia di relazione',
        dominio: 'DOM_TIPO_RELAZIONE',
        dominioDep: null,
        defaultValue: 3,
        validazione: /^[a-zA-Z0-9]*$/g
      },
      RICERCA_GIURIDICHE: {
        id: 'searchTextGiuridiche',
        label: 'Cerca persona giuridica',
        defaultValue: '',
        validazione: /^[a-zA-Z\s]*$/g
      }
    },
    [Forms.FATCA]: {
      TIN: {
        id: 'tin',
        label: 'TIN*',
        defaultValue: '',
        validazione: /^[0-9a-zA-Z\s]*$/
      },
      RESIDENZA_USA: {
        id: 'residenteUSA',
        defaultValue: ''
      },
      LISTA: {
        id: 'listaResidenzaFiscale',
        validazione: /^[0-9a-zA-Z\s]*$/,
        defaultValue: [
          {
            codiceNazione: '',
            codiceFiscaleEstero: ''
          }
        ],
        dominio: 'DOM_NAZIONE_RESIDENZA_FISCALE',
        dominioDep: null,
        lista: {
          STATO: {
            id: 'codiceNazione',
            label: 'Stato di residenza fiscale',
            dominio: 'DOM_NAZIONE_RESIDENZA_FISCALE',
            dominioDep: null,
            validazione: /^[a-zA-Z0-9]*$/g
          },
          CODICE_FISCALE: {
            id: 'codiceFiscaleEstero',
            label: 'Codice fiscale*'
          }
        }
      }
    },
    [Forms.FATCA_GIURIDICHE]: {
      CRS_RESFISCDET1_CF: {
        id: 'crs_resFiscDet1_cf',
        defaultValue: '',
        minLength: 16,
        maxLength: 16,
        validazione: /^[a-zA-Z0-9]*$/g
      },
      CRS_RESFISCDET2_CF: {
        id: 'crs_resFiscDet2_cf',
        defaultValue: '',
        minLength: 16,
        maxLength: 16,
        validazione: /^[a-zA-Z0-9]*$/g
      },
      CRS_RESFISCDET3_CF: {
        id: 'crs_resFiscDet3_cf',
        defaultValue: '',
        minLength: 16,
        maxLength: 16,
        validazione: /^[a-zA-Z0-9]*$/g
      },
      ISTITUZIONE_FINANZIARIA_NON_US: {
        id: 'fatca_isIstFinNoUS',
        defaultValue: null,
        itIs: 'true',
        itIsNot: 'false'
      },
      REPORTING_FI: {
        id: 'fatca_istFinDet_catAppartenenzaCode',
        defaultValue: '',
        validazione: /^[0-9a-zA-Z\s]*$/,
        opzione1: 'FI_FFI',
        opzione2: 'FFI',
        opzione3: 'FFI_BO'
      },
      ACTIVE_NFFE: {
        id: 'fatca_istNoFinDet_sceltaCode',
        defaultValue: '',
        itIs: 'ENT_ATT',
        itIsNot: 'ENT_PASS'
      },
      ACTIVE_NFFE_1: {
        id: 'fatca_istNoFinDet_entitaAttivaDet_catAppartenenzaCode',
        defaultValue: '',
        opzione1: 'ACTIVE_ENTITY_14'
      },
      PASSIVE_NFFE_1: {
        id: 'fatca_istNoFinDet_entitaPassivaDet_sceltaCode',
        defaultValue: '',
        itIs: 'PIU_PERS'
      },
      CP1_GROUP: {
        id: 'contrPers1_exist',
        defaultValue: false
      },
      CP2_GROUP: {
        id: 'contrPers2_exist',
        defaultValue: false
      },
      CA_DESCRIZIONE1: {
        id: 'fatca_istFinDet_catAppartenenzaDesc',
        defaultValue: '',
        validazione: /^[a-zA-Z0-9]{6}\.[a-zA-Z0-9]{5}\.[a-zA-Z]{2}\.[0-9]{3}$/
      },
      SPONSORED: {
        id: 'fatca_istNoFinDet_entitaAttivaDet_directReportDescr',
        defaultValue: '',
        validazione: /^[a-zA-Z0-9]{6}\.[a-zA-Z0-9]{5}\.[a-zA-Z]{2}\.[0-9]{3}$/
      },
      CP1_NAME: {
        id: 'contrPers1_nome',
        label: 'Nome',
        defaultValue: '',
        validazione: /^[a-zA-Z ’'àáèéìíòóùúÀÁÈÉÌÍÒÓÙÚ]*$/g
      },
      CP1_SURNAME: {
        id: 'contrPers1_cognome',
        label: 'Cognome',
        defaultValue: '',
        validazione: /^[a-zA-Z ’'àáèéìíòóùúÀÁÈÉÌÍÒÓÙÚ]*$/g
      },
      CP1_DATA_NASCITA: {
        id: 'contrPers1_dataNascita',
        label: 'Data di nascita',
        defaultValue: '',
        validazione: /^[0-9a-zA-Z\s]*$/
      },
      CP1_RESIDENZA_FISCALE_USA: {
        id: 'contrPers1_isResFiscUS',
        defaultValue: null,
        label: 'Residenza fiscale US',
        listaRisposte: [
          {
            idRisposta: true,
            descRisposta: 'SI',
            listaDipendenze: []
          },
          {
            idRisposta: false,
            descRisposta: 'NO',
            listaDipendenze: []
          }
        ]
      },
      CP1_STATO_RESIDENZA_FISCALE_1: {
        id: 'contrPers1_resFiscDet1_sceltaCode',
        label: 'Confermo e attesto di essere residente ai fini fiscali in',
        defaultValue: '',
        validazione: /^[0-9a-zA-Z\s]*$/
      },
      CP1_CF_RESIDENZA_FISCALE_1: {
        id: 'contrPers1_resFiscDet1_cf',
        defaultValue: '',
        minLength: 16,
        maxLength: 16,
        validazione: /^[a-zA-Z0-9]*$/g
      },
      CP1_STATO_RESIDENZA_FISCALE_2: {
        id: 'contrPers1_resFiscDet2_sceltaCode',
        label: 'Confermo e attesto di essere residente ai fini fiscali in',
        defaultValue: '',
        validazione: /^[0-9a-zA-Z\s]*$/
      },

      CP1_CF_RESIDENZA_FISCALE_2: {
        id: 'contrPers1_resFiscDet2_cf',
        minLength: 16,
        maxLength: 16,
        validazione: /^[a-zA-Z0-9]*$/g
      },
      CP1_STATO_RESIDENZA_FISCALE_3: {
        id: 'contrPers1_resFiscDet3_sceltaCode',
        label: 'Confermo e attesto di essere residente ai fini fiscali in',
        defaultValue: '',
        validazione: /^[0-9a-zA-Z\s]*$/
      },
      CP1_CF_RESIDENZA_FISCALE_3: {
        id: 'contrPers1_resFiscDet3_cf',
        minLength: 16,
        maxLength: 16,
        validazione: /^[a-zA-Z0-9]*$/g
      },
      CP1_TYPE_1: {
        id: 'contrPers1_tipoPersona_tipoCode'
      },
      CP2_NAME: {
        id: 'contrPers2_nome',
        label: 'Nome',
        defaultValue: '',
        validazione: /^[a-zA-Z ’'àáèéìíòóùúÀÁÈÉÌÍÒÓÙÚ]*$/g
      },
      CP2_SURNAME: {
        id: 'contrPers2_cognome',
        label: 'Cognome',
        defaultValue: '',
        validazione: /^[a-zA-Z ’'àáèéìíòóùúÀÁÈÉÌÍÒÓÙÚ]*$/g
      },
      CP2_DATA_NASCITA: {
        id: 'contrPers2_dataNascita',
        label: 'Data di nascita',
        defaultValue: '',
        validazione: /^[0-9a-zA-Z\s]*$/
      },
      CP2_RESIDENZA_FISCALE_USA: {
        id: 'contrPers2_isResFiscUS',
        defaultValue: null,
        label: 'Residenza fiscale US',
        listaRisposte: [
          {
            idRisposta: true,
            descRisposta: 'SI',
            listaDipendenze: []
          },
          {
            idRisposta: false,
            descRisposta: 'NO',
            listaDipendenze: []
          }
        ]
      },
      CP2_STATO_RESIDENZA_FISCALE_1: {
        id: 'contrPers2_resFiscDet1_sceltaCode',
        label: 'Confermo e attesto di essere residente ai fini fiscali in',
        defaultValue: '',
        validazione: /^[0-9a-zA-Z\s]*$/
      },
      CP2_CF_RESIDENZA_FISCALE_1: {
        id: 'contrPers2_resFiscDet1_cf',
        minLength: 16,
        maxLength: 16,
        validazione: /^[a-zA-Z0-9]*$/g
      },
      CP2_STATO_RESIDENZA_FISCALE_2: {
        id: 'contrPers2_resFiscDet2_sceltaCode',
        label: 'Confermo e attesto di essere residente ai fini fiscali in',
        defaultValue: '',
        validazione: /^[0-9a-zA-Z\s]*$/
      },
      CP2_CF_RESIDENZA_FISCALE_2: {
        id: 'contrPers2_resFiscDet2_cf',
        minLength: 16,
        maxLength: 16,
        validazione: /^[a-zA-Z0-9]*$/g
      },
      CP2_STATO_RESIDENZA_FISCALE_3: {
        id: 'contrPers2_resFiscDet3_sceltaCode',
        label: 'Confermo e attesto di essere residente ai fini fiscali in',
        defaultValue: '',
        validazione: /^[0-9a-zA-Z\s]*$/
      },
      CP2_CF_RESIDENZA_FISCALE_3: {
        id: 'contrPers2_resFiscDet3_cf',
        minLength: 16,
        maxLength: 16,
        validazione: /^[a-zA-Z0-9]*$/g
      },
      CP2_TYPE_1: {
        id: 'contrPers2_tipoPersona_tipoCode'
      },
      CP1_RF1_NAZIONE: {
        id: 'contrPers1_resFiscDet1_nazione',
        defaultValue: '',
        dominio: 'DOM_NAZIONE_RESIDENZA_FISCALE',
        dominioDep: null,
        validazione: /^[a-zA-Z0-9]*$/g
      },
      CP1_RF1_TIN: {
        id: 'contrPers1_resFiscDet1_tin',
        defaultValue: '',
        label: 'TIN'
      },
      CP1_RF2_TIN: {
        id: 'contrPers1_resFiscDet2_tin',
        defaultValue: '',
        label: 'TIN'
      },
      CP1_RF3_TIN: {
        id: 'contrPers1_resFiscDet3_tin',
        defaultValue: '',
        label: 'TIN'
      },
      CP2_RF1_TIN: {
        id: 'contrPers2_resFiscDet1_tin',
        defaultValue: '',
        label: 'TIN'
      },
      CP2_RF2_TIN: {
        id: 'contrPers2_resFiscDet2_tin',
        defaultValue: '',
        label: 'TIN'
      },
      CP2_RF3_TIN: {
        id: 'contrPers2_resFiscDet3_tin',
        defaultValue: '',
        label: 'TIN'
      },
      CP1_RF2_NAZIONE: {
        defaultValue: '',
        id: 'contrPers1_resFiscDet2_nazione',
        dominio: 'DOM_NAZIONE_RESIDENZA_FISCALE',
        dominioDep: null,
        validazione: /^[a-zA-Z0-9]*$/g
      },
      CP1_RF3_NAZIONE: {
        defaultValue: '',
        id: 'contrPers1_resFiscDet3_nazione',
        dominio: 'DOM_NAZIONE_RESIDENZA_FISCALE',
        dominioDep: null,
        validazione: /^[a-zA-Z0-9]*$/g
      },
      CP2_RF1_NAZIONE: {
        defaultValue: '',
        id: 'contrPers2_resFiscDet1_nazione',
        dominio: 'DOM_NAZIONE_RESIDENZA_FISCALE',
        dominioDep: null,
        validazione: /^[a-zA-Z0-9]*$/g
      },
      CP2_RF2_NAZIONE: {
        defaultValue: '',
        id: 'contrPers2_resFiscDet2_nazione',
        dominio: 'DOM_NAZIONE_RESIDENZA_FISCALE',
        dominioDep: null,
        validazione: /^[a-zA-Z0-9]*$/g
      },
      CP2_RF3_NAZIONE: {
        defaultValue: '',
        id: 'contrPers2_resFiscDet3_nazione',
        dominio: 'DOM_NAZIONE_RESIDENZA_FISCALE',
        dominioDep: null,
        validazione: /^[a-zA-Z0-9]*$/g
      },
      ISTITUZIONE_FINANZIARIA: {
        id: 'istituzioneFinanziaria',
        defaultValue: ''
      },
      ENTITA_INVESTIMENTO_1: {
        id: 'entitaInvestimento',
        defaultValue: ''
      },
      ENTITA_NON_FINANZIARIA_ACTIVE_1: {
        id: 'entitaFinanziariaActive',
        defaultValue: ''
      },
      ENTITA_NON_FINANZIARIA_PASSIVE_1: {
        id: 'entitaFinanziariaPassive',
        defaultValue: ''
      },

      CP1_NAZIONE: {
        id: 'contrPers1_nazioneNascita',
        label: 'Nazione di nascita*',
        defaultValue: '',
        dominio: 'DOM_NAZIONE_RESIDENZA',
        dominioDep: null,
        italiaId: 'ITA',
        statiUnitiId: 'USA',
        validazione: /^[a-zA-Z0-9]*$/g
      },
      CP1_PROVINCIA: {
        id: 'contrPers1_provinciaNascita',
        label: 'Provincia*',
        defaultValue: '',
        dominio: 'DOM_PROVINCE_RESIDENZA',
        dominioDep: 'codiceNazioneNascita',
        validazione: /^[a-zA-Z0-9]*$/g
      },
      CP1_CAP: {
        id: 'cap1',
        label: 'CAP*',
        defaultValue: '',
        dominio: 'DOM_CAP_RESIDENZA',
        dominioDep: 'codiceComuneResidenza',
        validazione: /^[a-zA-Z0-9]*$/g
      },
      CP1_COMUNE: {
        id: 'contrPers1_luogoNascita',
        label: 'Città di nascita*',
        defaultValue: '',
        dominio: 'DOM_COMUNE_NASCITA',
        dominioDep: 'contrPers1_provinciaNascita',
        validazione: /^[a-zA-Z0-9]*$/g
      },
      CP1_COMUNE_ESTERO: {
        id: 'contrPers1_placeNascita',
        label: 'Città di nascita*',
        defaultValue: ''
      },
      CP1_CITTADINANZA: {
        id: 'contrPers1_cittadinanza',
        label: 'Cittadinanza*',
        defalultValue: '',
        // dominio: ''
        dominio: 'DOM_CITTADINANZE'
      },
      CP1_NAZIONE_RESIDENZA: {
        id: 'contrPers1_nazioneResidenza',
        label: 'Nazione di residenza*',
        defaultValue: '',
        dominio: 'DOM_NAZIONE_RESIDENZA',
        dominioDep: null,
        italiaId: 'ITA',
        statiUnitiId: 'USA',
        validazione: /^[a-zA-Z0-9]*$/g
      },
      CP2_NAZIONE_RESIDENZA: {
        id: 'contrPers2_nazioneResidenza',
        label: 'Nazione di residenza*',
        defaultValue: '',
        dominio: 'DOM_NAZIONE_RESIDENZA',
        dominioDep: null,
        italiaId: 'ITA',
        statiUnitiId: 'USA',
        validazione: /^[a-zA-Z0-9]*$/g
      },
      CP2_CITTADINANZA: {
        id: 'contrPers2_cittadinanza',
        label: 'Cittadinanza*',
        defalultValue: '',
        // dominio: ''
        dominio: 'DOM_CITTADINANZE'
      },
      CP2_NAZIONE: {
        id: 'contrPers2_nazioneNascita',
        label: 'Nazione di nascita*',
        defaultValue: '',
        dominio: 'DOM_NAZIONE_RESIDENZA',
        dominioDep: null,
        italiaId: 'ITA',
        statiUnitiId: 'USA',
        validazione: /^[a-zA-Z0-9]*$/g
      },
      CP2_PROVINCIA: {
        id: 'contrPers2_provinciaNascita',
        label: 'Provincia*',
        defaultValue: '',
        dominio: 'DOM_PROVINCE_RESIDENZA',
        dominioDep: 'codiceNazioneNascita',
        validazione: /^[a-zA-Z0-9]*$/g
      },
      CP2_CAP: {
        id: 'cap2',
        label: 'CAP*',
        defaultValue: '',
        dominio: 'DOM_CAP_RESIDENZA',
        dominioDep: 'codiceComuneResidenza',
        validazione: /^[a-zA-Z0-9]*$/g
      },
      CP2_COMUNE: {
        id: 'contrPers2_luogoNascita',
        label: 'Città di nascita*',
        defaultValue: '',
        dominio: 'DOM_COMUNE_RESIDENZA',
        dominioDep: 'contrPers2_provinciaNascita',
        validazione: /^[a-zA-Z0-9]*$/g
      },
      CP2_COMUNE_ESTERO: {
        id: 'contrPers2_placeNascita',
        label: 'Città di nascita*',
        defaultValue: ''
      },
      CRS_ISTITUZIONE_FINANZIARIA: {
        id: 'crs_defPos_sceltaCode',
        defaultValue: '',
        itIs: 'IST_FIN',
        itIsNot: 'ENT_NO_FIN'
      },
      CRS_ENTITA: {
        id: 'crs_defPos_istFinCrsDet_sceltaCode',
        defaultValue: '',
        itIs: 'INV_GIURIS'
      },
      CRS_ENTITA_PERSONE: {
        id: 'crs_defPos_istFinCrsDet_isSenzaUnaPiuContrPers',
        defaultValue: null
      },
      CRS_ACTIVE: {
        id: 'crs_defPos_entitaNoFinDet_sceltaCode',
        defaultValue: '',
        itIs: 'ACTIVE_NFE',
        itIsNot: 'PASSIVE_NFE'
      },
      CRS_ACTIVE_TYPE: {
        id: 'crs_defPos_entitaNoFinDet_activeNFE_sceltaCode',
        defaultValue: ''
      },
      CRS_ACTIVE_SOCIETA_QUOTATA: {
        id: 'crs_defPos_entitaNoFinDet_activeNFE_SocQuotata',
        label: 'Mercato di quotazione',
        defaultValue: ''
      },
      CRS_ACTIVE_SOCIETA_COLLEGATA: {
        id: 'crs_defPos_entitaNoFinDet_activeNFE_SocCollegata',
        label: 'Nome società e mercato di quotazione',
        defaultValue: ''
      },
      CRS_ACTIVE_SOCIETA_ALTRO: {
        id: 'crs_defPos_entitaNoFinDet_activeNFE_AltraTipologia',
        label: 'Tipologia',
        defaultValue: ''
      },
      CRS_PASSIVE_TYPE: {
        id: 'crs_defPos_entitaNoFinDet_passiveNFE_isSenzaUnaPiuContrPers',
        defaultValue: null
      },
      CRS_STATO_RESIDENZA_FISCALE_1: {
        id: 'crs_resFiscDet1_sceltaCode',
        label: 'Confermo e attesto di essere residente ai fini fiscali in',
        defaultValue: '',
        validazione: /^[0-9a-zA-Z\s]*$/
      },
      CRS_STATO_RESIDENZA_FISCALE_2: {
        id: 'crs_resFiscDet2_sceltaCode',
        label: 'Confermo e attesto di essere residente ai fini fiscali in',
        defaultValue: '',
        validazione: /^[0-9a-zA-Z\s]*$/
      },
      CRS_STATO_RESIDENZA_FISCALE_3: {
        id: 'crs_resFiscDet3_sceltaCode',
        label: 'Confermo e attesto di essere residente ai fini fiscali in',
        defaultValue: '',
        validazione: /^[0-9a-zA-Z\s]*$/
      },
      // nazioni crs, che poi mando in post!
      CRS_RESFISCALEDET1_NAZIONE: {
        id: 'crs_resFiscDet1_nazione',
        dominio: 'DOM_NAZIONE_RESIDENZA_FISCALE'
      },
      CRS_RESFISCALEDET2_NAZIONE: {
        id: 'crs_resFiscDet2_nazione',
        dominio: 'DOM_NAZIONE_RESIDENZA_FISCALE'
      },
      CRS_RESFISCALEDET3_NAZIONE: {
        id: 'crs_resFiscDet3_nazione',
        dominio: 'DOM_NAZIONE_RESIDENZA_FISCALE'
      }
    },
    [Forms.ANTIRICICLAGGIO_GIURIDICHE]: {
      ATTIVITA_EXTRA_EU: {
        id: 'attivitaExtraEu',
        templateId: 93,
        defaultValue: '',
        propRisposta: 'idRisposta'
      },
      QUALI_ATTIVITA_EXTRA_EU: {
        id: 'qualiAttivitaExtraEu',
        dominio: 'DOM_NAZIONE_EXTRA_EU',
        // dominio: 'DOM_NAZIONE_RESIDENZA',
        dominioDep: null,
        templateId: 94,
        defaultValue: '',
        propRisposta: 'descRisposta'
      },
      NATURA_RAPPORTO: {
        id: 'naturaRapporto',
        templateId: 95,
        defaultValue: '',
        propRisposta: 'descRisposta',
        dominio: 'DOM_NATURA_RAPPORTO',
        dominioDep: null,
        altroId: '5'
      },

      NATURA_RAPPORTO_ALTRO: {
        id: 'naturaRapportoAltro',
        templateId: 96,
        defaultValue: '',
        propRisposta: 'descRisposta'
      },
      TITOLARI_EFFETTIVI: {
        id: 'titolariEffettivi',
        templateId: 97,
        defaultValue: '',
        propRisposta: 'idRisposta'
      },
      SETTORE_OCCUPAZIONE: {
        id: 'settoreOccupazione',
        templateId: 98,
        defaultValue: '',
        propRisposta: 'descRisposta',
        dominio: 'DOM_SETTORE_OCCUPAZIONE_I_AML',
        dominioDep: null,
        altroId: '07'
      },
      SETTORE_OCCUPAZIONE_ALTRO: {
        id: 'settoreOccupazioneAltro',
        templateId: 99,
        defaultValue: '',
        propRisposta: 'descRisposta',
        dominio: 'DOM_SETTORE_OCCUPAZIONE_II',
        dominioDep: null
      },
      OPERARE_SETTORI: {
        id: 'operareSettori',
        templateId: 100,
        defaultValue: '',
        propRisposta: 'idRisposta'
      },
      OPERARE_AFFARI: {
        id: 'operareAffari',
        templateId: 101,
        defaultValue: '',
        propRisposta: 'idRisposta'
      },
      ORGANIZZAZIONE_NO_PROFIT: {
        id: 'organizzazioneNoprofit',
        templateId: 102,
        defaultValue: '',
        propRisposta: 'idRisposta',
        validazione: /^[a-zA-Z0-9 ,’'àáèéìíòóùúÀÁÈÉÌÍÒÓÙÚ]*$/g
      },
      CLASSE_BENEFICIARI: {
        id: 'classeBeneficiari',
        templateId: 103,
        defaultValue: '',
        propRisposta: 'descRisposta',
        validazione: /^[a-zA-Z0-9 ,’'àáèéìíòóùúÀÁÈÉÌÍÒÓÙÚ]*$/g
      },
      FONTE_PROVENIENZA: {
        id: 'fonteProvenienza',
        templateId: 104,
        defaultValue: '',
        propRisposta: 'descRisposta',
        validazione: /^[a-zA-Z0-9 ,’'àáèéìíòóùúÀÁÈÉÌÍÒÓÙÚ]*$/g
      }
    },
    [Forms.CONSENSI_PRIVACY]: {
      ATTIVITA_MARKETING: {
        id: 'attivitaMarketing',
        templateId: 68,
        defaultValue: '',
        propRisposta: 'idRisposta',
        value: ''
      },
      ATTIVITA_ALTRO: {
        id: 'attivitaAltro',
        templateId: 69,
        defaultValue: '',
        propRisposta: 'idRisposta',
        value: ''
      }
    },
    [Forms.CONSENSI_PRIVACY_GIURIDICHE]: {
      ATTIVITA_MARKETING: {
        id: 'attivitaMarketing',
        templateId: 258,
        defaultValue: '',
        propRisposta: 'idRisposta',
        value: ''
      },
      ATTIVITA_ALTRO: {
        id: 'attivitaAltro',
        templateId: 259,
        defaultValue: '',
        propRisposta: 'idRisposta',
        value: ''
      }
    },
    [Forms.ANTIRICICLAGGIO]: {
      TITOLO_STUDIO: {
        id: 'titoloStudio',
        templateId: 44,
        defaultValue: '',
        propRisposta: 'descRisposta',
        dominio: 'DOM_TITOLO_STUDIO',
        dominioDep: null
      },
      NATURA_RAPPORTO: {
        id: 'naturaRapporto',
        templateId: 45,
        defaultValue: '',
        propRisposta: 'descRisposta',
        dominio: 'DOM_NATURA_RAPPORTO',
        dominioDep: null,
        altroId: '5'
      },
      NATURA_RAPPORTO_ALTRO: {
        id: 'naturaRapportoAltro',
        templateId: 46,
        defaultValue: '',
        propRisposta: 'descRisposta'
      },
      SETTORE_OCCUPAZIONE: {
        id: 'settoreOccupazione',
        templateId: 47,
        defaultValue: '',
        propRisposta: 'descRisposta',
        dominio: 'DOM_SETTORE_OCCUPAZIONE_I_AML',
        dominioDep: null,
        altroId: '07'
      },
      SETTORE_OCCUPAZIONE_ALTRO: {
        id: 'settoreOccupazioneAltro',
        templateId: 48,
        defaultValue: '',
        propRisposta: 'descRisposta',
        dominio: 'DOM_SETTORE_OCCUPAZIONE_II',
        dominioDep: null
      },
      PROFESSIONE_ATTUALE: {
        id: 'professioneAttuale',
        templateId: 49,
        defaultValue: '',
        propRisposta: 'descRisposta',
        dominio: 'DOM_PROFESSIONE_ATTUALE_AML',
        dominioDep: null,
        casalingaId: '002',
        pensionatoId: '003',
        disoccupStudId: '110',
        liberoProfId: '145'
      },
      PROFESSIONE_PRECEDENTE: {
        id: 'professionePrecedente',
        templateId: 50,
        defaultValue: '',
        propRisposta: 'descRisposta'
      },
      NAZIONE_INSEDIAMENTO: {
        id: 'nazioneInsediamento',
        templateId: 51,
        defaultValue: '',
        propRisposta: 'descRisposta',
        dominio: 'DOM_NAZIONE_PROFESSIONE',
        dominioDep: null,
        italiaId: 'ITA'
      },
      PROVINCIA_ATTIVITA: {
        id: 'provinciaAttivita',
        templateId: 52,
        defaultValue: '',
        propRisposta: 'descRisposta',
        dominio: 'DOM_PROVINCE_PROFESSIONE',
        dominioDep: null
      },
      ANNO_INIZIO: {
        id: 'annoInizio',
        templateId: 53,
        defaultValue: '',
        propRisposta: 'descRisposta'
      },
      RADIO_PEP: {
        id: 'radioPep',
        templateId: 54,
        defaultValue: '',
        propRisposta: 'idRisposta',
        resetId: 62,
        rapportiPEPId: 65
      },
      CARICA_RICOPERTA: {
        id: 'caricaRicoperta',
        templateId: 55,
        defaultValue: '',
        propRisposta: 'descRisposta'
      },
      INIZIO_CARICA: {
        id: 'inizioCarica',
        templateId: 56,
        defaultValue: '',
        propRisposta: 'descRisposta'
      },
      FONTE_REDDITO: {
        id: 'fonteReddito',
        templateId: 57,
        defaultValue: '',
        propRisposta: 'descRisposta'
      },
      RADIO_PEP_PERCHE: {
        id: 'RadioPepPerche',
        templateId: 58,
        defaultValue: '',
        propRisposta: 'idRisposta'
      },
      RADIO_PEP_REDDITO: {
        id: 'radioPepReddito',
        templateId: 59,
        defaultValue: '',
        propRisposta: 'idRisposta'
      },
      YES_NO_PEP_PARTECIPAZIONI_SOC: {
        id: 'yesNoPepPartecipazioniSoc',
        templateId: 60,
        defaultValue: '',
        propRisposta: 'idRisposta'
      },
      YES_NO_PEP_PROCEDIMENTI: {
        id: 'yesNoPepProcedimenti',
        templateId: 61,
        defaultValue: '',
        propRisposta: 'idRisposta'
      },
      RADIO_PEP_RAPPORTI_PERCHE: {
        id: 'radioPepRapportiPerche',
        templateId: 62,
        defaultValue: '',
        propRisposta: 'idRisposta'
      },
      YES_NO_CARICHE_POLITICHE: {
        id: 'yesNoCarichePolitiche',
        templateId: 63,
        defaultValue: '',
        propRisposta: 'idRisposta'
      },
      YES_NO_CARICHE_EXTRA: {
        id: 'yesNoCaricheExtra',
        templateId: 64,
        defaultValue: '',
        propRisposta: 'idRisposta'
      },
      YES_NO_CARICHE_PUBBLICHE: {
        id: 'yesNoCarichePubbliche',
        templateId: 65,
        defaultValue: '',
        propRisposta: 'idRisposta'
      },
      YES_NO_FONDI_PUBBLICI: {
        id: 'yesNoFondiPubblici',
        templateId: 66,
        defaultValue: '',
        propRisposta: 'idRisposta'
      },
      PRINCIPALE_FONTE_REDDITO: {
        id: 'field_272',
        templateId: 272,
        // defaultValue: '',
        propRisposta: 'idRisposta',
        isCheckbox: true
      },
      PRINCIPALE_FONTE_REDDITO1: {
        id: 'field_272_292',
        templateId: 272,
        defaultValue: false,
        propRisposta: 'idRisposta',
        isCheckbox: true
      },
      PRINCIPALE_FONTE_REDDITO2: {
        id: 'field_272_293',
        templateId: 272,
        defaultValue: false,
        propRisposta: 'idRisposta',
        isCheckbox: true
      },
      PRINCIPALE_FONTE_REDDITO3: {
        id: 'field_272_294',
        templateId: 272,
        defaultValue: false,
        propRisposta: 'idRisposta',
        isCheckbox: true
      },
      PRINCIPALE_FONTE_REDDITOS4: {
        id: 'field_272_295',
        templateId: 272,
        defaultValue: false,
        propRisposta: 'idRisposta',
        isCheckbox: true
      },
      PRINCIPALE_FONTE_REDDITO5: {
        id: 'field_272_296',
        templateId: 272,
        defaultValue: false,
        propRisposta: 'idRisposta',
        isCheckbox: true
      },
      FONTE_REDDITO_MEDIO: {
        id: 'field_273',
        // id: 'fonteRedditoMedio',
        templateId: 273,
        defaultValue: '',
        propRisposta: 'idRisposta'
      },
      REDDITO_FUTURO: {
        id: 'field_274',
        templateId: 274,
        defaultValue: '',
        propRisposta: 'idRisposta'
      },
      PATRIMONIO_FINANZIARIO: {
        id: 'field_275',
        templateId: 275,
        defaultValue: '',
        propRisposta: 'idRisposta'
      },
      INVESTIMENTI_IMMOBILIARI: {
        id: 'field_276',
        templateId: 276,
        defaultValue: '',
        propRisposta: 'idRisposta'
      },
      INVESTIMENTI_IMMOBILIARI_PRIMA_CASA: {
        id: 'field_277',
        templateId: 277,
        defaultValue: '',
        propRisposta: 'idRisposta'
      },
      INVESTIMENTI_IMMOBILIARI_ALTRI_IMMOBILI: {
        id: 'field_278',
        templateId: 278,
        defaultValue: '',
        propRisposta: 'idRisposta'
      },
      PATRIMONIO_IMMOBILIARE: {
        id: 'field_279',
        templateId: 279,
        defaultValue: '',
        propRisposta: 'idRisposta'
      },
      PATRIMONIO_FINANZIARIO_COMPLESSIVO: {
        id: 'field_280',
        templateId: 280,
        defaultValue: '',
        propRisposta: 'idRisposta'
      },
      IMPEGNI_FINANZIARI_MENSILI: {
        id: 'field_281',
        templateId: 281,
        defaultValue: '',
        propRisposta: 'idRisposta'
      },
      RISPARMIO_MENSILE: {
        id: 'field_282',
        templateId: 282,
        defaultValue: '',
        propRisposta: 'idRisposta'
      },
      PERSONE_DIPENDENTI: {
        id: 'field_283',
        templateId: 283,
        defaultValue: '',
        propRisposta: 'idRisposta'
      }
    }
  },

  {
    // VECCHIA MIFID
    [Forms.MIFID_CONOSCENZA]: {
      TITOLO_STUDIO: {
        id: 'titoloStudio',
        templateId: 5,
        dominio: 'DOM_TITOLO_STUDIO_MIFID',
        dominioDep: null,
        defaultValue: ''
      },
      SETTORE_OCCUPAZIONE: {
        id: 'settoreOccupazione',
        templateId: 6,
        dominio: 'DOM_SETTORE_OCCUPAZIONE_MIFID',
        dominioDep: null,
        defaultValue: ''
      },
      SETTORE_PROFESSIONE: {
        id: 'settoreProfessione',
        templateId: 7,
        dominio: 'DOM_PROFESSIONE_MIFID',
        dominioDep: null,
        defaultValue: ''
      },
      ALTRI_CONTRATTI_CONSULENZA: {
        id: 'altriContrattiConsulenza',
        templateId: 8,
        defaultValue: ''
      },
      ALTRI_CONTRATTI_COLLOCAMENTO: {
        id: 'altriContrattiCollocamento',
        templateId: 9,
        defaultValue: ''
      },
      STRUMENTI: {
        id: 'stumenti',
        templateId: 10,
        fromTemplate: true,
        desinenze: ['_conoscenza', '_operazioni', '_check']
      },
      VERO_FALSO: {
        id: 'veroFalso',
        templateId: 21,
        fromTemplate: true
      }
    },
    [Forms.MIFID_OBIETTIVI]: {
      OBIETTIVI: {
        id: 'obiettivi',
        templateId: 32
      },
      RISCHIO: {
        id: 'rischio',
        templateId: 33
      },
      ORIZZONTE_TEMPORALE: {
        id: 'orizzonteTemporale',
        templateId: 34
      }
    },
    [Forms.MIFID_OBIETTIVI_LIGHT]: {
      OBIETTIVI: {
        id: 'obiettivi',
        templateId: 90
      },
      RISCHIO: {
        id: 'rischio',
        templateId: 91
      },
      ORIZZONTE_TEMPORALE: {
        id: 'orizzonteTemporale',
        templateId: 92
      }
    },
    [Forms.MIFID_ALTRO_LIGHT]: {
      RICCHEZZA_FINANZIARIA: {
        id: 'ricchezzaFinanziaria',
        templateId: 87,
        defaultValue: ''
      },
      INVESTIMENTO_STRUMENTI_AZIONARI: {
        id: 'investimentoStrumentiAzionari',
        templateId: 88,
        defaultValue: ''
      },
      RICCHEZZA_NON_INVESTITA: {
        id: 'ricchezzaNonInvestita',
        templateId: 89,
        innerRisposte: true
      }
    },
    [Forms.MIFID_FINANZIARIA]: {
      FONTE_REDDITO: {
        id: 'fonteReddito',
        templateId: 35,
        altroId: '20',
        professioneId: '18',
        defaultValue: ''
      },
      FONTE_REDDITO_ALTRO: {
        id: 'fonteRedditoAltro',
        templateId: 36,
        defaultValue: ''
      },
      REDDITO_FUTURO: {
        id: 'redditoFuturo',
        templateId: 37,
        defaultValue: ''
      },
      PATRIMONIO_FINANZIARIO: {
        id: 'patrimonioFinanziario',
        templateId: 38,
        defaultValue: ''
      },
      INVESTIMENTI_IMMOBILIARI: {
        id: 'investimentiImmobiliari',
        templateId: 39,
        isCheckbox: true
      },
      IMPEGNI_FINANZIARI_MENSILI: {
        id: 'impegniFinanziariMensili',
        templateId: 40,
        defaultValue: ''
      }
    },
    [Forms.MIFID_ALTRO]: {
      RICCHEZZA_FINANZIARIA: {
        id: 'ricchezzaFinanziaria',
        templateId: 41,
        defaultValue: ''
      },
      INVESTIMENTO_STRUMENTI_AZIONARI: {
        id: 'investimentoStrumentiAzionari',
        templateId: 42,
        defaultValue: ''
      },
      RICCHEZZA_NON_INVESTITA: {
        id: 'ricchezzaNonInvestita',
        templateId: 43,
        innerRisposte: true
      }
    },
    // NUOVA MIFID PF
    [Forms.MIFID_ANAGRAFICA_PF]: {
      STATO_CIVILE: {
        id: 'statoCivile',
        templateId: 123,
        defaultValue: ''
      },
      NUCLEO_FAMILIARE: {
        id: 'nucleoFamiliare',
        templateId: 124,
        defaultValue: ''
      }
    },
    [Forms.MIFID_CONOSCENZA_PF]: {
      TITOLO_STUDIO: {
        id: 'titoloStudio',
        templateId: 125,
        dominio: 'DOM_TITOLO_STUDIO_MIFID',
        dominioDep: null,
        defaultValue: '',
        descRisposta: true
      },
      SETTORE_OCCUPAZIONE: {
        id: 'settoreOccupazione',
        templateId: 126,
        dominio: 'DOM_SETTORE_OCCUPAZIONE_MIFID',
        dominioDep: null,
        defaultValue: ''
      },
      SETTORE_PROFESSIONE: {
        id: 'settoreProfessione',
        templateId: 127,
        dominio: 'DOM_PROFESSIONE_ATTUALE_MIFID',
        dominioDep: null,
        defaultValue: '',
        descRisposta: true
      },
      ALTRI_CONTRATTI_CONSULENZA: {
        id: 'altriContrattiConsulenza',
        templateId: 128,
        defaultValue: ''
      },
      ALTRI_CONTRATTI_COMMERCIALIZZAZIONE: {
        id: 'altriContrattiCommercializzazione',
        templateId: 129,
        defaultValue: ''
      },
      RISCHIO_RENDIMENTO: {
        id: 'rischioRendimento',
        templateId: 130
      },
      DIVERSIFICAZIONE_RISCHIO: {
        id: 'diversificazioneRischio',
        templateId: 131
      },
      ORIZZONTE_TEMPORALE: {
        id: 'orizzonteTemporale',
        templateId: 132
      },
      VERO_FALSO: {
        id: 'veroFalso',
        templateId: 133,
        fromTemplate: true
      },
      SI_NO: {
        id: 'siNo',
        templateId: 143,
        fromTemplate: true
      },
      INVESTIMENTO: {
        id: 'investimento',
        templateId: 150
      }
    },
    [Forms.MIFID_FINANZIARIA_PF]: {
      FONTE_REDDITO: {
        id: 'fonteReddito',
        templateId: 151,
        defaultValue: '',
        isCheckbox: true
      },
      FONTE_REDDITO_MEDIO: {
        id: 'fonteRedditoMedio',
        templateId: 152,
        defaultValue: ''
      },
      REDDITO_FUTURO: {
        id: 'redditoFuturo',
        templateId: 153,
        defaultValue: ''
      },
      PATRIMONIO_FINANZIARIO: {
        id: 'patrimonioFinanziario',
        templateId: 154,
        defaultValue: ''
      },
      INVESTIMENTI_IMMOBILIARI: {
        id: 'investimentiImmobiliari',
        templateId: 155,
        defaultValue: ''
      },
      PATRIMONIO_IMMOBILIARE: {
        id: 'patrimonioImmobiliare',
        templateId: 158,
        defaultValue: ''
      },
      PATRIMONIO_FINANZIARIO_COMPLESSIVO: {
        id: 'patrimonioFinanziarioComplessivo',
        templateId: 159,
        defaultValue: ''
      },
      IMPEGNI_FINANZIARI_MENSILI: {
        id: 'impegniFinanziariMensili',
        templateId: 160,
        defaultValue: ''
      },
      RISPARMIO_MENSILE: {
        id: 'risparmioMensile',
        templateId: 161,
        defaultValue: ''
      },
      PERSONE_DIPENDENTI: {
        id: 'personeDipendenti',
        templateId: 162,
        defaultValue: ''
      }
    },
    [Forms.MIFID_TOLLERANZA_PF]: {
      PERDITA: {
        id: 'perdita',
        templateId: 163
      },
      RISCHIO: {
        id: 'rischio',
        templateId: 164
      }
    },
    [Forms.MIFID_OBIETTIVI_PF]: {
      OBIETTIVI_INVESTIMENTI: {
        id: 'obiettiviInvestimenti',
        templateId: 165
      },
      ESIGENZE_LIQUIDITA: {
        id: 'esigenzeLiquidita',
        templateId: 166
      },

      // volatilità e prodotti alternativi non sono presenti nelle modifiche mifid

      /* VOLATILITA: {
        id: 'volatilita',
        templateId: 167
      },
      PRODOTTI_ALTERNATIVI: {
        id: 'prodottiAlternativi',
        templateId: 168
      }, */
      // aggiunta di 3 nuove domande
      LIMITAZIONI_DISINVESTIMENTO: {
        id: 'limitazioniDisinvestimento',
        templateId: 255
      },
      PERCENTUALE_DISINVESTIMENTO: {
        id: 'percentualeDisinvestimento',
        templateId: 167
      },
      ESTENSIONI_SUCCESSORIE: {
        id: 'estensioniSuccessorie',
        templateId: 168
      },
      OBBLIGAZIONI_PERPETUE: {
        id: 'obbligazioniPerpetue',
        templateId: 169
      },
      // fine aggiunta 4 nuove domande
      ESIGENZE_ASSICURATIVE: {
        id: 'esigenzeAssicurative',
        templateId: 170
      },
      PREFERENZE_INVESTIMENTI: {
        id: 'preferenzeInvestimenti',
        templateId: 172
      }
    },
    [Forms.MIFID_INVESTIMENTI_ESG_PF]: {
      CONOSCENZA_ESPERIENZA: {
        id: 'conoscenza_esperienza',
        templateId: 260
      },
      QUOTA_PORTAFOGLIO: {
        id: 'quota_portafoglio',
        templateId: 284
      },
      QUOTA_PORTAFOGLIO2: {
        id: 'quota_portafoglio2',
        templateId: 285
      },
      INTERESSE: {
        id: 'interesse',
        templateId: 266
      },
      INTERESSE2: {
        id: 'interesse',
        templateId: 267
      },
      IMPATTI_AMBIENTE: {
        id: 'impatti_ambiente',
        templateId: 290
      },
      IMPATTI_SOCIALI_GOV: {
        id: 'impatti_sociali_gov',
        templateId: 293
      },
      STRUMENTI_SOCIALI_GOV: {
        id: 'strumenti_sociali_gov',
        templateId: 296
      },
      STRUMENTI_AMBIENTE: {
        id: 'strumenti_ambiente',
        templateId: 299
      }
    },
    [Forms.MIFID_EDUCAZIONE_PF]: {
      MERCATI_FINANZIARI: {
        id: 'mercatiFinanziari',
        templateId: 173
      },
      AGGIORNAMENTO_MERCATI: {
        id: 'aggiornamentoMercati',
        templateId: 174
      },
      INTERESSE_INVESTIMENTI: {
        id: 'interesseInvestimenti',
        templateId: 175
      },
      PENSIONAMENTO: {
        id: 'pensionamento',
        templateId: 176
      },
      CRISI_BANCARIE: {
        id: 'crisiBancarie',
        templateId: 177,
        fromTemplate: true
      },
      GRAFICO_PORTAFOGLIO: {
        id: 'graficoPortafoglio',
        templateId: 182
      }
    },
    // NUOVA MIFID PG
    [Forms.MIFID_CONOSCENZA_PG]: {
      TITOLO_STUDIO: {
        id: 'titoloStudio',
        templateId: 187,
        dominio: 'DOM_TITOLO_STUDIO_MIFID',
        dominioDep: null,
        defaultValue: '',
        descRisposta: true
      },
      SETTORE_OCCUPAZIONE: {
        id: 'settoreOccupazione',
        templateId: 188,
        dominio: 'DOM_SETTORE_OCCUPAZIONE_MIFID',
        dominioDep: null,
        defaultValue: ''
      },
      ALTRI_CONTRATTI_CONSULENZA: {
        id: 'altriContrattiConsulenza',
        templateId: 189,
        defaultValue: ''
      },
      ALTRI_CONTRATTI_COMMERCIALIZZAZIONE: {
        id: 'altriContrattiCommercializzazione',
        templateId: 190,
        defaultValue: ''
      },
      RISCHIO_RENDIMENTO: {
        id: 'rischioRendimento',
        templateId: 191
      },
      DIVERSIFICAZIONE_RISCHIO: {
        id: 'diversificazioneRischio',
        templateId: 192
      },
      ORIZZONTE_TEMPORALE: {
        id: 'orizzonteTemporale',
        templateId: 193
      },
      VERO_FALSO: {
        id: 'veroFalso',
        templateId: 194,
        fromTemplate: true
      },
      SI_NO: {
        id: 'siNo',
        templateId: 204,
        fromTemplate: true
      },
      INVESTIMENTO: {
        id: 'investimento',
        templateId: 211
      }
    },
    [Forms.MIFID_FINANZIARIA_PG]: {
      RICAVI_MEDI: {
        id: 'ricaviMedi',
        templateId: 212,
        defaultValue: ''
      },
      RICAVI_FUTURO: {
        id: 'ricaviFuturo',
        templateId: 213,
        defaultValue: ''
      },
      ATTIVO_BILANCIO_MEDIO: {
        id: 'attivoBilancioMedio',
        templateId: 214,
        defaultValue: ''
      },
      PATRIMONIO_NETTO: {
        id: 'patrimonioNetto',
        templateId: 215,
        defaultValue: ''
      },
      PATRIMONIO_FINANZIARIO_COMPLESSIVO: {
        id: 'patrimonioFinanziarioComplessivo',
        templateId: 216,
        defaultValue: ''
      }
    },
    [Forms.MIFID_TOLLERANZA_PG]: {
      PERDITA: {
        id: 'perdita',
        templateId: 217
      },
      RISCHIO: {
        id: 'rischio',
        templateId: 218
      }
    },
    [Forms.MIFID_OBIETTIVI_PG]: {
      OBIETTIVI_INVESTIMENTI: {
        id: 'obiettiviInvestimenti',
        templateId: 219
      },
      ESIGENZE_LIQUIDITA: {
        id: 'esigenzeLiquidita',
        templateId: 220
      },
      // volatilità non è presente nelle nuove modifiche mifid
      /* VOLATILITA: {
        id: 'volatilita',
        templateId: 221
      }, */
      // aggiunta di 3 nuove domanade di cui ESTENSIONI_SUCCESSORIE uguale a PF
      LIMITAZIONI_DISINVESTIMENTO: {
        id: 'limitazioniDisinvestimento',
        templateId: 256
      },
      PERCENTUALE_DISINVESTIMENTO: {
        id: 'percentualeDisinvestimento',
        templateId: 221
      },
      OBBLIGAZIONI_PERPETUE: {
        id: 'obbligazioniPerpetue',
        templateId: 222
      },
      ESIGENZE_ASSICURATIVE: {
        id: 'esigenzeAssicurative',
        templateId: 223
      },
      PREFERENZE_INVESTIMENTI: {
        id: 'preferenzeInvestimenti',
        templateId: 225
      }
    },
    [Forms.MIFID_INVESTIMENTI_ESG_PG]: {
      CONOSCENZA_ESPERIENZA: {
        id: 'conoscenza_esperienza',
        templateId: 261
      },
      QUOTA_PORTAFOGLIO: {
        id: 'quota_portafoglio',
        templateId: 286
      },
      QUOTA_PORTAFOGLIO2: {
        id: 'quota_portafoglio2',
        templateId: 287
      },
      INTERESSE: {
        id: 'interesse',
        templateId: 268
      },
      INTERESSE2: {
        id: 'interesse',
        templateId: 269
      },
      IMPATTI_AMBIENTE: {
        id: 'impatti_ambiente',
        templateId: 291
      },
      IMPATTI_SOCIALI_GOV: {
        id: 'impatti_sociali_gov',
        templateId: 294
      },
      STRUMENTI_SOCIALI_GOV: {
        id: 'strumenti_sociali_gov',
        templateId: 297
      },
      STRUMENTI_AMBIENTE: {
        id: 'strumenti_ambiente',
        templateId: 300
      }
    },
    [Forms.MIFID_EDUCAZIONE_PG]: {
      DIPENDENTI: {
        id: 'dipendenti',
        templateId: 226
      },
      WELFARE: {
        id: 'welfare',
        templateId: 227
      },
      KEY_MAN: {
        id: 'keyMan',
        templateId: 228
      },
      POLIZZE_ASSICURATIVE: {
        id: 'polizzeAssicurative',
        templateId: 229
      },
      FATTURATO: {
        id: 'fatturato',
        templateId: 230
      },
      ATTIVITA_SOSTENIBILI: {
        id: 'attivitaSostenibili',
        templateId: 233
      },
      BILANCIO_SOCIALE: {
        id: 'bilancioSociale',
        templateId: 234
      },
      FONDO_PENSIONE_CATEGORIA: {
        id: 'fondoPensioneCategoria',
        templateId: 235
      },
      FONDO_PENSIONE_APERTO: {
        id: 'fondoPensioneAperto',
        templateId: 236
      },
      UTILIZZO_TESORERIA: {
        id: 'utilizzoTesoreria',
        templateId: 237
      },
      SCADENZA_DEBITO: {
        id: 'scadenzaDebito',
        templateId: 238
      }
    },
    // NUOVA MIFID PD
    [Forms.MIFID_TOLLERANZA_PD]: {
      PERDITA: {
        id: 'perdita',
        templateId: 243
      },
      RISCHIO: {
        id: 'rischio',
        templateId: 244
      }
    },
    [Forms.MIFID_OBIETTIVI_PD]: {
      OBIETTIVI_INVESTIMENTI: {
        id: 'obiettiviInvestimenti',
        templateId: 245
      },
      ESIGENZE_LIQUIDITA: {
        id: 'esigenzeLiquidita',
        templateId: 246
      },
      LIMITAZIONI_DISINVESTIMENTO: {
        id: 'limitazioniDisinvestimento',
        templateId: 257
      },
      ESIGENZE_ASSICURATIVE: {
        id: 'esigenzeAssicurative',
        templateId: 247
      },
      PREFERENZE_INVESTIMENTI: {
        id: 'preferenzeInvestimenti',
        templateId: 249
      }
    },
    [Forms.MIFID_INVESTIMENTI_ESG_PD]: {
      CONOSCENZA_ESPERIENZA: {
        id: 'conoscenza_esperienza',
        templateId: 262
      },
      QUOTA_PORTAFOGLIO: {
        id: 'quota_portafoglio',
        templateId: 288
      },
      QUOTA_PORTAFOGLIO2: {
        id: 'quota_portafoglio2',
        templateId: 289
      },
      INTERESSE: {
        id: 'interesse',
        templateId: 270
      },
      INTERESSE2: {
        id: 'interesse',
        templateId: 271
      },
      IMPATTI_AMBIENTE: {
        id: 'impatti_ambiente',
        templateId: 292
      },
      IMPATTI_SOCIALI_GOV: {
        id: 'impatti_sociali_gov',
        templateId: 295
      },
      STRUMENTI_SOCIALI_GOV: {
        id: 'strumenti_sociali_gov',
        templateId: 298
      },
      STRUMENTI_AMBIENTE: {
        id: 'strumenti_ambiente',
        templateId: 301
      }
    },
    // [Forms.MIFID_EDUCAZIONE_PD]: {
    //   RICAVI_MEDI: {
    //     id: 'ricaviMedi',
    //     templateId: 248
    //   },
    //   RICAVI_FUTURO: {
    //     id: 'ricaviFuturo',
    //     templateId: 249
    //   }
    // },
    [Forms.MIFID_FINANZIARIA_PD]: {
      RICAVI_MEDI: {
        id: 'ricaviMedi',
        templateId: 250
      },
      RICAVI_FUTURO: {
        id: 'ricaviFuturo',
        templateId: 251
      },
      ATTIVO_BILANCIO_MEDIO: {
        id: 'attivoBilancioMedio',
        templateId: 252
      },
      PATRIMONIO_NETTO: {
        id: 'patrimonioNetto',
        templateId: 253
      },
      PATRIMONIO_FINANZIARIO_COMPLESSIVO: {
        id: 'patrimonioFinanziarioComplessivo',
        templateId: 254
      }
    }
  },
  {
    [Forms.SERVIZI_DIGITALI]: {
      SERVIZIO: {
        id: 'flagConsulenzaCollocamento',
        hiddenId: 'flagCollocamento',
        defaultValue: 'true'
      },
      MODULO: {
        id: 'immagineFirma',
        defaultValue: null
      },
      CHECK_MODULO: {
        id: 'flagModuloFirmato',
        label: 'Confermo di avere raccolto lo specimen di firma',
        defaultValue: '',
        maxLength: 20,
        validazione: /^[a-zA-Z\s]*$/g
      },
      MODULO_THUMB: {
        id: 'immagineFirmaThumb',
        defaultValue: null
      },
      COMUNE_FIRMA: {
        id: 'comuneFirma',
        label: 'Luogo sottoscrizione*',
        defaultValue: '',
        dominio: 'DOM_COMUNE_FIRMA',
        dominioDep: null,
        validazione: /^[a-zA-Z0-9]*$/g
      }
    }
  },
  {
    [Forms.DATA_GATHERING]: {
      EMAIL: {
        id: 'emailAddress',
        label: 'Email*',
        defaultValue: '',
        validazione: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/
      },
      FLAG_EMAIL: {
        id: 'flagEmailCertified',
        defaultValue: false,
        validazione: true
      },
      PREFISSO: {
        id: 'internationalPrefixCellNumberCode',
        label: 'Prefisso*',
        defaultValue: '+39',
        dominio: 'DOM_PREFISSI',
        dominioDep: null
      },
      CELLULARE: {
        id: 'mobileNumber',
        label: 'Cellulare*',
        defaultValue: '',
        validazione: /^[0-9]*$/g
      },
      FLAG_CELLULARE: {
        id: 'flagMobileNumberCertified',
        defaultValue: false,
        validazione: true
      },
      NOME: {
        id: 'name',
        label: 'Nome*',
        defaultValue: '',
        maxLength: 50,
        validazione: /^[a-zA-ZÀÁÈÉÌÍÒÓÙÚà-ž\s'’-]*$/g
      },
      COGNOME: {
        id: 'surname',
        label: 'Cognome*',
        defaultValue: '',
        maxLength: 50,
        validazione: /^[a-zA-ZÀÁÈÉÌÍÒÓÙÚà-ž\s'’-]*$/g
      },
      CODICE_FISCALE: {
        id: 'taxCode',
        label: 'Codice Fiscale*',
        defaultValue: '',
        minLength: 16,
        maxLength: 16,
        validazione: /^[a-zA-Z0-9]*$/g
      },
      PARTITA_IVA: {
        id: 'partitaIva',
        label: 'Partita IVA*',
        minLength: 11,
        maxLength: 11,
        defaultValue: '',
        validazione: /^[0-9]{11}$/g
      },
      CITTADINANZA: {
        id: 'citizenshipCode',
        label: 'Cittadinanza*',
        defaultValue: '',
        dominio: 'DOM_CITTADINANZE',
        dominioDep: null,
        validazione: /^[a-zA-Z0-9]*$/g
      },
      NAZIONE: {
        id: 'nationBirthCode',
        label: 'Nazione di nascita*',
        defaultValue: '',
        dominio: 'DOM_NAZIONE_NASCITA',
        dominioDep: null,
        italiaId: 'ITA',
        statiUnitiId: 'USA',
        validazione: /^[a-zA-Z0-9]*$/g
      },
      PROVINCIA: {
        id: 'provinceBirthCode',
        label: 'Provincia di nascita*',
        defaultValue: '',
        dominio: 'DOM_PROVINCE_NASCITA',
        dominioDep: null,
        validazione: /^[a-zA-Z0-9]*$/g
      },
      RESIDENZA: {
        id: 'registeredResidenceCode',
        label: 'Residenza*',
        defaultValue: 'ITA',
        dominio: 'DOM_NAZIONE_RESIDENZA',
        dominioDep: null,
        italiaId: 'ITA',
        validazione: /^[a-zA-Z0-9]*$/g
      },
      COMUNE: {
        id: 'cityBirthCode',
        label: 'Comune di nascita*',
        defaultValue: '',
        dominio: 'DOM_COMUNE_NASCITA',
        dominioDep: 'provinceBirthCode',
        validazione: /^[a-zA-Z0-9]*$/g
      },
      COMUNE_ESTERO: {
        id: 'cityBirthDescription',
        label: 'Comune di nascita*',
        defaultValue: ''
      },
      DATA_NASCITA: {
        id: 'birthDate',
        label: 'Data di nascita*',
        defaultValue: ''
      },
      SETTORE: {
        id: 'jobSectorCode',
        label: 'Settore',
        dominio: 'DOM_SETTORE_OCCUPAZIONE_I_AML',
        dominioDep: null,
        defaultValue: ''
      },
      RUOLO: {
        id: 'jobRoleCode',
        label: 'Ruolo',
        dominio: 'DOM_PROFESSIONE_ATTUALE_AML',
        dominioDep: null,
        defaultValue: ''
      },
      AZIENDA: {
        id: 'companyName',
        label: 'Azienda',
        defaultValue: '',
        maxLength: 50
      },
      CONSULENZA: {
        id: 'codiceTipoConsulenza',
        label: 'Consulenza',
        defaultValue: '',
        chiave: ''
      }
    }
  },
  {
    [Forms.DATA_GATHERING]: {
      EMAIL: {
        id: 'emailAddress',
        label: 'Email*',
        defaultValue: '',
        validazione: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/
      },
      FLAG_EMAIL: {
        id: 'flagEmailCertified',
        defaultValue: false,
        validazione: true
      },
      PREFISSO: {
        id: 'internationalPrefixCellNumberCode',
        label: 'Prefisso*',
        defaultValue: '+39',
        dominio: 'DOM_PREFISSI',
        dominioDep: null
      },
      CELLULARE: {
        id: 'mobileNumber',
        label: 'Cellulare*',
        defaultValue: '',
        validazione: /^[0-9]*$/g
      },
      FLAG_CELLULARE: {
        id: 'flagMobileNumberCertified',
        defaultValue: false,
        validazione: true
      },
      ...commonDatiGiuridiche
    }
  },
  {
    [Forms.CODICE_FISCALE_PF]: {
      CODICE_FISCALE: {
        id: 'taxCode',
        constant: 'CODICE_FISCALE',
        label: 'Codice Fiscale*',
        defaultValue: '',
        minLength: 16,
        maxLength: 16,
        validazione: /^[a-zA-Z0-9]*$/g
      },
      // CF_CHECK: {
      //   id: 'CF_CHECK',
      //   label: 'flag di conferma ricezione CF',
      //   defaultValue: false
      // },
      // FLAG_CF_FRONTE: {
      //   id: 'isTaxCodeFrontUploaded',
      //   defaultValue: false
      // },
      CF_FRONTE_PG: {
        id: 'isTaxCodeFrontUploaded',
        defaultValue: false
      },
      CF_RETRO_PG: {
        id: 'isTaxCodeBackUploaded',
        defaultValue: false
      }
      // CF_FRONTE: {
      //   id: 'imgTaxCodeFront',
      //   defaultValue: null,
      //   isImage: true,
      //   tipoAllegato: 'CF_FRONTE'
      // },
      // CF_FRONTE_THUMB: {
      //   id: 'imgTaxCodeFront',
      //   defaultValue: null,
      //   isThumb: true
      // },
      // FLAG_CF_RETRO: {
      //   id: 'isTaxCodeBackUploaded',
      //   defaultValue: false
      // },
      // CF_RETRO: {
      //   id: 'imgTaxCodeBack',
      //   defaultValue: null,
      //   isImage: true,
      //   tipoAllegato: 'CF_RETRO'
      // },
      // CF_RETRO_THUMB: {
      //   id: 'imgTaxCodeBack',
      //   defaultValue: null,
      //   isThumb: true
      // },
      // FLAG_CUSTOMER_IS_BLACKLIST: {
      //   id: 'flagCustomerIsBlacklist',
      //   defaultValue: false
      // }
    }
  },
  {
    [Forms.INFO_GENERALI]: {
      COMUNE_FIRMA: {
        id: 'codiceComuneFirma',
        label: 'Luogo sottoscrizione*',
        defaultValue: '',
        dominio: 'DOM_COMUNE_FIRMA',
        validazione: /^[a-zA-Z0-9]*$/g,
        isNotCorrispondenza: true
      }
    }
  }
]
