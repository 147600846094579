import React, { Component, Fragment } from 'react'
import { PropTypes } from 'prop-types'
import { Field } from 'redux-form'
import moment from 'moment'
import { Row, Col } from 'react-materialize'
import {
  reduceQuestions,
  mapDominiToOptions,
  mapDominiToAutocomplete,
  getValueDominioFromKey,
  getDominio
} from '../../utils'
import AccordionHeader from '../../../../components/AccordionHeader'
import { CollapsibleItem } from '../../../../components/Collapsible'
import InputText from '../../../../components/Inputs/Text'
import { ANTIRICICLAGGIO } from '../../../../store/modules/forms/constants'
import {
  renderAutocomplete,
  renderDatePicker,
  renderHtmlInput,
  renderSelect,
  renderYesNoQuestion
} from '../../../../components/Forms/renderFields'
import * as dataSB from '../../../../static-data/data-servizio-base.json'
import Title from '../../../../components/Title'
import ButtonSubmit from '../../../../components/Buttons/Submit'
import SurveyCheckbox from '../../../../components/SurveyCheckbox'
import SurveyHr from '../../../../components/SurveyHr'
import SurveyRadio from '../../../../components/SurveyRadio'
import YesNo from '../../QuestionarioProfilatura/YesNo'
import { checkValidationErrors, getErrorMessage } from '../validation'
import ModalGeneric from '../../../../components/ModalGeneric'

class AntiRiciclaggio extends Component {
  constructor(props) {
    super(props)
    this.state = {
      saved: false,
      nazione: [],
      showModalAlertFieldsErrors: false,
      modalAlertBodyFieldsErrors: '',
      fieldsWithErrors: []
    }
    this.isOutsideRange = this.isOutsideRange.bind(this)
    this.getOrderedNazioni = this.getOrderedNazioni.bind(this)
    this.isDisabled = this.isDisabled.bind(this)
    this.isSettoreOccupazioneDisabled = this.isSettoreOccupazioneDisabled.bind(this)
  }

  componentDidMount() {
    this.setState({ nazione: this.getOrderedNazioni(this.props.domini) })
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevProps.apiSuccess && this.props.apiSuccess && this.props.submitSucceeded) {
      this.props.initialize(this.props.formValues)
      this.props.resetForm(ANTIRICICLAGGIO)
      this.setState({ saved: true })
    }

    if (prevProps.dominioRequest.fetching && !this.props.dominioRequest.fetching && !this.props.dominioRequest.error) {
      this.setState({ nazione: this.getOrderedNazioni(this.props.domini) })
    }

    const {
      template: { listaDomande },
      formValues,
      fields
    } = this.props
    const questionsWithErrors = checkValidationErrors(listaDomande, formValues, fields)
    const fieldsWithErrors = questionsWithErrors.map(field => field.getId())
    const isNewErrorAdded = fieldsWithErrors.some(field => !prevState.fieldsWithErrors.includes(field))
    if (fieldsWithErrors.length !== prevState.fieldsWithErrors.length || isNewErrorAdded) {
      this.setState({
        fieldsWithErrors,
        showModalAlertFieldsErrors: isNewErrorAdded,
        modalAlertBodyFieldsErrors: isNewErrorAdded ? getErrorMessage(questionsWithErrors) : ''
      })
    }
  }

  getOrderedNazioni(domini) {
    const { fields } = this.props
    const {
      NAZIONE_INSEDIAMENTO: { dominio, italiaId }
    } = fields
    const nazioni = mapDominiToAutocomplete(getDominio(domini, dominio))
    const itIndex = nazioni.findIndex(n => n.value === italiaId)
    if (itIndex > -1) {
      return [nazioni[itIndex], ...nazioni.slice(0, itIndex), ...nazioni.slice(itIndex + 1)]
    }
    return nazioni
  }

  isOutsideRange(date) {
    const { dataNascitaCF } = this.props
    if (!dataNascitaCF) return date.isAfter(moment(), 'day')
    return date.isAfter(moment(), 'day') || date.isBefore(moment(dataNascitaCF), 'day')
  }

  isSettoreOccupazioneDisabled() {
    const { fields, formValues } = this.props
    if (
      formValues[fields.PROFESSIONE_ATTUALE.id] === fields.PROFESSIONE_ATTUALE.pensionatoId ||
      formValues[fields.PROFESSIONE_ATTUALE.id] === fields.PROFESSIONE_ATTUALE.casalingaId ||
      formValues[fields.PROFESSIONE_ATTUALE.id] === fields.PROFESSIONE_ATTUALE.disoccupStudId
    ) {
      return true
    }
    return false
  }

  isDisabled() {
    const { fields, formValues } = this.props

    if (formValues[fields.PROFESSIONE_PRECEDENTE.id] !== '') {
      if ('' + formValues[fields.PROFESSIONE_PRECEDENTE.id] !== fields.PROFESSIONE_ATTUALE.liberoProfId) {
        return true
      }
    } else {
      if ('' + formValues[fields.PROFESSIONE_ATTUALE.id] !== fields.PROFESSIONE_ATTUALE.liberoProfId) {
        return true
      }
    }
    return false
  }

  render() {
    const {
      template,
      domini,
      handleSubmit,
      onSave,
      isDirty,
      formValues,
      fields,
      status,
      change,
      submitting,
      isFetching,
      invalid,
      touch
    } = this.props
    const { listaDomande } = template
    const isInvalidForm = this.state.fieldsWithErrors.length !== 0
    const radioPepRisposte = listaDomande
      .find(d => d.idDomanda === fields.RADIO_PEP.templateId)
      .listaRisposte.map(r => '' + r.idRisposta)
    const submit = values => {
      if (onSave) {
        this.setState({ saved: false })
        let bodyRequest = reduceQuestions(fields, {
          ...values,
          [fields.INIZIO_CARICA.id]:
            values[fields.RADIO_PEP.id] === '' + fields.RADIO_PEP.resetId ? '' : values[fields.INIZIO_CARICA.id]
        })
        onSave(bodyRequest, true)
      }
    }
    return (
      <CollapsibleItem
        header={dataSB.censimento_anagrafica.antiriciclaggio.title_accordion}
        icon="keyboard_arrow_down"
        itemKey={ANTIRICICLAGGIO}
        labelInfo={AccordionHeader(isDirty, this.state.saved, status)}>
        {this.state.showModalAlertFieldsErrors && (
          <ModalGeneric
            show={this.state.showModalAlertFieldsErrors}
            text={this.state.modalAlertBodyFieldsErrors}
            handler={() => this.setState({ showModalAlertFieldsErrors: false })}
          />
        )}
        <form onSubmit={handleSubmit(submit)} noValidate className="anti-riciclaggio">
          <Row>
            <Col s={12}>
              <Title>Il soggetto dichiara di essere un titolare effettivo</Title>
            </Col>
          </Row>
          <Row className="m-top20">
            <Row>
              <Col s={6}>
                <Field
                  name={fields.TITOLO_STUDIO.id}
                  size={12}
                  label={listaDomande.find(c => c.idDomanda === fields.TITOLO_STUDIO.templateId).testoDomanda}
                  options={mapDominiToOptions(getDominio(domini, fields.TITOLO_STUDIO.dominio), true)}
                  component={renderSelect}
                />
              </Col>
            </Row>
          </Row>
          <Row>
            <Row>
              <Col s={6} key={1}>
                <Field
                  name={fields.NATURA_RAPPORTO.id}
                  size={12}
                  label={listaDomande.find(c => c.idDomanda === fields.NATURA_RAPPORTO.templateId).testoDomanda}
                  options={mapDominiToOptions(getDominio(domini, fields.NATURA_RAPPORTO.dominio), true)}
                  onChange={value => {
                    if (value !== fields.NATURA_RAPPORTO.altroId && formValues[fields.NATURA_RAPPORTO_ALTRO.id]) {
                      change(fields.NATURA_RAPPORTO_ALTRO.id, '')
                    }
                  }}
                  component={renderSelect}
                />
              </Col>
              <Col s={6} key={2}>
                <Field
                  name={fields.NATURA_RAPPORTO_ALTRO.id}
                  s={12}
                  maxLength={20}
                  label={listaDomande.find(c => c.idDomanda === fields.NATURA_RAPPORTO_ALTRO.templateId).testoDomanda}
                  value={formValues[fields.NATURA_RAPPORTO_ALTRO.id]}
                  disabled={'' + formValues[fields.NATURA_RAPPORTO.id] !== fields.NATURA_RAPPORTO.altroId}
                  change={change}
                  touch={touch}
                  component={InputText}
                />
              </Col>
            </Row>
          </Row>
          <Row>
            <Row>
              <Col s={6} key={1}>
                <Field
                  name={fields.SETTORE_OCCUPAZIONE.id}
                  size={12}
                  label={listaDomande.find(c => c.idDomanda === fields.SETTORE_OCCUPAZIONE.templateId).testoDomanda}
                  disabled={this.isSettoreOccupazioneDisabled()}
                  options={mapDominiToOptions(getDominio(domini, fields.SETTORE_OCCUPAZIONE.dominio), true)}
                  onChange={value => {
                    if (
                      value !== fields.SETTORE_OCCUPAZIONE_ALTRO.altroId &&
                      formValues[fields.SETTORE_OCCUPAZIONE_ALTRO.id]
                    ) {
                      change(fields.SETTORE_OCCUPAZIONE_ALTRO.id, '')
                    }
                  }}
                  component={renderSelect}
                />
              </Col>
              <Col s={6} key={2}>
                <Field
                  name={fields.SETTORE_OCCUPAZIONE_ALTRO.id}
                  size={12}
                  label={
                    listaDomande.find(c => c.idDomanda === fields.SETTORE_OCCUPAZIONE_ALTRO.templateId).testoDomanda
                  }
                  options={mapDominiToOptions(getDominio(domini, fields.SETTORE_OCCUPAZIONE_ALTRO.dominio), true)}
                  disabled={
                    '' + formValues[fields.SETTORE_OCCUPAZIONE.id] !== fields.SETTORE_OCCUPAZIONE.altroId ||
                    formValues[fields.PROFESSIONE_ATTUALE.id] !== fields.PROFESSIONE_ATTUALE.pensionatoId ||
                    formValues[fields.PROFESSIONE_ATTUALE.id] !== fields.PROFESSIONE_ATTUALE.casalingaId ||
                    formValues[fields.PROFESSIONE_ATTUALE.id] !== fields.PROFESSIONE_ATTUALE.disoccupStudId
                  }
                  component={renderSelect}
                />
              </Col>
            </Row>
          </Row>
          <Row>
            <Row>
              <Col s={6} key={1}>
                <Field
                  name={fields.PROFESSIONE_ATTUALE.id}
                  size={12}
                  label={listaDomande.find(c => c.idDomanda === fields.PROFESSIONE_ATTUALE.templateId).testoDomanda}
                  options={mapDominiToOptions(getDominio(domini, fields.PROFESSIONE_ATTUALE.dominio), true)}
                  onChange={value => {
                    change(fields.ANNO_INIZIO.id, '')
                    if (
                      value.target.value === fields.PROFESSIONE_ATTUALE.pensionatoId ||
                      value.target.value === fields.PROFESSIONE_ATTUALE.casalingaId ||
                      value.target.value === fields.PROFESSIONE_ATTUALE.disoccupStudId
                    ) {
                      change(fields.SETTORE_OCCUPAZIONE.id, fields.SETTORE_OCCUPAZIONE.altroId)
                      change(fields.SETTORE_OCCUPAZIONE_ALTRO.id, '')
                    }

                    if (
                      value !== fields.PROFESSIONE_ATTUALE.pensionatoId &&
                      formValues[fields.PROFESSIONE_PRECEDENTE.id]
                    ) {
                      change(fields.PROFESSIONE_PRECEDENTE.id, '')
                    }
                    if (
                      value !== fields.PROFESSIONE_ATTUALE.liberoProfId &&
                      formValues[fields.NAZIONE_INSEDIAMENTO.id]
                    ) {
                      change(fields.NAZIONE_INSEDIAMENTO.id, '')
                    }
                    if (value !== fields.PROFESSIONE_ATTUALE.liberoProfId && formValues[fields.PROVINCIA_ATTIVITA.id]) {
                      change(fields.PROVINCIA_ATTIVITA.id, '')
                    }
                    if (value !== fields.PROFESSIONE_ATTUALE.liberoProfId && formValues[fields.ANNO_INIZIO.id]) {
                      change(fields.ANNO_INIZIO.id, '')
                    }
                  }}
                  component={renderSelect}
                />
              </Col>
              <Col s={6} key={2}>
                <Field
                  name={fields.PROFESSIONE_PRECEDENTE.id}
                  size={12}
                  label={listaDomande.find(c => c.idDomanda === fields.PROFESSIONE_PRECEDENTE.templateId).testoDomanda}
                  disabled={formValues[fields.PROFESSIONE_ATTUALE.id] !== fields.PROFESSIONE_ATTUALE.pensionatoId}
                  options={mapDominiToOptions(
                    Object.keys(getDominio(domini, fields.PROFESSIONE_ATTUALE.dominio)).reduce((res, cur) => {
                      if (cur !== fields.PROFESSIONE_ATTUALE.pensionatoId) {
                        return {
                          ...res,
                          [cur]: getDominio(domini, fields.PROFESSIONE_ATTUALE.dominio)[cur]
                        }
                      }
                      return res
                    }, {}),
                    true
                  )}
                  component={renderSelect}
                  onChange={value => {
                    if (
                      value !== fields.PROFESSIONE_ATTUALE.liberoProfId &&
                      formValues[fields.NAZIONE_INSEDIAMENTO.id]
                    ) {
                      change(fields.NAZIONE_INSEDIAMENTO.id, '')
                    }
                    if (value !== fields.PROFESSIONE_ATTUALE.liberoProfId && formValues[fields.PROVINCIA_ATTIVITA.id]) {
                      change(fields.PROVINCIA_ATTIVITA.id, '')
                    }
                    if (value !== fields.PROFESSIONE_ATTUALE.liberoProfId && formValues[fields.ANNO_INIZIO.id]) {
                      change(fields.ANNO_INIZIO.id, '')
                    }
                  }}
                />
              </Col>
            </Row>
          </Row>
          <Row>
            <Col s={12}>
              <Title>Principale insediamento attività (altro lavoratore in proprio)</Title>
            </Col>
          </Row>
          <Row className="m-top20">
            <Row>
              <Col s={6}>
                <Col s={12}>
                  <Field
                    name={fields.NAZIONE_INSEDIAMENTO.id}
                    threshold={0}
                    defaultValue={getValueDominioFromKey(
                      domini.listaDomini,
                      fields.NAZIONE_INSEDIAMENTO.dominio,
                      formValues[fields.NAZIONE_INSEDIAMENTO.id]
                    )}
                    label={listaDomande.find(c => c.idDomanda === fields.NAZIONE_INSEDIAMENTO.templateId).testoDomanda}
                    options={this.state.nazione}
                    handleChange={(field, value) => {
                      change(field, value)
                      if (formValues[fields.PROVINCIA_ATTIVITA.id]) {
                        change(fields.PROVINCIA_ATTIVITA.id, '')
                      }
                    }}
                    disabled={this.isDisabled()}
                    component={renderAutocomplete}
                  />
                </Col>
              </Col>
              <Col s={6}>
                <Col s={12}>
                  <Field
                    name={fields.PROVINCIA_ATTIVITA.id}
                    size={12}
                    threshold={0}
                    defaultValue={getValueDominioFromKey(
                      domini.listaDomini,
                      fields.PROVINCIA_ATTIVITA.dominio,
                      formValues[fields.PROVINCIA_ATTIVITA.id]
                    )}
                    label={listaDomande.find(c => c.idDomanda === fields.PROVINCIA_ATTIVITA.templateId).testoDomanda}
                    options={mapDominiToAutocomplete(getDominio(domini, fields.PROVINCIA_ATTIVITA.dominio))}
                    disabled={
                      this.isDisabled() ||
                      '' + formValues[fields.NAZIONE_INSEDIAMENTO.id] !== fields.NAZIONE_INSEDIAMENTO.italiaId
                    }
                    handleChange={change}
                    component={renderAutocomplete}
                  />
                </Col>
              </Col>
            </Row>
            <Row>
              <Col s={3}>
                <Field
                  name={fields.ANNO_INIZIO.id}
                  s={12}
                  maxLength={4}
                  label={listaDomande.find(c => c.idDomanda === fields.ANNO_INIZIO.templateId).testoDomanda}
                  value={formValues[fields.ANNO_INIZIO.id]}
                  disabled={this.isDisabled()}
                  change={change}
                  touch={touch}
                  component={InputText}
                />
              </Col>
            </Row>
          </Row>
          <div className="pep-section">
            <Row>
              <Col s={12}>
                <Title>{listaDomande.find(c => c.idDomanda === fields.RADIO_PEP.templateId).testoDomanda}</Title>
                <div className="big-radio">
                  {listaDomande
                    .find(c => c.idDomanda === fields.RADIO_PEP.templateId)
                    .listaRisposte.map((risposta, index) => (
                      <div key={index}>
                        <Field
                          name={fields.RADIO_PEP.id}
                          className="with-gap"
                          type="radio"
                          id={`type-${index}`}
                          value={'' + risposta.idRisposta}
                          onChange={() => {
                            // se rispondo NO devo cancellare i valori eventualmente salvati in precedenza
                            if (risposta.idRisposta === fields.RADIO_PEP.resetId) {
                              change(fields.CARICA_RICOPERTA.id, '')
                              change(fields.INIZIO_CARICA.id, '')
                              change(fields.FONTE_REDDITO.id, '')
                              change(fields.RADIO_PEP_PERCHE.id, '')
                              change(fields.RADIO_PEP_RAPPORTI_PERCHE.id, '')
                              change(fields.RADIO_PEP_REDDITO.id, '')
                              change(fields.YES_NO_PEP_PARTECIPAZIONI_SOC.id, '')
                              change(fields.YES_NO_PEP_PROCEDIMENTI.id, '')
                            }
                            // se rispondo SI RAPPORTI CON PEP devo cancellare solo alcuni valori
                            if (risposta.idRisposta === fields.RADIO_PEP.rapportiPEPId) {
                              change(fields.CARICA_RICOPERTA.id, '')
                              change(fields.INIZIO_CARICA.id, '')
                              change(fields.FONTE_REDDITO.id, '')
                              change(fields.RADIO_PEP_PERCHE.id, '')
                            }
                            // se la risposta è diversa da SI RAPPORTI CON PEP devo cancellare solo questo campo
                            if (risposta.idRisposta !== fields.RADIO_PEP.rapportiPEPId) {
                              change(fields.RADIO_PEP_RAPPORTI_PERCHE.id, '')
                            }
                          }}
                          component={renderHtmlInput}
                        />
                        <label htmlFor={`type-${index}`}>{risposta.descRisposta}</label>
                      </div>
                    ))}
                </div>
              </Col>
            </Row>
            <div
              className={`pep-more-info-box${
                formValues[fields.RADIO_PEP.id] && formValues[fields.RADIO_PEP.id] !== radioPepRisposte[0]
                  ? ' pep-active'
                  : ''
              }`}>
              {formValues[fields.RADIO_PEP.id] && formValues[fields.RADIO_PEP.id] !== radioPepRisposte[3] && (
                <Fragment>
                  <Row className="p-top20">
                    <Row>
                      <Col s={6}>
                        <Field
                          name={fields.CARICA_RICOPERTA.id}
                          s={12}
                          maxLength={50}
                          label={
                            listaDomande.find(c => c.idDomanda === fields.CARICA_RICOPERTA.templateId).testoDomanda
                          }
                          value={formValues[fields.CARICA_RICOPERTA.id]}
                          change={change}
                          touch={touch}
                          component={InputText}
                        />
                      </Col>
                      <Col s={6}>
                        <Field
                          name={fields.INIZIO_CARICA.id}
                          s={12}
                          openDirection="down"
                          placeholder=""
                          label={listaDomande.find(c => c.idDomanda === fields.INIZIO_CARICA.templateId).testoDomanda}
                          defaultDate={formValues[fields.INIZIO_CARICA.id]}
                          isOutsideRange={this.isOutsideRange}
                          onDateChange={date => change(fields.INIZIO_CARICA.id, date.format())}
                          readOnly
                          component={renderDatePicker}
                        />
                      </Col>
                    </Row>
                  </Row>
                  <Row>
                    <Row>
                      <Col s={12}>
                        <Field
                          name={fields.FONTE_REDDITO.id}
                          s={12}
                          maxLength={50}
                          label={listaDomande.find(c => c.idDomanda === fields.FONTE_REDDITO.templateId).testoDomanda}
                          value={formValues[fields.FONTE_REDDITO.id]}
                          change={change}
                          touch={touch}
                          component={InputText}
                        />
                      </Col>
                    </Row>
                  </Row>
                </Fragment>
              )}
              {formValues[fields.RADIO_PEP.id] && formValues[fields.RADIO_PEP.id] !== radioPepRisposte[3] && (
                <Row>
                  <Col s={12}>
                    <Title>
                      {listaDomande.find(c => c.idDomanda === fields.RADIO_PEP_PERCHE.templateId).testoDomanda}
                    </Title>
                    <div className="radio-vertical">
                      {listaDomande
                        .find(d => d.idDomanda === fields.RADIO_PEP_PERCHE.templateId)
                        .listaRisposte.map((risposta, index) => (
                          <p key={`pep-perche-${index}`}>
                            <Field
                              name={fields.RADIO_PEP_PERCHE.id}
                              className="with-gap"
                              type="radio"
                              id={`pep-perche-i-${index}`}
                              value={'' + risposta.idRisposta}
                              component={renderHtmlInput}
                            />
                            <label htmlFor={`pep-perche-i-${index}`}>{risposta.descRisposta}</label>
                          </p>
                        ))}
                    </div>
                  </Col>
                </Row>
              )}
              {formValues[fields.RADIO_PEP.id] &&
                formValues[fields.RADIO_PEP.id] !== radioPepRisposte[1] &&
                formValues[fields.RADIO_PEP.id] !== radioPepRisposte[2] && (
                  <Row>
                    <Col s={12}>
                      <Title>
                        {
                          listaDomande.find(c => c.idDomanda === fields.RADIO_PEP_RAPPORTI_PERCHE.templateId)
                            .testoDomanda
                        }
                      </Title>
                      <div className="radio-vertical">
                        {listaDomande
                          .find(d => d.idDomanda === fields.RADIO_PEP_RAPPORTI_PERCHE.templateId)
                          .listaRisposte.map((risposta, index) => (
                            <p key={`pep-rapporti-${index}`}>
                              <Field
                                name={fields.RADIO_PEP_RAPPORTI_PERCHE.id}
                                className="with-gap"
                                type="radio"
                                id={`pep-rapporti-i-${index}`}
                                value={'' + risposta.idRisposta}
                                component={renderHtmlInput}
                              />
                              <label htmlFor={`pep-rapporti-i-${index}`}>{risposta.descRisposta}</label>
                            </p>
                          ))}
                      </div>
                    </Col>
                  </Row>
                )}
              <Row className="last-radio">
                <Col s={12}>
                  <Title>
                    {listaDomande.find(c => c.idDomanda === fields.RADIO_PEP_REDDITO.templateId).testoDomanda}
                  </Title>
                  <div className="radio-vertical">
                    {listaDomande
                      .find(d => d.idDomanda === fields.RADIO_PEP_REDDITO.templateId)
                      .listaRisposte.map((risposta, index) => (
                        <p key={`pep-reddito-${index}`}>
                          <Field
                            name={fields.RADIO_PEP_REDDITO.id}
                            className="with-gap"
                            type="radio"
                            id={`pep-reddito-i-${index}`}
                            value={'' + risposta.idRisposta}
                            component={renderHtmlInput}
                          />
                          <label htmlFor={`pep-reddito-i-${index}`}>{risposta.descRisposta}</label>
                        </p>
                      ))}
                  </div>
                </Col>
              </Row>
              <Field
                name={fields.YES_NO_PEP_PARTECIPAZIONI_SOC.id}
                label={
                  listaDomande.find(c => c.idDomanda === fields.YES_NO_PEP_PARTECIPAZIONI_SOC.templateId).testoDomanda
                }
                handlerFn={value => change(fields.YES_NO_PEP_PARTECIPAZIONI_SOC.id, value)}
                options={
                  listaDomande.find(c => c.idDomanda === fields.YES_NO_PEP_PARTECIPAZIONI_SOC.templateId).listaRisposte
                }
                component={renderYesNoQuestion}
              />
              <Field
                name={fields.YES_NO_PEP_PROCEDIMENTI.id}
                label={listaDomande.find(c => c.idDomanda === fields.YES_NO_PEP_PROCEDIMENTI.templateId).testoDomanda}
                handlerFn={value => change(fields.YES_NO_PEP_PROCEDIMENTI.id, value)}
                options={
                  listaDomande.find(c => c.idDomanda === fields.YES_NO_PEP_PROCEDIMENTI.templateId).listaRisposte
                }
                component={renderYesNoQuestion}
              />
              <Row>
                <Col s={12}>
                  <div className="inner-shadow-bottom-box" />
                </Col>
              </Row>
            </div>
          </div>
          <Field
            name={fields.YES_NO_CARICHE_POLITICHE.id}
            label={listaDomande.find(c => c.idDomanda === fields.YES_NO_CARICHE_POLITICHE.templateId).testoDomanda}
            handlerFn={value => change(fields.YES_NO_CARICHE_POLITICHE.id, value)}
            options={listaDomande.find(c => c.idDomanda === fields.YES_NO_CARICHE_POLITICHE.templateId).listaRisposte}
            component={renderYesNoQuestion}
          />
          <Field
            name={fields.YES_NO_CARICHE_EXTRA.id}
            label={listaDomande.find(c => c.idDomanda === fields.YES_NO_CARICHE_EXTRA.templateId).testoDomanda}
            handlerFn={value => change(fields.YES_NO_CARICHE_EXTRA.id, value)}
            options={listaDomande.find(c => c.idDomanda === fields.YES_NO_CARICHE_EXTRA.templateId).listaRisposte}
            component={renderYesNoQuestion}
          />
          <Field
            name={fields.YES_NO_CARICHE_PUBBLICHE.id}
            label={listaDomande.find(c => c.idDomanda === fields.YES_NO_CARICHE_PUBBLICHE.templateId).testoDomanda}
            handlerFn={value => change(fields.YES_NO_CARICHE_PUBBLICHE.id, value)}
            options={listaDomande.find(c => c.idDomanda === fields.YES_NO_CARICHE_PUBBLICHE.templateId).listaRisposte}
            component={renderYesNoQuestion}
          />
          <Field
            name={fields.YES_NO_FONDI_PUBBLICI.id}
            label={listaDomande.find(c => c.idDomanda === fields.YES_NO_FONDI_PUBBLICI.templateId).testoDomanda}
            handlerFn={value => change(fields.YES_NO_FONDI_PUBBLICI.id, value)}
            options={listaDomande.find(c => c.idDomanda === fields.YES_NO_FONDI_PUBBLICI.templateId).listaRisposte}
            component={renderYesNoQuestion}
          />
          <Row>
            <Col s={12}>
              <Title>Valutazione situazione finanziaria</Title>
            </Col>
          </Row>
          <Row className="m-top20">
            <Col
              s={12}
              className={
                this.state.fieldsWithErrors.includes(fields.PRINCIPALE_FONTE_REDDITO.templateId) ? 'fieldHasErrors' : ''
              }>
              <SurveyCheckbox
                formValues={formValues}
                template={listaDomande.find(d => d.idDomanda === fields.PRINCIPALE_FONTE_REDDITO.templateId)}
              />
            </Col>
          </Row>
          <SurveyHr hideHr />
          <Row>
            <Col
              s={12}
              className={
                this.state.fieldsWithErrors.includes(fields.FONTE_REDDITO_MEDIO.templateId) ? 'fieldHasErrors' : ''
              }>
              <SurveyRadio
                formValues={formValues}
                template={listaDomande.find(d => d.idDomanda === fields.FONTE_REDDITO_MEDIO.templateId)}
              />
            </Col>
            <Col
              s={12}
              className={
                this.state.fieldsWithErrors.includes(fields.REDDITO_FUTURO.templateId) ? 'fieldHasErrors' : ''
              }>
              <SurveyRadio
                formValues={formValues}
                template={listaDomande.find(d => d.idDomanda === fields.REDDITO_FUTURO.templateId)}
              />
            </Col>
            <Col
              s={12}
              className={
                this.state.fieldsWithErrors.includes(fields.PATRIMONIO_FINANZIARIO.templateId) ? 'fieldHasErrors' : ''
              }>
              <SurveyRadio
                formValues={formValues}
                template={listaDomande.find(d => d.idDomanda === fields.PATRIMONIO_FINANZIARIO.templateId)}
              />
            </Col>
          </Row>
          <SurveyHr hideHr />
          <Row>
            <Col s={12}>
              <h2 className="collapsible-section-title">Si o no?</h2>
              <Row
                className={
                  this.state.fieldsWithErrors.includes(fields.INVESTIMENTI_IMMOBILIARI.templateId)
                    ? 'fieldHasErrors'
                    : ''
                }>
                <YesNo
                  field={fields.INVESTIMENTI_IMMOBILIARI}
                  listaDomande={listaDomande}
                  formValues={formValues}
                  change={change}
                />
              </Row>
            </Col>
          </Row>
          <SurveyHr hideHr />
          <Row>
            <Col
              s={12}
              className={
                this.state.fieldsWithErrors.includes(fields.PATRIMONIO_IMMOBILIARE.templateId) ? 'fieldHasErrors' : ''
              }>
              <SurveyRadio
                formValues={formValues}
                template={listaDomande.find(d => d.idDomanda === fields.PATRIMONIO_IMMOBILIARE.templateId)}
              />
            </Col>
            <Col
              s={12}
              className={
                this.state.fieldsWithErrors.includes(fields.PATRIMONIO_FINANZIARIO_COMPLESSIVO.templateId)
                  ? 'fieldHasErrors'
                  : ''
              }>
              <SurveyRadio
                formValues={formValues}
                template={listaDomande.find(d => d.idDomanda === fields.PATRIMONIO_FINANZIARIO_COMPLESSIVO.templateId)}
              />
            </Col>
            <Col
              s={12}
              className={
                this.state.fieldsWithErrors.includes(fields.IMPEGNI_FINANZIARI_MENSILI.templateId)
                  ? 'fieldHasErrors'
                  : ''
              }>
              <SurveyRadio
                formValues={formValues}
                template={listaDomande.find(d => d.idDomanda === fields.IMPEGNI_FINANZIARI_MENSILI.templateId)}
              />
            </Col>
            <Col
              s={12}
              className={
                this.state.fieldsWithErrors.includes(fields.RISPARMIO_MENSILE.templateId) ? 'fieldHasErrors' : ''
              }>
              <SurveyRadio
                formValues={formValues}
                template={listaDomande.find(d => d.idDomanda === fields.RISPARMIO_MENSILE.templateId)}
              />
            </Col>
            <Col
              s={12}
              className={
                this.state.fieldsWithErrors.includes(fields.PERSONE_DIPENDENTI.templateId) ? 'fieldHasErrors' : ''
              }>
              <SurveyRadio
                formValues={formValues}
                template={listaDomande.find(d => d.idDomanda === fields.PERSONE_DIPENDENTI.templateId)}
              />
            </Col>
          </Row>
          <ButtonSubmit disabled={submitting || !isDirty || invalid || isFetching || isInvalidForm} />
        </form>
      </CollapsibleItem>
    )
  }
}

AntiRiciclaggio.propTypes = {
  onSave: PropTypes.func,
  domini: PropTypes.object,
  dominioRequest: PropTypes.object,
  template: PropTypes.object,
  handleSubmit: PropTypes.func,
  isDirty: PropTypes.bool,
  submitting: PropTypes.bool,
  invalid: PropTypes.bool,
  isFetching: PropTypes.bool,
  change: PropTypes.func,
  touch: PropTypes.func,
  initialize: PropTypes.func,
  formValues: PropTypes.object,
  fields: PropTypes.object,
  apiSuccess: PropTypes.bool,
  resetForm: PropTypes.func,
  submitSucceeded: PropTypes.bool,
  status: PropTypes.any,
  dataNascitaCF: PropTypes.any
}

AntiRiciclaggio.defaultProps = {
  formValues: {}
}

export default AntiRiciclaggio
