import moment from 'moment'

export const KEYS = {
  ENTER: 'Enter'
}

export const checkInvalidBirthdate = date => {
  return date.isAfter(moment().subtract(18, 'y')) || date.isBefore(moment().subtract(500, 'y'))
}

export const checkInvalidDocumentDate = date => {
  return date.isBefore(moment().subtract(500, 'y'))
}

// Default timer set to 60 seconds
export const timedButtonClick = (
  canClickKey,
  remainingTimeKey,
  timerIdKey,
  setStateFunction,
  initialCooldown = 60000
) => {
  const clickTimestamp = Date.now()

  setStateFunction(prevState => ({
    ...prevState,
    [canClickKey]: false,
    [remainingTimeKey]: initialCooldown,
    [timerIdKey]: setInterval(() => {
      setStateFunction(currentState => {
        const elapsedTime = Date.now() - clickTimestamp
        const remaining = Math.max(0, initialCooldown - elapsedTime)
        const shouldClearInterval = remaining === 0

        if (shouldClearInterval) {
          clearInterval(currentState[timerIdKey])
        }

        return {
          ...currentState,
          [remainingTimeKey]: remaining,
          [canClickKey]: shouldClearInterval ? true : currentState[canClickKey],
          [timerIdKey]: shouldClearInterval ? null : currentState[timerIdKey]
        }
      })
    }, 1000)
  }))
}
