import React, { Component } from 'react'
import { PropTypes } from 'prop-types'
import moment from 'moment'
import { Field } from 'redux-form'
import { Row, Col } from 'react-materialize'
import AccordionHeader from '../../../../components/AccordionHeader'
import { CollapsibleItem } from '../../../../components/Collapsible'
import InputText from '../../../../components/Inputs/Text'
// import dataSB from '../../../../static-data/data-servizio-base.json'
import { DOCUMENTO_IDENTITA_ALLEGATO_FISICA_RIFERIMENTO } from '../../../../store/modules/forms/constants'
import {
  mapDominiToOptions,
  mapDominiToAutocomplete,
  getValueDominioFromKey,
  getAutocompleteDefaultOption,
  parseDocumentsData,
  getDominio
} from '../../utils'
import {
  // renderInputImage,
  renderSelect,
  renderDatePicker,
  renderAutocomplete,
  renderCheckbox,
  renderCheckboxRadio
  // renderCheckboxRadio
} from '../../../../components/Forms/renderFields'
import ButtonSubmit from '../../../../components/Buttons/Submit'

// const documenti = dataSB.acquisizione.documento_identita

class DocumentiFisicaRiferimento extends Component {
  constructor(props) {
    super(props)

    this.state = { saved: false, test: true, docType: false, notChecked: true }

    this.toggleChange = this.toggleChange.bind(this)
    this.checkNazione = this.checkNazione.bind(this)
    this.checkItalia = this.checkItalia.bind(this)
    this.checkProvincia = this.checkProvincia.bind(this)
    this.isOutsideRangeRilascio = this.isOutsideRangeRilascio.bind(this)
    this.flagDocHandler = this.flagDocHandler.bind(this)
    this.submit = this.submit.bind(this)
  }

  componentDidMount() {
    // this.props.initialize(this.props.formValues)
    const {
      idPhysicalPersonReference,
      customer: { idIncarico },
      getDocumentIdPFActionCreator
    } = this.props
    if (idPhysicalPersonReference) getDocumentIdPFActionCreator(idPhysicalPersonReference, idIncarico)
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.isFetching &&
      !this.props.isFetching &&
      !this.props.apiError &&
      this.props.currentForm === DOCUMENTO_IDENTITA_ALLEGATO_FISICA_RIFERIMENTO
    ) {
      this.props.initialize(this.props.formValues)
      this.props.resetForm(DOCUMENTO_IDENTITA_ALLEGATO_FISICA_RIFERIMENTO)
      this.setState({ saved: true })
    }
    if (this.props.isDirty && this.state.saved) {
      this.setState({ saved: false })
    }
  }
  flagDocHandler(type) {
    this.setState({ docType: type })
  }

  checkNazione() {
    const {
      domini: { listaDomini },
      formValues,
      fields
    } = this.props
    return !!getValueDominioFromKey(
      listaDomini,
      fields.NAZIONE_RILASCIO.dominio,
      formValues[fields.NAZIONE_RILASCIO.id]
    )
  }

  checkItalia() {
    const { formValues, fields } = this.props
    return '' + formValues[fields.NAZIONE_RILASCIO.id] === fields.NAZIONE_RILASCIO.italiaId
  }

  checkProvincia(value) {
    const {
      domini: { listaDomini },
      fields
    } = this.props
    return !!getValueDominioFromKey(listaDomini, fields.PROVINCIA_RILASCIO.dominio, value)
  }

  isOutsideRangeRilascio(date) {
    const { dataNascitaCF } = this.props
    if (!dataNascitaCF) return date.isAfter(moment(), 'day')
    return date.isAfter(moment(), 'day') || date.isBefore(moment(dataNascitaCF), 'day')
  }

  submit(submittedValues) {
    const {
      submitHandler,
      currentInitial,
      fields,
      flagCustomerIsBlacklist,
      customer,
      formValues,
      checkHasProcuratore,
      isProspectPersonaGiuridica
    } = this.props
    const { values } = parseDocumentsData(fields, submittedValues, currentInitial)
    if (isProspectPersonaGiuridica) {
      values.isIdentityFrontUploaded = submittedValues.isIdentityFrontUploaded
      values.isIdentityBackUploaded = submittedValues.isIdentityBackUploaded

      values.documentType = submittedValues.documentType
      values.nationRelaseCode = submittedValues.nationRelaseCode
      values.provinceReleaseCode = submittedValues.provinceReleaseCode
      values.cityReleaseCode = submittedValues.cityReleaseCode
    }
    Object.assign(values, { flagCustomerIsBlacklist: !!flagCustomerIsBlacklist })

    submitHandler(values, null, DOCUMENTO_IDENTITA_ALLEGATO_FISICA_RIFERIMENTO)

    if (!isProspectPersonaGiuridica) {
      const checkProcuratorData = {
        customerId: customer.idCliente,
        hasProcuratore: formValues[fields.HA_PROCURATORE.id],
        idIncarico: customer.idIncarico
      }
      checkHasProcuratore(checkProcuratorData)
    }
    this.setState({ saved: true })

    // const { initialize, resetForm } = this.props
    // initialize(submittedValues || formValues)
    // initialize(formValues)
    // resetForm(DOCUMENTO_IDENTITA_ALLEGATO_FISICA_RIFERIMENTO)
    this.setState({ saved: true })
  }

  isCompiled(v) {
    const { formValues = {} } = this.props
    return typeof formValues[v.id] !== 'undefined' && formValues[v.id] !== null && formValues[v.id] !== ''
  }

  toggleChange() {
    this.setState({
      notChecked: !this.state.notChecked
    })
  }
  get canProceed() {
    const { notChecked } = this.state
    return !notChecked
  }

  flagCheckModuleHandler() {
    this.setState({ flagModuloFirmato: !this.state.flagModuloFirmato })
  }

  render() {
    const {
      handleSubmit,
      submitting,
      isDirty,
      anyTouched,
      invalid,
      status,
      domini,
      formValues,
      formErrors,
      change,
      touch,
      fields,
      isFetching
      // isProspectPersonaGiuridica
    } = this.props
    const {
      TIPO_DOCUMENTO,
      NUMERO_DOCUMENTO,
      NAZIONE_RILASCIO,
      PROVINCIA_RILASCIO,
      COMUNE_RILASCIO,
      COMUNE_ESTERO_RILASCIO,
      DATA_RILASCIO,
      DATA_SCADENZA,
      DOC_FRONTE,
      // DOC_FRONTE_THUMB,
      DOC_RETRO,
      // DOC_RETRO_THUMB,
      HA_PROCURATORE
      // DOC_CHECK
    } = fields

    // let bool = location.pathname.indexOf('-PG') >= 0
    return (
      <CollapsibleItem
        // TODO use Prospect context here
        header={'Persona fisica di riferimento'}
        itemKey={DOCUMENTO_IDENTITA_ALLEGATO_FISICA_RIFERIMENTO}
        icon="keyboard_arrow_down"
        labelInfo={AccordionHeader(isDirty, this.state.saved, status)}>
        <form onSubmit={handleSubmit(this.submit)} noValidate>
          <Row>
            <Field
              name={TIPO_DOCUMENTO.id}
              size={6}
              label={TIPO_DOCUMENTO.label}
              value={formValues[TIPO_DOCUMENTO.id]}
              change={change}
              touch={touch}
              options={mapDominiToOptions(getDominio(domini, TIPO_DOCUMENTO.dominio), true)}
              component={renderSelect}
            />
            <Field
              name={NUMERO_DOCUMENTO.id}
              s={6}
              maxLength={10}
              error={formErrors[NUMERO_DOCUMENTO.id]}
              label={NUMERO_DOCUMENTO.label}
              value={formValues[NUMERO_DOCUMENTO.id]}
              change={change}
              touch={touch}
              component={InputText}
            />
          </Row>
          <Row>
            <Col s={6}>
              <Field
                name={NAZIONE_RILASCIO.id}
                size={12}
                threshold={0}
                label={NAZIONE_RILASCIO.label}
                defaultValue={getAutocompleteDefaultOption(
                  domini.listaDomini,
                  NAZIONE_RILASCIO.dominio,
                  formValues[NAZIONE_RILASCIO.id]
                )}
                options={mapDominiToAutocomplete(getDominio(domini, NAZIONE_RILASCIO.dominio))}
                handleChange={(field, value) => {
                  change(field, value)
                  change(PROVINCIA_RILASCIO.id, '')
                  change(COMUNE_RILASCIO.id, '')
                  change(COMUNE_ESTERO_RILASCIO.id, '')
                }}
                component={renderAutocomplete}
                value={formValues[NAZIONE_RILASCIO.id]}
              />
            </Col>
            <Col s={6}>
              <Field
                name={PROVINCIA_RILASCIO.id}
                size={12}
                threshold={0}
                label={PROVINCIA_RILASCIO.label}
                defaultValue={getAutocompleteDefaultOption(
                  domini.listaDomini,
                  PROVINCIA_RILASCIO.dominio,
                  formValues[PROVINCIA_RILASCIO.id]
                )}
                options={mapDominiToAutocomplete(getDominio(domini, PROVINCIA_RILASCIO.dominio))}
                handleChange={(field, value) => {
                  change(field, value)
                  change(COMUNE_RILASCIO.id, '')
                  if (this.checkProvincia(value)) {
                    this.props.dominioCustomRequest(COMUNE_RILASCIO.dominio, PROVINCIA_RILASCIO.dominio, value, true)
                  }
                }}
                disabled={!this.checkItalia()}
                component={renderAutocomplete}
                value={formValues[PROVINCIA_RILASCIO.id]}
              />
            </Col>
          </Row>
          <Row>
            {this.checkItalia() && (
              <Col s={6}>
                <Field
                  name={COMUNE_RILASCIO.id}
                  size={12}
                  threshold={0}
                  label={COMUNE_RILASCIO.label}
                  disabled={!this.checkProvincia(formValues[PROVINCIA_RILASCIO.id])}
                  defaultValue={getAutocompleteDefaultOption(
                    domini.listaDomini,
                    COMUNE_RILASCIO.dominio,
                    formValues[COMUNE_RILASCIO.id]
                  )}
                  options={mapDominiToAutocomplete(getDominio(domini, COMUNE_RILASCIO.dominio))}
                  handleChange={(field, value) => change(field, value)}
                  component={renderAutocomplete}
                  value={formValues[COMUNE_RILASCIO.id]}
                />
              </Col>
            )}
            {!this.checkItalia() && (
              <Field
                name={COMUNE_ESTERO_RILASCIO.id}
                s={6}
                maxLength={50}
                error={formErrors[COMUNE_ESTERO_RILASCIO.id]}
                label={COMUNE_ESTERO_RILASCIO.label}
                value={formValues[COMUNE_ESTERO_RILASCIO.id]}
                disabled={!this.checkNazione()}
                change={change}
                touch={touch}
                component={InputText}
              />
            )}
          </Row>
          <Row>
            <Field
              name={DATA_RILASCIO.id}
              s={6}
              openDirection="up"
              label={DATA_RILASCIO.label}
              placeholder=""
              isDayBlocked={date => date.day() === 0}
              readOnly
              isOutsideRange={this.isOutsideRangeRilascio}
              defaultDate={formValues[DATA_RILASCIO.id]}
              onDateChange={date => change(DATA_RILASCIO.id, date.format())}
              component={renderDatePicker}
              value={formValues[DATA_RILASCIO.id]}
            />

            <Field
              name={DATA_SCADENZA.id}
              s={6}
              openDirection="up"
              anchorDirection="right"
              label={DATA_SCADENZA.label}
              placeholder=""
              readOnly
              isOutsideRange={date =>
                date.isBefore(moment().add(1, 'day'), 'day') ||
                date.isAfter(
                  moment()
                    .add(11, 'y')
                    .add(1, 'day'),
                  'day'
                )
              }
              defaultDate={formValues[DATA_SCADENZA.id]}
              onDateChange={date => change(DATA_SCADENZA.id, date.format())}
              component={renderDatePicker}
              value={formValues[DATA_SCADENZA.id]}
            />
          </Row>
          <Row className="m-top50">
            <Col s={4} offset="s1">
              <Field
                name={DOC_FRONTE.id}
                checked={formValues[DOC_FRONTE.id]}
                value={formValues[DOC_FRONTE.id]}
                label="Copia del Documento Identità fronte acquisito"
                onClick={() => this.flagCheckModuleHandler()}
                change={change}
                touch={touch}
                type="checkbox"
                component={renderCheckboxRadio}
              />
            </Col>
            <Col s={4} offset="s2">
              <Field
                name={DOC_RETRO.id}
                checked={formValues[DOC_RETRO.id]}
                value={formValues[DOC_RETRO.id]}
                label="Copia del Documento Identità retro acquisito"
                onClick={() => this.flagCheckModuleHandler()}
                change={change}
                touch={touch}
                type="checkbox"
                component={renderCheckboxRadio}
              />
            </Col>
          </Row>

          <Row>
            <Col s={6} className="m-top20">
              {this.props.url === `/consulenza-base/${this.props.idPratica}/acquisizione-documenti` && (
                <Field name={HA_PROCURATORE.id} label={HA_PROCURATORE.label} component={renderCheckbox} />
              )}
            </Col>
            <Col s={6}>
              <ButtonSubmit disabled={submitting || (!isDirty && !anyTouched) || invalid || isFetching} />
            </Col>
          </Row>
        </form>
      </CollapsibleItem>
    )
  }
}

DocumentiFisicaRiferimento.propTypes = {
  submitHandler: PropTypes.func,
  handleSubmit: PropTypes.func,
  submitting: PropTypes.bool,
  isDirty: PropTypes.bool,
  invalid: PropTypes.bool,
  isFetching: PropTypes.bool,
  initialize: PropTypes.func,
  change: PropTypes.func,
  touch: PropTypes.func,
  currentInitial: PropTypes.object,
  formValues: PropTypes.object,
  formErrors: PropTypes.object,
  domini: PropTypes.object,
  dominioCustomRequest: PropTypes.func,
  status: PropTypes.any,
  anyTouched: PropTypes.bool,
  dataNascitaCF: PropTypes.any,
  fields: PropTypes.object,
  flagCustomerIsBlacklist: PropTypes.any,
  url: PropTypes.string,
  idPratica: PropTypes.any,
  customer: PropTypes.object,
  idPhysicalPersonReference: PropTypes.string,
  checkHasProcuratore: PropTypes.func,
  isProspectPersonaGiuridica: PropTypes.bool,
  resetForm: PropTypes.func,
  getDocumentIdPFActionCreator: PropTypes.func,
  currentForm: PropTypes.object,
  apiError: PropTypes.object
}

DocumentiFisicaRiferimento.defaultProps = {
  formValues: {},
  formErrors: {}
}

export default DocumentiFisicaRiferimento
