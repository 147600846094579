import * as Forms from '../../store/modules/forms/constants'

export default {
  [Forms.APERTURA_INFO]: {
    REGIME: {
      id: 'idRegimeAppartenenza',
      defaultValue: '',
      isNotCorrispondenza: true
    },
    COMUNE_FIRMA: {
      id: 'codiceComuneFirma',
      label: 'Luogo sottoscrizione*',
      defaultValue: '',
      dominio: 'DOM_COMUNE_FIRMA',
      validazione: /^\d+$/,
      isNotCorrispondenza: true
    },
    FLAG_INDIRIZZO: {
      id: 'flagCorrispondenza',
      defaultValue: false,
      isNotCorrispondenza: true
    },
    NOME: {
      id: 'nome',
      label: 'Nome*',
      defaultValue: '',
      validazione: /^[a-zA-ZÀÁÈÉÌÍÒÓÙÚà-ž\s'’-]*$/g
    },
    COGNOME: {
      id: 'cognome',
      label: 'Cognome*',
      defaultValue: '',
      validazione: /^[a-zA-ZÀÁÈÉÌÍÒÓÙÚà-ž\s'’-]*$/g
    },
    INDIRIZZO: {
      id: 'indirizzo',
      label: 'Indirizzo*',
      defaultValue: '',
      daResidenza: true
    },
    // CIVICO: {
    //   id: 'civico',
    //   label: 'Civico',
    //   defaultValue: '',
    //   validazione: /^[1-9]{1}[0-9]{0,2}[a-zA-Z]{0,1}$/
    // },
    NAZIONE: {
      id: 'idNazione',
      label: 'Nazione*',
      defaultValue: '',
      dominio: 'DOM_NAZIONE_CONTATTI',
      dominioDep: null,
      italiaId: 'ITA',
      validazione: /^\d+$/,
      daResidenza: true
    },
    PROVINCIA: {
      id: 'idProvincia',
      label: 'Provincia*',
      defaultValue: '',
      dominio: 'DOM_PROVINCE_CONTATTI',
      dominioDep: null,
      validazione: /^\d+$/,
      daResidenza: true
    },
    COMUNE: {
      id: 'idComune',
      label: 'Comune*',
      defaultValue: '',
      dominio: 'DOM_COMUNE_CONTATTI',
      dominioDep: 'idProvincia',
      validazione: /^\d+$/,
      daResidenza: true
    },
    COMUNE_ESTERO: {
      id: 'comuneEstero',
      label: 'Comune*',
      defaultValue: '',
      daResidenza: true
    },
    CAP: {
      id: 'cap',
      label: 'CAP*',
      defaultValue: '',
      dominio: 'DOM_CAP_CONTATTI',
      dominioDep: 'idComune',
      validazione: /^\d+$/,
      daResidenza: true
    }
  },
  [Forms.APERTURA_INFO_GIURIDICHE]: {
    REGIME: {
      id: 'idRegimeAppartenenza',
      defaultValue: '',
      isNotCorrispondenza: true
    },
    COMUNE_FIRMA: {
      id: 'codiceComuneFirma',
      label: 'Luogo sottoscrizione*',
      defaultValue: '',
      dominio: 'DOM_COMUNE_FIRMA',
      validazione: /^[a-zA-Z0-9]*$/g,
      isNotCorrispondenza: true
    },
    FLAG_INDIRIZZO: {
      id: 'flagCorrispondenza',
      defaultValue: false,
      isNotCorrispondenza: true
    },
    NOME: {
      id: 'name',
      label: 'Nome*',
      defaultValue: '',
      validazione: /^[a-zA-ZÀÁÈÉÌÍÒÓÙÚà-ž\s'’-]*$/g,
      daResidenza: true
    },
    RAGIONE_SOCIALE_PG: {
      id: 'businessName',
      label: 'Cognome/Ragione sociale*',
      defaultValue: '',
      validazione: /^[a-zA-ZÀÁÈÉÌÍÒÓÙÚà-ž\s'’-]*$/g,
      daResidenza: true
    },
    SEDE_LEGALE_PG: {
      id: 'registeredOfficeAddress',
      label: 'Indirizzo*',
      defaultValue: '',
      daResidenza: true
    },
    NAZIONE_PG: {
      id: 'nation',
      label: 'Nazione*',
      defaultValue: '',
      dominio: 'DOM_NAZIONE_SEDE_LEGALE_PG',
      dominioDep: null,
      italiaId: 'ITA',
      validazione: /^[a-zA-Z0-9]*$/g,
      daResidenza: true
    },
    PROVINCIA_PG: {
      id: 'province',
      label: 'Provincia*',
      defaultValue: '',
      dominio: 'DOM_PROVINCE_SEDE_LEGALE_PG',
      dominioDep: null,
      validazione: /^[a-zA-Z0-9]*$/g,
      daResidenza: true
    },
    CITTA_PG: {
      id: 'city',
      label: 'Comune*',
      defaultValue: '',
      dominio: 'DOM_COMUNE_SEDE_LEGALE_PG',
      dominioDep: 'province',
      validazione: /^[a-zA-Z0-9]*$/g,
      daResidenza: true
    },
    CITTA_ESTERO_PG: {
      id: 'place',
      label: 'Comune*',
      defaultValue: '',
      daResidenza: true
    },
    CAP_PG: {
      id: 'zipCode',
      label: 'CAP*',
      defaultValue: '',
      dominio: 'DOM_CAP_SEDE_LEGALE_PG',
      dominioDep: 'idComune',
      validazione: /^[a-zA-Z0-9]*$/g,
      daResidenza: true
    }
  },
  [Forms.APERTURA_MAX]: {
    IMPORTO: {
      id: 'importo',
      label: 'Importo',
      defaultValue: ''
    },
    FLAG_BONIFICO: {
      id: 'flagBonifico',
      defaultValue: true,
      readOnly: true
    },
    ORIGINE: {
      id: 'idOrigineVersamento',
      label: 'Origine versamento',
      defaultValue: '',
      dominio: 'DOM_ORIGINE_VERSAMENTO'
    },
    FASCIA: {
      id: 'fasciaInvestimento',
      label: 'Fascia di investimento*',
      defaultValue: '',
      skip: true,
      loadFrom: true,
      dominio: 'DOM_ORIGINE_VERSAMENTO'
    },
    COMM_ADVISORY: {
      id: 'percComm',
      label: 'Commissione advisory',
      defaultValue: '',
      isPercComm: true,
      isRequired: true,
      loadFrom: true
    },
    TIPOLOGIA_CONTO: {
      id: 'idTipologiaConto',
      label: 'Conto',
      defaultValue: '1',
      isContoIndividualeExt: true
    },
    VALORE_INIZIALE_CONTO: {
      id: 'valoreLinea',
      label: 'Valore Linea',
      defaultValue: '0',
      isContoIndividuale: true
    },
    CONTROVALORE_GARANZIA: {
      id: 'controvaloreGaranzia',
      label: 'Valore',
      defaultValue: '0',
      isContoIndividuale: true
    },
    FLAG_INTESTATARIO_CONTO: {
      id: 'idBeneficiari',
      label: 'Mio/Nostro Favore*',
      defaultValue: '0',
      isContoIndividuale: true
    },
    ALTRE_RICHIESTE: {
      id: 'altreRichieste',
      label: 'Altre richieste',
      defaultValue: '',
      isContoIndividuale: true
    },
    NOMINATIVO: {
      id: 'nominativoBeneficario',
      label: 'Nome Cognome',
      defaultValue: '',
      isContoIndividuale: true
    },
    ID_CONTO: {
      id: 'idBancaConto',
      label: 'Nome Cognome',
      defaultValue: 'UBS',
      isContoIndividuale: true
    }
  },
  [Forms.APERTURA_MAX_FUND]: {
    ID_CONTO: {
      id: 'idBancaConto',
      label: 'Nome Cognome',
      defaultValue: 'BPM',
      isContoIndividuale: true
    },
    IMPORTO: {
      id: 'importo',
      label: 'Importo',
      defaultValue: ''
    },
    FLAG_BONIFICO: {
      id: 'flagBonifico',
      defaultValue: true,
      readOnly: true,
      isRequired: true
    },
    ORIGINE: {
      id: 'idOrigineVersamento',
      label: 'Origine versamento',
      defaultValue: '',
      dominio: 'DOM_ORIGINE_VERSAMENTO'
    },
    FASCIA: {
      id: 'fasciaInvestimento',
      defaultValue: '',
      skip: true,
      dominio: 'DOM_FASCIA'
    },
    COMM_ADVISORY: {
      id: 'percComm',
      label: 'Commissione advisory',
      defaultValue: '',
      isPercComm: true,
      codiceCommissione: 6,
      isRequired: true
    },
    TIPOLOGIA_CONTO: {
      id: 'idTipologiaConto',
      label: 'Conto',
      defaultValue: '1',
      isContoIndividualeExt: true
    }
  },
  [Forms.APERTURA_RTO_BPM]: {
    FLAG_BONIFICO: {
      id: 'flagBonifico',
      label: 'Bonifico',
      defaultValue: false,
      passFalse: true,
      isRequired: true
    },
    FLAG_ASSEGNO: {
      id: 'flagAssegno',
      label: 'Assegno bancario circolare non trasferibile',
      defaultValue: false,
      passFalse: true,
      isRequired: true
    },
    IMPORTO: {
      id: 'importo',
      label: 'Importo',
      defaultValue: ''
    },
    ORIGINE: {
      id: 'idOrigineVersamento',
      label: 'Origine versamento',
      defaultValue: '',
      dominio: 'DOM_ORIGINE_VERSAMENTO'
    },
    FLAG_TRANSFER: {
      id: 'flagTrasferimentoTitoli',
      label: 'Trasferimento titoli',
      defaultValue: false
    },
    ABI: {
      id: 'abi',
      label: 'ABI',
      defaultValue: '',
      isAssegno: true,
      validazione: /^\d{5}$/
    },
    CAB: {
      id: 'cab',
      label: 'CAB',
      defaultValue: '',
      isAssegno: true,
      validazione: /^\d{5}$/
    },
    NUM_CONTO: {
      id: 'numConto',
      label: 'Num. conto',
      defaultValue: '',
      isAssegno: true,
      validazione: /^\d{8}$/
    },
    NUM_ASSEGNO: {
      id: 'numAssegno',
      label: 'Num. assegno',
      defaultValue: '',
      isAssegno: true,
      validazione: /^\d{13}$/
    },
    IMG: {
      id: 'imageFull',
      label: 'Foto assegno*',
      defaultValue: null,
      isAssegno: true
    },
    IMG_THUMB: {
      id: 'imageThumbnail',
      defaultValue: null,
      isAssegno: true
    },
    COMM_AZIONI: {
      id: 'percComm',
      label: 'Commissione titoli azionari e diritti di opzione',
      defaultValue: '',
      dominio: 'DOM_TITOLO_COMMISSIONE',
      isPercComm: true,
      codiceCommissione: 4,
      isRequired: true
    },
    COMM_OBBLIGAZIONI: {
      id: 'percComm_2',
      label: 'Commissione titoli obbligazionari e titoli di stato',
      defaultValue: '',
      dominio: 'DOM_TITOLO_COMMISSIONE',
      isPercComm: true,
      codiceCommissione: 5,
      isRequired: true
    },
    TIPOLOGIA_CONTO: {
      id: 'idTipologiaConto',
      label: 'Conto',
      defaultValue: '1',
      isContoIndividualeExt: true
    },
    VALORE_INIZIALE_CONTO: {
      id: 'valoreLinea',
      label: 'Valore Linea',
      defaultValue: '0',
      isContoIndividuale: true
    },
    CONTROVALORE_GARANZIA: {
      id: 'controvaloreGaranzia',
      label: 'Valore',
      defaultValue: '0',
      isContoIndividuale: true
    },
    FLAG_INTESTATARIO_CONTO: {
      id: 'idBeneficiari',
      label: 'Mio/Nostro Favore',
      defaultValue: '0',
      isContoIndividuale: true
    },
    ALTRE_RICHIESTE: {
      id: 'altreRichieste',
      label: 'Altre richieste',
      defaultValue: '',
      isContoIndividuale: true
    },
    NOMINATIVO: {
      id: 'nominativoBeneficario',
      label: 'Nome Cognome',
      defaultValue: '',
      isContoIndividuale: true
    },
    ID_CONTO: {
      id: 'idBancaConto',
      label: 'Nome Cognome',
      defaultValue: 'UBS',
      isContoIndividuale: true
    }
  },

  [Forms.APERTURA_RTO_UBS]: {
    FLAG_BONIFICO: {
      id: 'flagBonifico',
      label: 'Bonifico',
      defaultValue: false,
      passFalse: true,
      isRequired: true
    },
    IMPORTO: {
      id: 'importo',
      label: 'Importo',
      defaultValue: ''
    },
    ORIGINE: {
      id: 'idOrigineVersamento',
      label: 'Origine versamento',
      defaultValue: '',
      dominio: 'DOM_ORIGINE_VERSAMENTO'
    },
    FLAG_TRANSFER: {
      id: 'flagTrasferimentoTitoli',
      label: 'Trasferimento titoli',
      defaultValue: false
    },
    COMM_AZIONI: {
      id: 'percComm',
      label: 'Commissione titoli azionari e diritti di opzione',
      defaultValue: '',
      dominio: 'DOM_TITOLO_COMMISSIONE',
      isPercComm: true,
      codiceCommissione: 4,
      isRequired: true
    },
    COMM_OBBLIGAZIONI: {
      id: 'percComm_2',
      label: 'Commissione titoli obbligazionari e titoli di stato',
      defaultValue: '',
      dominio: 'DOM_TITOLO_COMMISSIONE',
      isPercComm: true,
      codiceCommissione: 5,
      isRequired: true
    },
    TIPOLOGIA_CONTO: {
      id: 'idTipologiaConto',
      label: 'Conto',
      defaultValue: '1',
      isContoIndividualeExt: true
    },
    VALORE_INIZIALE_CONTO: {
      id: 'valoreLinea',
      label: 'Valore Linea',
      defaultValue: '0',
      isContoIndividuale: true
    },
    CONTROVALORE_GARANZIA: {
      id: 'controvaloreGaranzia',
      label: 'Valore',
      defaultValue: '0',
      isContoIndividuale: true
    },
    FLAG_INTESTATARIO_CONTO: {
      id: 'idBeneficiari',
      label: 'Mio/Nostro Favore',
      defaultValue: '0',
      isContoIndividuale: true
    },
    ALTRE_RICHIESTE: {
      id: 'altreRichieste',
      label: 'Altre richieste',
      defaultValue: '',
      isContoIndividuale: true
    },
    NOMINATIVO: {
      id: 'nominativoBeneficario',
      label: 'Nome Cognome',
      defaultValue: '',
      isContoIndividuale: true
    },
    ID_CONTO: {
      id: 'idBancaConto',
      label: 'Nome Cognome',
      defaultValue: 'UBS',
      isContoIndividuale: true
    }
  },
  [Forms.APERTURA_RTO_IMI]: {
    ID_CONTO: {
      id: 'idBancaConto',
      label: 'Nome Cognome',
      defaultValue: 'UBS',
      isContoIndividuale: true
    },
    TIPOLOGIA_CONTO: {
      id: 'idTipologiaConto',
      label: 'Conto',
      defaultValue: '1',
      isContoIndividualeExt: true
    },
    FLAG_BONIFICO: {
      id: 'flagBonifico',
      label: 'Bonifico',
      defaultValue: false,
      passFalse: true,
      isRequired: true
    },
    IMPORTO: {
      id: 'importo',
      label: 'Importo',
      defaultValue: ''
    },
    ORIGINE: {
      id: 'idOrigineVersamento',
      label: 'Origine versamento',
      defaultValue: '',
      dominio: 'DOM_ORIGINE_VERSAMENTO'
    },
    FLAG_TRANSFER: {
      id: 'flagTrasferimentoTitoli',
      label: 'Trasferimento titoli',
      defaultValue: false
    },
    COMM_AZIONI: {
      id: 'percComm',
      label: 'Commissione titoli azionari e diritti di opzione',
      defaultValue: '',
      dominio: 'DOM_TITOLO_COMMISSIONE',
      isPercComm: true,
      codiceCommissione: 4,
      isRequired: true
    },
    COMM_OBBLIGAZIONI: {
      id: 'percComm_2',
      label: 'Commissione titoli obbligazionari e titoli di stato',
      defaultValue: '',
      dominio: 'DOM_TITOLO_COMMISSIONE',
      isPercComm: true,
      codiceCommissione: 5,
      isRequired: true
    }
  },
  [Forms.APERTURA_CONTO_DEPOSITO_LIQUIDITA]: {
    FLAG_BONIFICO: {
      id: 'flagBonifico',
      label: 'Bonifico',
      defaultValue: false,
      passFalse: true,
      isRequired: true
    },
    FLAG_ASSEGNO: {
      id: 'flagAssegno',
      label: 'Assegno bancario circolare non trasferibile',
      defaultValue: false,
      passFalse: true,
      isRequired: true
    },
    IMPORTO: {
      id: 'importo',
      label: 'Importo',
      defaultValue: ''
    },
    ORIGINE: {
      id: 'idOrigineVersamento',
      label: 'Origine versamento',
      defaultValue: '',
      dominio: 'DOM_ORIGINE_VERSAMENTO'
    },
    FLAG_TRANSFER: {
      id: 'flagTrasferimentoTitoli',
      label: 'Trasferimento titoli',
      defaultValue: false
    },
    ABI: {
      id: 'abi',
      label: 'ABI',
      defaultValue: '',
      isAssegno: true,
      validazione: /^\d{5}$/
    },
    CAB: {
      id: 'cab',
      label: 'CAB',
      defaultValue: '',
      isAssegno: true,
      validazione: /^\d{5}$/
    },
    NUM_CONTO: {
      id: 'numConto',
      label: 'Num. conto',
      defaultValue: '',
      isAssegno: true,
      validazione: /^\d{8}$/
    },
    NUM_ASSEGNO: {
      id: 'numAssegno',
      label: 'Num. assegno',
      defaultValue: '',
      isAssegno: true,
      validazione: /^\d{13}$/
    },
    IMG: {
      id: 'imageFull',
      label: 'Foto assegno*',
      defaultValue: null,
      isAssegno: true
    },
    IMG_THUMB: {
      id: 'imageThumbnail',
      defaultValue: null,
      isAssegno: true
    },
    COSTO_SERVIZIO: {
      id: 'percComm',
      label: 'Costo del servizio',
      defaultValue: '0',
      isValuta: true,
      isRequired: true,
      loadFrom: true,
      codiceCommissione: 6
    }

    // TIPOLOGIA_CONTO: {
    //   id: 'idTipologiaConto',
    //   label: 'Conto',
    //   defaultValue: '1',
    //   isContoIndividualeExt: true
    // },
    // VALORE_INIZIALE_CONTO: {
    //   id: 'valoreLinea',
    //   label: 'Valore Linea*',
    //   defaultValue: '0',
    //   isContoIndividuale: true
    // },
    // CONTROVALORE_GARANZIA: {
    //   id: 'controvaloreGaranzia',
    //   label: 'Valore*',
    //   defaultValue: '0',
    //   isContoIndividuale: true
    // },
    // FLAG_INTESTATARIO_CONTO: {
    //   id: 'idBeneficiari',
    //   label: 'Mio/Nostro Favore*',
    //   defaultValue: '0',
    //   isContoIndividuale: true
    // },
    // ALTRE_RICHIESTE: {
    //   id: 'altreRichieste',
    //   label: 'Altre richieste',
    //   defaultValue: '',
    //   isContoIndividuale: true
    // },
    // NOMINATIVO: {
    //   id: 'nominativoBeneficario',
    //   label: 'Nome Cognome*',
    //   defaultValue: '',
    //   isContoIndividuale: true
    // },
    // ID_CONTO: {
    //   id: 'idBancaConto',
    //   label: 'Nome Cognome',
    //   defaultValue: 'UBS',
    //   isContoIndividuale: true
    // }
  },
  [Forms.APERTURA_PIR]: {
    NAZIONE_NASCITA: {
      id: 'codNazioneNascita',
      label: 'Nazione di nascita',
      defaultValue: '',
      dominio: 'DOM_NAZIONE_NASCITA',
      dominioDep: null,
      italiaId: 'ITA',
      statiUnitiId: 'USA'
    },
    PROVINCIA_NASCITA: {
      id: 'codProvinciaNascita',
      label: 'Provincia di nascita',
      defaultValue: '',
      dominio: 'DOM_PROVINCE_NASCITA',
      dominioDep: null
    },
    COMUNE_NASCITA: {
      id: 'codComuneNascita',
      label: 'Comune di nascita',
      defaultValue: '',
      dominio: 'DOM_COMUNE_NASCITA',
      dominioDep: 'codProvinciaNascita'
    },
    DATA_NASCITA: {
      id: 'dataNascita',
      label: 'Data di nascita',
      defaultValue: ''
    },
    CITTADINANZA: {
      id: 'codCittadinanza',
      label: 'Cittadinanza',
      defaultValue: '',
      dominio: 'DOM_CITTADINANZE'
    },
    INDIRIZZO_RESIDENZA: {
      id: 'indirizzoResidenza',
      label: 'Indirizzo di residenza',
      defaultValue: ''
    },
    NAZIONE_RESIDENZA: {
      id: 'codNazioneResidenza',
      label: 'Nazione di residenza',
      defaultValue: 'ITA',
      dominio: 'DOM_NAZIONE_RESIDENZA',
      dominioDep: null,
      italiaId: 'ITA'
    },
    PROVINCIA_RESIDENZA: {
      id: 'codProvinciaResidenza',
      label: 'Provincia di residenza',
      defaultValue: '',
      dominio: 'DOM_PROVINCE_RESIDENZA_PROC',
      dominioDep: null
    },
    COMUNE_RESIDENZA: {
      id: 'codComuneResidenza',
      label: 'Comune di residenza',
      defaultValue: '',
      dominio: 'DOM_COMUNE_RESIDENZA',
      dominioDep: 'codProvinciaResidenza'
    },
    TIPO_DOCUMENTO: {
      id: 'tipoDocumento',
      label: 'Tipologia documento',
      defaultValue: 'CI',
      dominio: 'DOM_TIPO_DOCUMENTO',
      dominioDep: null
    },
    NUMERO_DOCUMENTO: {
      id: 'numeroDocumenti',
      label: 'Numero documento',
      defaultValue: ''
    },
    NAZIONE_RILASCIO: {
      id: 'nazioneRilascio',
      label: 'Nazione di rilascio',
      defaultValue: 'ITA',
      dominio: 'DOM_NAZIONE_RILASCIO',
      dominioDep: null,
      italiaId: 'ITA'
    },
    PROVINCIA_RILASCIO: {
      id: 'provinciaRilascio',
      label: 'Provincia di rilascio',
      defaultValue: '',
      dominio: 'DOM_PROVINCE_RILASCIO',
      dominioDep: null
    },
    COMUNE_RILASCIO: {
      id: 'comuneRilascio',
      label: 'Comune di rilascio',
      defaultValue: '',
      dominio: 'DOM_COMUNE_RILASCIO',
      dominioDep: 'provinciaRilascio'
    },
    DATA_RILASCIO: {
      id: 'dataRilascio',
      label: 'Data rilascio',
      defaultValue: ''
    },
    DATA_SCADENZA: {
      id: 'dataScadenza',
      label: 'Data scadenza',
      defaultValue: ''
    }
  },
  [Forms.APERTURA_MAX_BNP]: {
    IMPORTO: {
      id: 'importo',
      label: 'Importo',
      defaultValue: ''
    },
    FLAG_BONIFICO: {
      id: 'flagBonifico',
      defaultValue: true,
      readOnly: true
    },
    ORIGINE: {
      id: 'idOrigineVersamento',
      label: 'Origine versamento',
      defaultValue: '',
      dominio: 'DOM_ORIGINE_VERSAMENTO'
    },
    FASCIA: {
      id: 'fasciaInvestimento',
      label: 'Fascia di investimento*',
      defaultValue: '',
      skip: true,
      loadFrom: true,
      dominio: 'DOM_ORIGINE_VERSAMENTO'
    },
    COMM_ADVISORY: {
      id: 'percComm',
      label: 'Commissione advisory',
      defaultValue: '',
      isPercComm: true,
      isRequired: true,
      loadFrom: true
    },
    TIPOLOGIA_CONTO: {
      id: 'idTipologiaConto',
      label: 'Conto',
      defaultValue: '1',
      isContoIndividualeExt: true
    },
    VALORE_INIZIALE_CONTO: {
      id: 'valoreLinea',
      label: 'Valore Linea',
      defaultValue: '0',
      isContoIndividuale: true
    },
    CONTROVALORE_GARANZIA: {
      id: 'controvaloreGaranzia',
      label: 'Valore',
      defaultValue: '0',
      isContoIndividuale: true
    },
    FLAG_INTESTATARIO_CONTO: {
      id: 'idBeneficiari',
      label: 'Mio/Nostro Favore*',
      defaultValue: '0',
      isContoIndividuale: true
    },
    ALTRE_RICHIESTE: {
      id: 'altreRichieste',
      label: 'Altre richieste',
      defaultValue: '',
      isContoIndividuale: true
    },
    NOMINATIVO: {
      id: 'nominativoBeneficario',
      label: 'Nome Cognome',
      defaultValue: '',
      isContoIndividuale: true
    },
    ID_CONTO: {
      id: 'idBancaConto',
      label: 'Nome Cognome',
      defaultValue: 'UBS',
      isContoIndividuale: true
    }
  },
  [Forms.APERTURA_MAX_FUND_BNP]: {
    ID_CONTO: {
      id: 'idBancaConto',
      label: 'Nome Cognome',
      defaultValue: 'BPM',
      isContoIndividuale: true
    },
    IMPORTO: {
      id: 'importo',
      label: 'Importo',
      defaultValue: ''
    },
    FLAG_BONIFICO: {
      id: 'flagBonifico',
      defaultValue: true,
      readOnly: true,
      isRequired: true
    },
    ORIGINE: {
      id: 'idOrigineVersamento',
      label: 'Origine versamento',
      defaultValue: '',
      dominio: 'DOM_ORIGINE_VERSAMENTO'
    },
    FASCIA: {
      id: 'fasciaInvestimento',
      defaultValue: '',
      skip: true,
      dominio: 'DOM_FASCIA'
    },
    COMM_ADVISORY: {
      id: 'percComm',
      label: 'Commissione advisory',
      defaultValue: '',
      isPercComm: true,
      codiceCommissione: 6,
      isRequired: true
    },
    TIPOLOGIA_CONTO: {
      id: 'idTipologiaConto',
      label: 'Conto',
      defaultValue: '1',
      isContoIndividualeExt: true
    }
  }
}
