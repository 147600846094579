import React from 'react'
import * as forms from '../../../store/modules/forms/constants'

export const tipologiaContoDetails = [
  { descTipoServizio: 'MAX', tipologia: ['Individuale'] }, // UBS
  { descTipoServizio: 'FUND', tipologia: ['Omnibus'] }, // UBS
  { descTipoServizio: 'BPM', tipologia: ['Omnibus', 'Individuale'] }, // BPM
  { descTipoServizio: 'UBS', tipologia: ['Omnibus', 'Individuale'] }, // UBS
  { descTipoServizio: 'IMI', tipologia: ['Omnibus'] }, // UBS
  { descTipoServizio: 'CONTO_DEPOSITO', tipologia: ['Omnibus'] }, // UBS
  { descTipoServizio: 'MAX_BNP', tipologia: ['Omnibus'] }, // BNP
  { descTipoServizio: 'FUND_BNP', tipologia: ['Omnibus'] } // BNP
]
export const Forms = [
  forms.APERTURA_INFO,
  forms.APERTURA_MAX,
  forms.APERTURA_MAX_FUND,
  forms.APERTURA_RTO_BPM,
  forms.APERTURA_RTO_UBS,
  forms.APERTURA_RTO_IMI,
  forms.APERTURA_CONTO_DEPOSITO_LIQUIDITA,
  forms.APERTURA_PIR,
  forms.APERTURA_MAX_BNP,
  forms.APERTURA_MAX_FUND_BNP
]

export const FormsGiuridiche = [
  forms.APERTURA_INFO_GIURIDICHE,
  forms.APERTURA_MAX,
  forms.APERTURA_MAX_FUND,
  forms.APERTURA_RTO_BPM,
  forms.APERTURA_RTO_UBS,
  forms.APERTURA_RTO_IMI,
  forms.APERTURA_CONTO_DEPOSITO_LIQUIDITA,
  forms.APERTURA_PIR,
  forms.APERTURA_MAX_BNP,
  forms.APERTURA_MAX_FUND_BNP
]

const mapFormIdServizio = {
  [forms.APERTURA_MAX]: 1,
  [forms.APERTURA_MAX_FUND]: 2,
  [forms.APERTURA_RTO_BPM]: 3,
  [forms.APERTURA_RTO_UBS]: 4,
  [forms.APERTURA_RTO_IMI]: 7,
  [forms.APERTURA_CONTO_DEPOSITO_LIQUIDITA]: 9,
  [forms.APERTURA_PIR]: 10,
  [forms.APERTURA_MAX_BNP]: 12,
  [forms.APERTURA_MAX_FUND_BNP]: 13
}

const mapIdServizioToForm = {
  1: forms.APERTURA_MAX,
  2: forms.APERTURA_MAX_FUND,
  3: forms.APERTURA_RTO_BPM,
  4: forms.APERTURA_RTO_UBS,
  7: forms.APERTURA_RTO_IMI,
  9: forms.APERTURA_CONTO_DEPOSITO_LIQUIDITA,
  10: forms.APERTURA_PIR,
  12: forms.APERTURA_MAX_BNP,
  13: forms.APERTURA_MAX_FUND_BNP
}

export const getFormFromIdServizio = idServizio => mapIdServizioToForm[idServizio]

export const getIdServizio = form => mapFormIdServizio[form]

export const createDefaultPayload = (fields = {}, percComm = [], serviceData) => {
  return Object.keys(fields).reduce(
    (res, cur) => {
      const field = fields[cur]
      if (field.isRequired) {
        if (field.isPercComm) {
          return {
            ...res,
            percComm: [
              ...(res.percComm || []),
              {
                chiave: field.codiceCommissione,
                valore: (percComm.find(p => p.codiceCommissione === field.codiceCommissione) || {}).percentualeDefault
              }
            ]
          }
        }
        if (field.isValuta) {
          return {
            ...res,
            percComm: [
              ...(res.percComm || []),
              {
                chiave: field.codiceCommissione,
                valore: (percComm.find(p => p.codiceCommissione === field.codiceCommissione) || {}).percentualeDefault
              }
            ]
          }
        }
        return {
          ...res,
          pagamento: {
            ...res.pagamento,
            [field.id]: field.defaultValue
          }
        }
      }
      return res
    },
    {
      idIncarico: serviceData.idIncarico,
      idTipoServizio: serviceData.idTipoServizio,
      pagamento: {}
    }
  )
}

export const createPayload = (fields, values, serviceData) => {
  return Object.keys(fields).reduce(
    (res, cur) => {
      const field = fields[cur]
      const valore = values[field.id]
      if (field.readOnly) {
        return {
          ...res,
          pagamento: {
            ...res.pagamento,
            [field.id]: true
          }
        }
      }
      if (field.isContoIndividuale) {
        return {
          ...res,
          contoIndividuale: {
            ...res.contoIndividuale,
            [field.id]: valore
          }
        }
      }
      if (field.isContoIndividualeExt) {
        return {
          ...res,
          [field.id]: valore
        }
      }

      if ((!valore && !field.passFalse) || field.skip) {
        return res
      }
      if (field.isPercComm && !field.isValuta) {
        const chiave = field.codiceCommissione && !field.loadFrom ? field.codiceCommissione : values[fields.FASCIA.id]
        if (!chiave) return res
        return {
          ...res,
          percComm: [...(res.percComm || []), { chiave, valore }]
        }
      }

      if (field.isValuta) {
        const chiave = field.codiceCommissione
        if (!chiave) return res
        return {
          ...res,
          percComm: [...(res.percComm || []), { chiave, valore }]
        }
      }
      if (field.isAssegno) {
        return {
          ...res,
          pagamento: {
            ...res.pagamento,
            dettagliAssegno: {
              ...(res.pagamento || {}).dettagliAssegno,
              [field.id]: valore
            }
          }
        }
      }
      return {
        ...res,
        pagamento: {
          ...res.pagamento,
          [field.id]: field.dominio ? { codiceDominio: field.dominio, chiave: valore } : valore
        }
      }
    },
    {
      idIncarico: serviceData.idIncarico,
      idTipoServizio: serviceData.idTipoServizio,
      idServizio: serviceData.idServizio
    }
  )
}

export const loadServiceData = (
  { percComm, pagamento, contoIndividuale, idTipologiaConto },
  fields,
  percComms = []
) => {
  return Object.keys(fields).reduce((res, cur) => {
    const field = fields[cur]
    let percCommEl = null
    if (field.skip) {
      if (field.loadFrom && Array.isArray(percComm) && percComm.length > 0) {
        return {
          ...res,
          [field.id]: '' + percComm[0].chiave
        }
      }
      return res
    }
    if (field.isPercComm) {
      if (!Array.isArray(percComm) || percComm.length === 0) {
        percCommEl = (percComms.find(p => p.codiceCommissione === field.codiceCommissione) || {}).percentualeDefault
      } else {
        if (field.loadFrom) {
          percCommEl = percComm[0].valore
        } else {
          percCommEl = (percComm.find(p => parseInt(p.chiave) === field.codiceCommissione) || {}).valore
        }
      }
      return {
        ...res,
        [field.id]: '' + parseFloat(percCommEl).toFixed(4)
      }
    }
    if (field.isValuta) {
      if (!Array.isArray(percComm) || percComm.length === 0) {
        percCommEl = (percComms.find(p => p.codiceCommissione === field.codiceCommissione) || {}).percentualeDefault
      } else {
        if (field.loadFrom) {
          percCommEl =
            percComm[0].valore !== 0
              ? percComm[0].valore
              : (percComms.find(p => p.codiceCommissione === field.codiceCommissione) || {}).percentualeDefault
        } else {
          percCommEl = (percComm.find(p => parseInt(p.chiave) === field.codiceCommissione) || {}).valore
        }
      }
      return {
        ...res,
        [field.id]: '' + parseFloat(percCommEl)
      }
    }
    if (field.isContoIndividuale) {
      return {
        ...res,
        [field.id]: contoIndividuale[field.id]
      }
    }
    if (field.isContoIndividualeExt) {
      return {
        ...res,
        [field.id]: idTipologiaConto
      }
    }
    if (!pagamento) return res
    if (field.dominio) {
      return {
        ...res,
        [field.id]: (pagamento[field.id] || {}).chiave || field.defaultValue
      }
    }
    if (field.isAssegno) {
      const { dettagliAssegno } = pagamento
      if (!dettagliAssegno) return res
      return {
        ...res,
        [field.id]: dettagliAssegno[field.id] || field.defaultValue
      }
    }
    return {
      ...res,
      [field.id]: pagamento[field.id] || field.defaultValue
    }
  }, {})
}

export const mapCommissioneToOption = percentualiCommissionali => {
  return percentualiCommissionali.reduce(
    (res, cur) => {
      return [
        ...res,
        <option key={`${cur.codiceTipoServizio}_${cur.codiceCommissione}`} value={cur.codiceCommissione}>
          {cur.descrizioneCommissione}
        </option>
      ]
    },
    [
      <option key={-1} value="" disabled>
        {'-'}
      </option>
    ]
  )
}
