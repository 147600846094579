import { connect } from 'react-redux'
import { reduxForm, getFormValues, getFormSyncErrors, isDirty, getFormInitialValues } from 'redux-form'
import { DOCUMENTO_IDENTITA_ALLEGATO_FISICA_RIFERIMENTO } from '../../../../store/modules/forms/constants'
import allFields from '../../fields'
import { loadDefaultFields, loadDocumentsData, notBelongToDominio, getInitialValues } from '../../utils'
import DocumentiFisicaRiferimento from './DocumentiFisicaRiferimentoComponent'
import { bindActionCreators } from 'redux'

import { getDocumentIdPFActionCreator } from '../../../../store/modules/personaGiuridica/client/actions'

const fields = allFields[0][DOCUMENTO_IDENTITA_ALLEGATO_FISICA_RIFERIMENTO]
const defaultFields = loadDefaultFields(fields)

const validate = (values, props = {}) => {
  const errors = {}
  const { domini = {} } = props
  const { NAZIONE_RILASCIO, PROVINCIA_RILASCIO, COMUNE_RILASCIO, NUMERO_DOCUMENTO } = fields
  if (
    !!values[NAZIONE_RILASCIO.id] &&
    notBelongToDominio(domini, NAZIONE_RILASCIO.dominio, values[NAZIONE_RILASCIO.id])
  ) {
    errors[NAZIONE_RILASCIO.id] = 'Nazione non riconosciuta'
  }
  if (
    !!values[PROVINCIA_RILASCIO.id] &&
    notBelongToDominio(domini, PROVINCIA_RILASCIO.dominio, values[PROVINCIA_RILASCIO.id])
  ) {
    errors[PROVINCIA_RILASCIO.id] = 'Provincia non riconosciuta'
  }
  if (
    !!values[PROVINCIA_RILASCIO.id] &&
    !!values[COMUNE_RILASCIO.id] &&
    notBelongToDominio(domini, COMUNE_RILASCIO.dominio, values[COMUNE_RILASCIO.id])
  ) {
    errors[COMUNE_RILASCIO.id] = 'Comune non riconosciuto'
  }

  if (
    !!values[NUMERO_DOCUMENTO.id] &&
    !new RegExp(NUMERO_DOCUMENTO.validazione.default).test(values[NUMERO_DOCUMENTO.id])
  ) {
    errors[NUMERO_DOCUMENTO.id] = 'Sono ammessi solo caratteri alfanumerici'
  }

  return errors
}

const loadInitialValues = state => {
  return {
    ...defaultFields,
    ...getInitialValues(fields, state.anagraficaPersonaGiuridicaCliente.docIDPF),
    [fields.DOC_FRONTE.id]: state.anagraficaPersonaGiuridicaCliente.docIDPF.isIdentityFrontUploaded,
    [fields.DOC_RETRO.id]: state.anagraficaPersonaGiuridicaCliente.docIDPF.isIdentityBackUploaded
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getDocumentIdPFActionCreator
    },
    dispatch
  )

const mapStateToProps = (state, ownProps) => {
  let commonStateToProps = {
    formValues: getFormValues(DOCUMENTO_IDENTITA_ALLEGATO_FISICA_RIFERIMENTO)(state),
    formErrors: getFormSyncErrors(DOCUMENTO_IDENTITA_ALLEGATO_FISICA_RIFERIMENTO)(state),
    dataNascitaCF: state.customers.dataRegistry.dataNascita || state.customers.customer.dataNascita,
    currentInitial: getFormInitialValues(DOCUMENTO_IDENTITA_ALLEGATO_FISICA_RIFERIMENTO)(state),
    currentForm: state.anagraficaPersonaGiuridicaCliente.currentForm,
    fields,
    isDirty: isDirty(DOCUMENTO_IDENTITA_ALLEGATO_FISICA_RIFERIMENTO)(state),
    isFetching: state.anagraficaPersonaGiuridicaCliente.postDocIDPGRequest.fetching,
    apiError: state.anagraficaPersonaGiuridicaCliente.postDocIDPGRequest.error
  }
  if (ownProps.isProspectPersonaGiuridica) {
    commonStateToProps = {
      ...commonStateToProps,
      initialValues: loadInitialValues(state)

      // initialValues: getInitialValues(fields, state.customers.documentsDataProcuratore)
    }
  } else {
    commonStateToProps = {
      ...commonStateToProps,
      initialValues: {
        ...defaultFields,
        ...loadDocumentsData(fields, state.customers.documentsData),
        [fields.HA_PROCURATORE.id]: state.customers.summaryData.idProcurator !== null
      },
      flagCustomerIsBlacklist:
        state.customers.documentsData.flagCustomerIsBlacklist === false ||
        state.customers.documentsData.flagCustomerIsBlacklist === true
          ? state.customers.documentsData.flagCustomerIsBlacklist
          : state.customers.flagCustomerIsBlacklist
    }
  }
  return commonStateToProps
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form: DOCUMENTO_IDENTITA_ALLEGATO_FISICA_RIFERIMENTO,
    validate,
    enableReinitialize: true,
    shouldError: () => isDirty(DOCUMENTO_IDENTITA_ALLEGATO_FISICA_RIFERIMENTO)
  })(DocumentiFisicaRiferimento)
)
