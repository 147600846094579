import React, { Component } from 'react'
import { Row, Col, Icon } from 'react-materialize'
import { PropTypes } from 'prop-types'
import Selezione from './Selezione'
import Email from './Email'
import Cartaceo from './Cartaceo'
import { FooterModal } from '../Common/Footer'
import { timedButtonClick } from '../../../utils'

class ModalPrivacy extends Component {
  constructor(props) {
    super(props)
    this.state = {
      stepName: 'selectPrivacy',
      emailPolling: false,
      created: false,
      error: null,
      selected: '',
      subjectType: 'PROCURATOR',
      validazione: '',
      isPrivacyAcceptanceDone: false,
      pollingHandler: null,
      canSendEmail: true,
      remainingTimeEmail: 0,
      timerIdEmail: null
    }
    this.timerId = null
    this.clickTimestamp = null
    this.clearInterval = this.clearInterval.bind(this)
    this.backHandler = this.backHandler.bind(this)
    this.emailHandler = this.emailHandler.bind(this)
    this.moduleHandler = this.moduleHandler.bind(this)
    this.moduleHandlerProcuratore = this.moduleHandlerProcuratore.bind(this)
    this.emailHandlerProcuratore = this.emailHandlerProcuratore.bind(this)
    this.nextStep = this.nextStep.bind(this)
    this.resetSelectedState = this.resetSelectedState.bind(this)
    this.clearRequests = this.clearRequests.bind(this)
    // this.updateStatoPratica = this.updateStatoPratica.bind(this)
  }
  componentDidMount() {
    this.props.privacyReset()
  }

  componentWillUnmount() {
    clearInterval(this.state.timerIdEmail)
  }
  componentDidUpdate(prevProps) {
    if (prevProps.privacySendRequest.fetching && !this.props.privacySendRequest.fetching) {
      // mettere controllo su email duplicate
      timedButtonClick('canSendEmail', 'remainingTimeEmail', 'timerIdEmail', this.setState.bind(this), 90000)
    }

    if (prevProps.privacyProcuratoreSendRequest.fetching && !this.props.privacyProcuratoreSendRequest.fetching) {
      // mettere controllo su email duplicate
      timedButtonClick('canSendEmail', 'remainingTimeEmail', 'timerIdEmail', this.setState.bind(this), 90000)
    }

    if (prevProps.moduleSendRequest.fetching && !this.props.moduleSendRequest.fetching) {
      this.props.continueHandler()
    }

    if (
      this.props.moduleSendRequestProcuratore &&
      prevProps.moduleSendRequestProcuratore &&
      prevProps.moduleSendRequestProcuratore.fetching &&
      !this.props.moduleSendRequestProcuratore.fetching
    ) {
      this.props.continueHandler()
    }

    if (
      this.props.privacyCheckRequestProcuratore &&
      prevProps.privacyCheckRequestProcuratore &&
      prevProps.privacyCheckRequestProcuratore.fetching &&
      !this.props.privacyCheckRequestProcuratore.fetching &&
      (this.props.privacyCheckRequestProcuratore.error || this.props.isPrivacyAcceptanceDoneProcuratore)
    ) {
      this.clearInterval(
        this.props.isPrivacyAcceptanceDoneProcuratore,
        !!this.props.privacyCheckRequestProcuratore.error
      )
    }
    if (
      prevProps.privacyCheckRequest.fetching &&
      !this.props.privacyCheckRequest.fetching &&
      (!this.props.privacyCheckRequest.error && this.props.isPrivacyAcceptanceDone)
    ) {
      this.clearInterval(this.props.isPrivacyAcceptanceDone, !!this.props.privacyCheckRequest.error)
    }
    if (!this.state.pollingHandler && this.state.emailPolling) {
      const {
        processId = '',
        privacyCheck,
        privacyCheckProcuratore,
        processIdProcuratore,
        isPrivacyAcceptanceDoneProcuratore,
        isPrivacyAcceptanceDone
      } = this.props

      if (!prevProps.processIdProcuratore && processIdProcuratore && !isPrivacyAcceptanceDoneProcuratore) {
        this.setState({
          pollingHandler: setInterval(() => privacyCheckProcuratore(processIdProcuratore), 20000)
        })
      } else if (!prevProps.processId && processId && !isPrivacyAcceptanceDone) {
        this.setState({
          pollingHandler: setInterval(() => privacyCheck(processId), 20000)
        })
      }
    }

    if (
      (!this.state.error && (this.props.privacyCheckRequest && this.props.privacyCheckRequest.error)) ||
      (this.props.privacyCheckRequestProcuratore && this.props.privacyCheckRequestProcuratore.error)
    ) {
      this.clearRequests()
    }
  }

  clearRequests() {
    clearInterval(this.state.pollingHandler)
    this.setState({
      stepName: 'selectPrivacy',
      emailPolling: false,
      created: false,
      error: true,
      selected: '',
      subjectType: 'PROCURATOR',
      validazione: '',
      isPrivacyAcceptanceDone: false,
      pollingHandler: null
    })
    this.props.privacyReset()
    this.props.modalHandler('close')
  }

  clearInterval(created, error) {
    clearInterval(this.state.pollingHandler)
    this.setState({ emailPolling: false, pollingHandler: null, created, error })
  }

  emailHandler() {
    if (this.props.isProspectPG) {
      const { privacySend, formValues, fields, createBodyRequestForPrivacy, parentFormValues } = this.props
      const {
        PRIVACY_DOC,
        PRIVACY_DOC_THUMB,
        EMAIL,
        NOME,
        COGNOME,
        RESIDENZA_ANAGRAFICA_ULTERIORI_DATI,
        NAZIONALITA_ULTERIORI_DATI,
        COMUNE_NASCITA_ULTERIORI_DATI,
        COMUNE_NASCITA_ESTERO_ULTERIORI_DATI,
        DATA_NASCITA_ULTERIORI_DATI,
        SESSO_ULTERIORI_DATI
      } = fields
      privacySend({
        ...createBodyRequestForPrivacy(formValues[PRIVACY_DOC.id], formValues[PRIVACY_DOC_THUMB.id]),
        emailAddress: formValues[EMAIL.id],
        name: parentFormValues[NOME.id],
        surname: parentFormValues[COGNOME.id],
        registeredResidenceCode: parentFormValues[RESIDENZA_ANAGRAFICA_ULTERIORI_DATI.id],
        cityBirthCode: parentFormValues[COMUNE_NASCITA_ULTERIORI_DATI.id],
        cityBirthDescription: parentFormValues[COMUNE_NASCITA_ESTERO_ULTERIORI_DATI.id] || null,
        birthDate: parentFormValues[DATA_NASCITA_ULTERIORI_DATI.id],
        sex: parentFormValues[SESSO_ULTERIORI_DATI.id],
        citizenshipCode: parentFormValues[NAZIONALITA_ULTERIORI_DATI.id]
      })
      this.setState({ emailPolling: true, created: false, error: false })
      // setInterval(this.props.continueHandler(), 1000)
    } else {
      const { privacySend, formValues, parentFormValues, fields } = this.props
      const {
        NOME,
        COGNOME,
        CODICE_FISCALE,
        EMAIL,
        RESIDENZA_ANAGRAFICA_ULTERIORI_DATI,
        NAZIONALITA_ULTERIORI_DATI,
        COMUNE_NASCITA_ULTERIORI_DATI,
        COMUNE_NASCITA_ESTERO_ULTERIORI_DATI,
        DATA_NASCITA_ULTERIORI_DATI,
        SESSO_ULTERIORI_DATI
      } = fields
      privacySend({
        name: parentFormValues[NOME.id],
        surname: parentFormValues[COGNOME.id],
        taxCode: parentFormValues[CODICE_FISCALE.id] ? parentFormValues[CODICE_FISCALE.id].toUpperCase() : '',
        citizenshipCode: parentFormValues[NAZIONALITA_ULTERIORI_DATI.id],
        registeredResidenceCode: parentFormValues[RESIDENZA_ANAGRAFICA_ULTERIORI_DATI.id],
        cityBirthCode: parentFormValues[COMUNE_NASCITA_ULTERIORI_DATI.id],
        cityBirthDescription: parentFormValues[COMUNE_NASCITA_ESTERO_ULTERIORI_DATI.id] || null,
        birthDate: parentFormValues[DATA_NASCITA_ULTERIORI_DATI.id],
        isPrivacyAcceptanceDone: true,
        subjectType: this.props.isProspectPG ? 'LEGAL_PERSON' : 'PHYSICAL_SUBJECT',
        emailAddress: formValues[EMAIL.id],
        sex: parentFormValues[SESSO_ULTERIORI_DATI.id]
      })
      this.setState({ emailPolling: true, created: false, error: false })
    }
  }

  emailHandlerProcuratore() {
    const { privacySendProcuratore, parentFormValues, formValues, fields } = this.props
    const { NOME, COGNOME, CODICE_FISCALE, EMAIL, RESIDENZA_ANAGRAFICA } = fields
    privacySendProcuratore({
      name: parentFormValues[NOME.id],
      surname: parentFormValues[COGNOME.id],
      emailAddress: formValues[EMAIL.id],
      taxCode: parentFormValues[CODICE_FISCALE.id] ? parentFormValues[CODICE_FISCALE.id].toUpperCase() : '',
      registeredResidenceCode: parentFormValues[RESIDENZA_ANAGRAFICA.id],
      isPrivacyAcceptanceDone: true,
      subjectType: 'PROCURATOR',
      idCustomer: this.props.idCustomer,
      flagIsBlacklist: this.props.flagIsBlacklistProcuratore
    })
    this.setState({ emailPolling: true, created: false, error: false })
  }

  moduleHandler() {
    // const { moduleSend, formValues, fields, createBodyRequestForPrivacy,parentFormValues } = this.props

    // const { PRIVACY_DOC, PRIVACY_DOC_THUMB } = fields
    const { moduleSend, formValues, parentFormValues, fields } = this.props
    const {
      NOME,
      COGNOME,
      CODICE_FISCALE,
      EMAIL,
      RESIDENZA_ANAGRAFICA_ULTERIORI_DATI,
      NAZIONALITA_ULTERIORI_DATI,
      COMUNE_NASCITA_ULTERIORI_DATI,
      COMUNE_NASCITA_ESTERO_ULTERIORI_DATI,
      DATA_NASCITA_ULTERIORI_DATI,
      SESSO_ULTERIORI_DATI
    } = fields

    if (this.props.isProspectPG) {
      const { moduleSend, formValues, fields, createBodyRequestForPrivacy } = this.props
      const { PRIVACY_DOC, PRIVACY_DOC_THUMB } = fields
      moduleSend(createBodyRequestForPrivacy(formValues[PRIVACY_DOC.id], formValues[PRIVACY_DOC_THUMB.id]))
      this.setState({ created: false, error: false })
      // setInterval(this.props.continueHandler(), 1000)
    } else {
      moduleSend({
        name: parentFormValues[NOME.id],
        surname: parentFormValues[COGNOME.id],
        taxCode: parentFormValues[CODICE_FISCALE.id] ? parentFormValues[CODICE_FISCALE.id].toUpperCase() : '',
        citizenshipCode: parentFormValues[NAZIONALITA_ULTERIORI_DATI.id],
        registeredResidenceCode: parentFormValues[RESIDENZA_ANAGRAFICA_ULTERIORI_DATI.id],
        cityBirthCode: parentFormValues[COMUNE_NASCITA_ULTERIORI_DATI.id],
        cityBirthDescription: parentFormValues[COMUNE_NASCITA_ESTERO_ULTERIORI_DATI.id] || null,
        birthDate: parentFormValues[DATA_NASCITA_ULTERIORI_DATI.id],
        isPrivacyAcceptanceDone: true,
        subjectType: 'PHYSICAL_SUBJECT',
        emailAddress: formValues[EMAIL.id],
        sex: parentFormValues[SESSO_ULTERIORI_DATI.id]
      })
    }

    this.setState({ created: false, error: false })
    // setInterval(this.props.continueHandler(), 1000)
  }

  backHandler() {
    this.setState({ stepName: 'selectPrivacy' })
    this.setState({ emailPolling: false, pollingHandler: null })
    clearInterval(this.state.pollingHandler)
  }

  moduleHandlerProcuratore() {
    const { moduleSendProcuratore, formValues, parentFormValues, fields } = this.props
    const { NOME, COGNOME, CODICE_FISCALE, EMAIL, RESIDENZA_ANAGRAFICA, PRIVACY_DOC, PRIVACY_DOC_THUMB } = fields
    moduleSendProcuratore({
      name: parentFormValues[NOME.id],
      surname: parentFormValues[COGNOME.id],
      taxCode: parentFormValues[CODICE_FISCALE.id] ? parentFormValues[CODICE_FISCALE.id].toUpperCase() : '',
      emailAddress: parentFormValues[EMAIL.id],
      isPrivacyAcceptanceDone: true,
      registeredResidenceCode: parentFormValues[RESIDENZA_ANAGRAFICA.id],
      file: formValues[PRIVACY_DOC.id],
      fileThumb: formValues[PRIVACY_DOC_THUMB.id],
      subjectType: 'PROCURATOR',
      idCustomer: this.props.idCustomer,
      flagIsBlacklist: this.props.flagIsBlacklistProcuratore
    })
    this.setState({ created: false, error: false })
  }

  choose(selected, unselected) {
    this.setState({ selected: selected })
    setTimeout(() => {
      if (this.props.procuratore) {
        this.props.privacyTypeHandler(this.state.selected)
      }
    }, 300)
    // border box
    document.getElementsByClassName(`onboarding-modale-icon__${selected}`)[0].style.border = '2px solid #083b67'
    document.getElementsByClassName(`onboarding-modale-icon__${unselected}`)[0].style.border = '2px solid #dadada'
    // icon
    document.getElementsByClassName(`onboarding-modale-icon__${selected}`)[1].style.color = '#083b67'
    document.getElementsByClassName(`onboarding-modale-icon__${unselected}`)[1].style.color = '#616166'
    // title
    document.getElementsByClassName(`onboarding-modale-icon__${selected}`)[2].style.color = '#083b67'
    document.getElementsByClassName(`onboarding-modale-icon__${unselected}`)[2].style.color = '#616166'
  }

  nextStep() {
    this.setState({ stepName: this.state.selected })
  }

  resetSelectedState() {
    this.setState({ selected: '' })
  }

  renderSwitch() {
    const { modalHandler, isPrivacyAcceptanceDone, isPrivacyAcceptanceDoneProcuratore, history } = this.props
    switch (this.state.stepName) {
      case 'selectPrivacy':
        return (
          <Selezione
            title={<p className="onboarding-modale-content__title center-align">Accettazione Privacy Policy</p>}
            text={
              <p className="onboarding-modale-content__subtitle">
                Sono previste due modalità per l'accettazione della Privacy da parte del Cliente: tramite email oppure
                tramite modulo cartaceo. Seleziona la modalità desiderata.
              </p>
            }
            {...this.props}>
            <Row className="center-align">
              <Col s={6} className="center-align">
                <div
                  className="onboarding-modale-icon onboarding-modale-icon__firma-digitale"
                  onClick={() => this.choose('firma-digitale', 'firma-cartacea')}
                  style={{ padding: '2rem' }}>
                  <Icon className="onboarding-modale-icon__firma-digitale icon-icone-ips_sv-spesa" medium>
                    &#xe91b;
                  </Icon>
                  <p className="onboarding-modale-icon__firma-digitale onboarding-modale-icon__title center-align">
                    EMAIL
                  </p>
                </div>
              </Col>
              <Col s={6} className="center-align">
                <div
                  className="onboarding-modale-icon onboarding-modale-icon__firma-cartacea"
                  onClick={() => this.choose('firma-cartacea', 'firma-digitale')}
                  style={{ padding: '2rem' }}>
                  <Icon className="onboarding-modale-icon__firma-cartacea icon-icone-ips_sv-spesa" medium>
                    &#xe918;
                  </Icon>
                  <p className="onboarding-modale-icon__firma-cartacea onboarding-modale-icon__title center-align">
                    MODULO
                  </p>
                </div>
              </Col>
            </Row>
            <Row>
              <Col s={12} style={{ marginTop: '40px' }}>
                <FooterModal
                  modalHandler={modalHandler}
                  nextStep={this.nextStep}
                  next={this.state.selected}
                  resetSelectedState={this.resetSelectedState}
                  push={history.push}
                />
              </Col>
            </Row>
          </Selezione>
        )
      case 'firma-digitale':
        // inizializzazione a stringa vuota con disabilitazione del pulsante Prosegui
        if (this.state.selected !== '') {
          this.setState({ selected: '' })
          // this.props.privacyReset()
        }

        return (
          <Email
            remainingTime={this.state.remainingTimeEmail}
            canSendEmail={this.state.canSendEmail}
            emailHandler={this.props.procuratore ? this.emailHandlerProcuratore : this.emailHandler}
            fields={this.props.fields}
            change={this.props.change}
            touch={this.props.touch}
            formValues={this.props.formValues}
            formErrors={this.props.formErrors}
            invalid={this.props.invalid}
            nonvalida={() => this.setState({ validazione: true })}
            valida={() => this.setState({ validazione: false })}
            validazione={this.state.validazione}
            backHandler={this.backHandler}
            emailPolling={this.state.emailPolling}
            isProspect={window.location.href.indexOf('/create') !== -1}
            isPrivacyAcceptanceDone={
              this.props.procuratore ? isPrivacyAcceptanceDoneProcuratore : isPrivacyAcceptanceDone
            }
            continueHandler={this.props.procuratore ? this.modalHandler : this.props.continueHandler}
            title={<p className="onboarding-modale-content__title center-align">Accettazione Privacy Policy</p>}
            text={
              <p className="onboarding-modale-content__subtitle">
                Inserisci l'indirizzo email a cui il cliente desidera ricevere l'informativa sulla privacy.
              </p>
            }
            modalHandler={modalHandler}
          />
        )
      case 'firma-cartacea':
        // inizializzazione a stringa vuota con disabilitazione del pulsante Prosegui
        if (this.state.selected !== '') {
          this.setState({ selected: '' })
        }
        return (
          <Cartaceo
            formValues={this.props.formValues}
            fields={this.props.fields}
            change={this.props.change}
            backHandler={this.backHandler}
            modalHandler={modalHandler}
            // isProspectPG={this.props.isProspectPG}
            continueHandler={this.props.procuratore ? this.moduleHandlerProcuratore : this.moduleHandler}
            title={<p className="onboarding-modale-content__title center-align">Accettazione Privacy Policy</p>}
            text={
              <p className="onboarding-modale-content__subtitle">
                Per l’accettazione della Privacy è necessario far firmare al Prospect il relativo documento.
              </p>
            }
            detail={
              <p className="onboarding-modale-content__subtitle">
                Il documento firmato andrà poi caricato a sistema o inviato al Back Office. Puoi effettuare questa
                operazione anche in un secondo momento.
              </p>
            }
            pdf={
              <Row className="valign-wrapper">
                <Col s={1}>
                  <Icon
                    className="onboarding-modale-icon__firma-cartacea icon-icone-ips_sv-spesa onboarding-modale-text__blu onboarding-modale-defaultCursor"
                    small>
                    &#xe915;
                  </Icon>
                </Col>
                <Col s={10}>
                  <p className="onboarding-modale-text__blu left-align">Documento di Accettazione Privacy</p>
                </Col>
                {/* <Col s={1}>
                  <button style={{ border: 'none' }}>
                    <Icon className="onboarding-modale-icon__firma-cartacea icon-icone-ips_sv-spesa right-align" small>
                      &#xe91d;
                    </Icon>
                  </button>
                </Col> */}
              </Row>
            }
          />
        )
    }
  }
  render() {
    const { show } = this.props
    if (!show) return null
    return (
      <div className="modal-background modal-privacy">
        <div className="modal-box-container modal-box-container-large">{this.renderSwitch()}</div>
      </div>
    )
  }
}
export default ModalPrivacy

ModalPrivacy.propTypes = {
  privacyReset: PropTypes.func,
  change: PropTypes.func,
  touch: PropTypes.func,
  handleSubmit: PropTypes.func,
  privacySend: PropTypes.func,
  privacySendProcuratore: PropTypes.func,
  privacyCheckProcuratore: PropTypes.func,
  moduleSend: PropTypes.func,
  moduleSendProcuratore: PropTypes.func,
  continueHandler: PropTypes.func,
  closeHandler: PropTypes.func,
  createBodyRequestForPrivacy: PropTypes.func,
  show: PropTypes.bool,
  error: PropTypes.bool,
  emailPolling: PropTypes.bool,
  privacySendRequest: PropTypes.object,
  privacyProcuratoreSendRequest: PropTypes.object,
  duplicatedEmail: PropTypes.bool,
  privacyCheckRequest: PropTypes.object,
  privacyCheckRequestProcuratore: PropTypes.object,
  moduleSendRequest: PropTypes.object,
  moduleSendRequestProcuratore: PropTypes.object,
  isPrivacyAcceptanceDone: PropTypes.bool,
  isPrivacyAcceptanceDoneProcuratore: PropTypes.bool,
  processId: PropTypes.string,
  processIdProcuratore: PropTypes.string,
  privacyCheck: PropTypes.func,
  formValues: PropTypes.object,
  parentFormValues: PropTypes.object,
  formErrors: PropTypes.object,
  invalid: PropTypes.bool,
  fields: PropTypes.object,
  procuratore: PropTypes.bool,
  idCustomer: PropTypes.string,
  modalHandler: PropTypes.func,
  privacyTypeHandler: PropTypes.func,
  isProspectPG: PropTypes.bool,
  history: PropTypes.any,
  flagIsBlacklistProcuratore: PropTypes.bool
}
